import React from 'react';
import InputField from './InputField';
import PasswordInput from './PasswordInput';

const StepOne = ({ 
  formData, 
  handleChange, 
  showPassword, 
  setShowPassword, 
  showConfirmPassword, 
  setShowConfirmPassword,
  fieldErrors
}) => (
  <div className="space-y-4 text-gray-800">
    <InputField
      label="Email"
      name="email"
      type="email"
      value={formData.email}
      onChange={handleChange}
      error={fieldErrors?.email}
      className={`w-full px-3 py-2 border ${
        fieldErrors?.email ? 'border-red-300' : 'border-gray-300'
      } rounded-md shadow-sm`}
    />
    <PasswordInput
      name="password"
      value={formData.password}
      onChange={handleChange}
      showPassword={showPassword}
      setShowPassword={setShowPassword}
      error={fieldErrors?.password}
      className={`w-full px-3 py-2 border ${
        fieldErrors?.password ? 'border-red-300' : 'border-gray-300'
      } rounded-md shadow-sm`}
    />
    <PasswordInput
      name="confirmPassword"
      value={formData.confirmPassword}
      onChange={handleChange}
      showPassword={showConfirmPassword}
      setShowPassword={setShowConfirmPassword}
      error={fieldErrors?.confirmPassword}
      className={`w-full px-3 py-2 border ${
        fieldErrors?.confirmPassword ? 'border-red-300' : 'border-gray-300'
      } rounded-md shadow-sm`}
    />
  </div>
);

export default StepOne;
