import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const CourseErrorModal = ({ showModal, setShowModal, courseAccessStatus, onContinueCourse, userProgress }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText('🚀 ចូលរួម https://codeforcambodia.com 🖥️ និងប្រើអ៊ីម៉ែលរបស់ខ្ញុំ 📧 ដើម្បីទទួលបានវគ្គសិក្សា Python video 🐍 មួយដោយឥតគិតថ្លៃ!');
    setCopied(true);
  };

  if (!showModal) return null;

  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/20 backdrop-blur-sm flex items-center justify-center z-50 p-4"
          onClick={() => setShowModal(false)}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            transition={{ type: 'spring', stiffness: 300, damping: 25 }}
            className="bg-white rounded-2xl shadow-xl w-full max-w-md overflow-hidden border border-gray-200"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="p-6 space-y-6">
              <div className="flex justify-between items-center">
                <h3 className="text-2xl font-bold text-gray-900 Nokora">
                  {!courseAccessStatus.isFree && !courseAccessStatus.hasAccess
                    ? 'មេរៀនគឺជាប្រភេទ Premium'
                    : 'មេរៀនទាន់រៀនដល់ទេ'}
                </h3>
                <button
                  onClick={() => setShowModal(false)}
                  className="text-gray-400 hover:text-gray-600 transition-colors duration-200"
                >
                  <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <p className="text-gray-600 Nokora">
                {!courseAccessStatus.isFree && !courseAccessStatus.hasAccess
                  ? 'ហៅមិត្ត 3 អ្នកដើម្បីបាន Premium ដើម្បីអាចរៀនបានគ្រប់ថ្នាក់!'
                  : 'សុំរៀនតាមលំដាប់លំដោយ អត់អាចរៀនរំលងបានទេ!'}
              </p>
              {!courseAccessStatus.isFree && !courseAccessStatus.hasAccess ? (
                <div className="space-y-4">
                  <motion.div
                    className="relative h-48 w-full rounded-xl overflow-hidden bg-gray-100"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    <video
                      className="w-full h-full object-cover"
                      controls
                      autoPlay
                    >
                      <source src="https://codeforcambodia.s3.us-east-005.backblazeb2.com/compressedDemoAccount.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </motion.div>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={handleCopyLink}
                    className={`w-full px-4 py-3 rounded-xl font-medium transition-all duration-300 Nokora ${
                      copied 
                        ? 'bg-green-100 text-green-700 border border-green-200' 
                        : 'bg-blue-100 text-blue-700 border border-blue-200 hover:bg-blue-50'
                    }`}
                  >
                    <span className="flex items-center justify-center">
                      {copied ? (
                        <>
                          <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          បានចម្លងហើយ!
                        </>
                      ) : (
                        <>
                          <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                          </svg>
                          ហៅមិត្ត
                        </>
                      )}
                    </span>
                  </motion.button>
                </div>
              ) : (
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => {
                    setShowModal(false);
                    onContinueCourse();
                  }}
                  className="w-full px-4 py-3 bg-blue-100 text-blue-700 border border-blue-200 hover:bg-blue-50 rounded-xl font-medium transition-all duration-300 Nokora"
                >
                  {userProgress ? 'រៀនបន្តរ' : 'ចាប់ផ្តើមរៀន'}
                </motion.button>
              )}
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setShowModal(false)}
                className="w-full px-4 py-3 bg-gray-100 text-gray-700 border border-gray-200 rounded-xl font-medium hover:bg-gray-50 transition-all duration-300 Nokora"
              >
                បិទ
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CourseErrorModal;