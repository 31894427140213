import React, { useRef, useEffect, useState } from 'react';
import { Snack } from 'snack-sdk';

export default function ReactNativeCompiler({ output }) {
  const webPreviewRef = useRef(null);
  const [error, setError] = useState('');
  const [snack, setSnack] = useState(null);

  useEffect(() => {
    const newSnack = new Snack({
      files: {
        'App.js': {
          type: 'CODE',
          contents: output || `
import React from 'react';
import { View, Text } from 'react-native';

export default function App() {
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text>Start editing to see your changes</Text>
    </View>
  );
}`
        }
      },
      dependencies: {
        'expo': '*',
        'react': '*',
        'react-native': '*'
      },
      webPreviewRef,
      verbose: true,
      online: true,
      sdkVersion: '48.0.0',
      name: 'React Native Preview'
    });

    setSnack(newSnack);

    return () => {
      if (newSnack) {
        newSnack.setOnline(false);
      }
    };
  }, []);

  useEffect(() => {
    if (!snack || !output) return;

    snack.updateFiles({
      'App.js': {
        type: 'CODE',
        contents: output
      }
    });

    const unsubscribe = snack.addStateListener((state, prevState) => {
      if (state.error && state.error !== prevState.error) {
        setError(state.error.message);
      } else if (!state.error && prevState.error) {
        setError('');
      }
    });

    return () => {
      unsubscribe();
    };
  }, [snack, output]);

  return (
    <div className="flex items-center justify-center p-4">
      <div className="relative transform-gpu">
        {/* Phone Frame - Much smaller size */}
        <div className="w-[250px] h-[500px] bg-black rounded-[30px] p-2 shadow-xl overflow-hidden relative">
          {/* Screen */}
          <div className="absolute inset-2 bg-white rounded-[25px] overflow-hidden">
            {/* Notch */}
            <div className="absolute top-0 left-1/2 -translate-x-1/2 w-[40%] h-4 bg-black rounded-b-2xl z-10"></div>

            {/* Dynamic Content Container */}
            <iframe
              ref={(c) => (webPreviewRef.current = c?.contentWindow ?? null)}
              src={snack?.getState().webPreviewURL}
              className="w-full h-full border-none"
              allow="geolocation; camera; microphone"
            />

            {error && (
              <div className="absolute inset-0 bg-red-50 p-2">
                <div className="text-red-500 font-mono text-xs whitespace-pre-wrap">
                  {error}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Home Indicator */}
        <div className="absolute bottom-1 left-1/2 -translate-x-1/2 w-16 h-0.5 bg-white rounded-full"></div>
      </div>
    </div>
  );
}