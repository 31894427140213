import React from 'react';

const Post = () => {
  return (
    <div className="bg-white rounded border  mb-4">
      <div className="p-2">
        <div className="flex items-center text-xs mb-2">
          <a href="#" className="text-[#1a1a1b] hover:underline">r/criminalminds</a>
          <span className="mx-1 text-[#787c7e]">•</span>
          <span className="text-[#787c7e]">Posted by</span>
          <a href="#" className="ml-1 text-[#787c7e] hover:underline">u/thabooshka</a>
          <span className="mx-1 text-[#787c7e]">•</span>
          <span className="text-[#787c7e]">10 yr. ago</span>
        </div>
        <h1 className="text-lg font-medium mb-2">Full Episodes Online</h1>
        <p className="text-sm mb-2">
          Does anyone know where I can catch up and watch season 10 online for free? I've been searching everywhere and cannot find anything.
        </p>
        <div className="flex items-center gap-4 text-xs text-[#878a8c]">
          <div className="flex items-center gap-1">
            <button className="hover:bg-[#1a1a1b]/[0.05] p-2 rounded-sm">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
                <path d="M8 2.5 4 6.5h8l-4-4z" />
              </svg>
            </button>
            <span className="font-bold">10</span>
            <button className="hover:bg-[#1a1a1b]/[0.05] p-2 rounded-sm">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
                <path d="M8 13.5 4 9.5h8l-4 4z" />
              </svg>
            </button>
          </div>
          <button className="flex items-center gap-1 hover:bg-[#1a1a1b]/[0.05] p-2 rounded-sm">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
              <path d="M8 3c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6zm0-2C3.58 1 0 4.58 0 9s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm.5 11h-1v-1h1v1zm0-2h-1V5h1v5z"/>
            </svg>
            17 Comments
          </button>
          <button className="flex items-center gap-1 hover:bg-[#1a1a1b]/[0.05] p-2 rounded-sm">
            Share
          </button>
          <button className="flex items-center gap-1 hover:bg-[#1a1a1b]/[0.05] p-2 rounded-sm">
            Save
          </button>
          <button className="flex items-center gap-1 hover:bg-[#1a1a1b]/[0.05] p-2 rounded-sm">
            •••
          </button>
        </div>
      </div>
    </div>
  );
};

export default Post;