import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Book, CheckCircle, AlertCircle, Lock } from 'lucide-react';
import { motion } from 'framer-motion';
import CourseErrorModal from '../Course/CourseErrorModal';

const LessonCard = ({
  lesson,
  courseId,
  moduleId,
  isLocked,
  onContinueCourse,
  userProgress,
  courseAccessStatus,
}) => {
  const [quizResult, setQuizResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const fetchLessonDetails = async () => {
    if (!isLocked) {
      try {
        const response = await axios.get(
          `https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/fetch/courses/${courseId}/modules/${moduleId}/lessons/${lesson.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        return response.data;
      } catch (err) {
        console.error('Error fetching lesson details:', err);
        setError('Failed to fetch lesson details');
      }
    }
  };

  useEffect(() => {
    const fetchQuizResult = async () => {
      if (lesson.type === 'quiz' && !isLocked) {
        setLoading(true);
        const content = await fetchLessonDetails();

        try {
          const response = await axios.get(
            `https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/gamify/quiz-results/${content.id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          setQuizResult(response.data);
        } catch (err) {
          setError('អ្នកអត់ទាន់ប្រលងទេ');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchQuizResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson.id, lesson.type, isLocked]);

  const getCardStyle = (percentage) => {
    if (percentage >= 80) return 'border-green-500 bg-green-50';
    if (percentage >= 60) return 'border-yellow-500 bg-yellow-50';
    return 'border-red-500 bg-red-50';
  };

  const getTextStyle = (percentage) => {
    if (percentage >= 80) return 'text-green-700';
    if (percentage >= 60) return 'text-yellow-700';
    return 'text-red-700';
  };

  const handleClick = (e) => {
    if (isLocked) {
      e.preventDefault();
      setShowModal(true);
    } else if (!courseAccessStatus.isFree && !courseAccessStatus.hasAccess) {
      e.preventDefault();
      setShowModal(true);
    }
  };

  return (
    <>
      <Link
        to={
          isLocked || (!courseAccessStatus.isFree && !courseAccessStatus.hasAccess)
            ? '#'
            : `/courses/${courseId}/modules/${moduleId}/lessons/${lesson.id}`
        }
        className="block"
        onClick={handleClick}
      >
        <motion.div
          whileHover={{ scale: 1.02 }}
          transition={{ type: 'spring', stiffness: 400, damping: 10 }}
          className={`border-2 rounded-lg p-4 mb-4 transition-all duration-300 hover:shadow-lg ${
            lesson.type === 'quiz' && quizResult
              ? getCardStyle(quizResult.percentage)
              : 'border-gray-200 hover:border-blue-500'
          } ${
            (!courseAccessStatus.isFree && !courseAccessStatus.hasAccess) ? 'opacity-75' : ''
          }`}
        >
          <div className="flex flex-col sm:flex-row justify-between items-start mb-2">
            <h3 className="text-lg font-semibold text-gray-800 mb-2 sm:mb-0">
              {lesson.lesson_name}
            </h3>
            {lesson.type === 'quiz' ? (
              <div className="flex items-center">
                <AlertCircle size={20} className="text-blue-500 mr-1" />
                <span className="text-sm font-medium text-blue-500 Nokora">ប្រលង</span>
              </div>
            ) : (
              <div className="flex items-center">
                <Book size={20} className="text-gray-500 mr-1" />
                <span className="text-sm font-medium text-gray-500 Nokora">
                  {lesson.type === 'document' ? 'ឯកសារ' : 'លំហាត់កូដ'}
                </span>
              </div>
            )}
          </div>
          <p className="text-gray-600 mb-2 text-sm sm:text-base">{lesson.description}</p>
          {lesson.type === 'quiz' && (
            <div className="mt-2">
              {loading && <p className="text-sm text-gray-500">Loading quiz result...</p>}
              {error && <p className="text-sm text-red-500 Nokora">{error}</p>}
              {quizResult && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                  className={`flex items-center ${getTextStyle(quizResult.percentage)}`}
                >
                  <CheckCircle size={16} className="mr-1" />
                  <span className="text-sm font-medium">
                    Score: {quizResult.percentage}%
                  </span>
                </motion.div>
              )}
            </div>
          )}
          {isLocked && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="mt-2 flex items-center text-gray-500"
            >
              <Lock size={16} className="mr-1" />
              <span className="text-sm">Locked</span>
            </motion.div>
          )}
          {!courseAccessStatus.isFree && !courseAccessStatus.hasAccess && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="mt-2 flex items-center text-yellow-600"
            >
              <Lock size={16} className="mr-1" />
              <span className="text-sm Nokora">មេរៀនត្រូវការ premium</span>
            </motion.div>
          )}
        </motion.div>
      </Link>
      <CourseErrorModal
        showModal={showModal}
        setShowModal={setShowModal}
        courseAccessStatus={courseAccessStatus}
        onContinueCourse={onContinueCourse}
        userProgress={userProgress}
      />
    </>
  );
};

export default LessonCard;
