import React, { useState } from 'react'
import Button from './Button'
import { Save, Trash2, MoreVertical, ArrowLeft } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

const EditorHeader = ({ title, onSave, onDelete, workspaceId }) => {
  const [showMenu, setShowMenu] = useState(false)
  const navigate = useNavigate();

  return (
    <header className="bg-gray-100 p-2 flex items-center space-x-2 border-b border-gray-200 sticky top-0 z-10">
                      <div className="flex items-center">
                    <button
                        onClick={() => navigate(`/projects/${workspaceId}`)}
                        className="flex items-center gap-1 px-4 py-2 text-gray-600 hover:text-gray-900"
                    >
                        <ArrowLeft size={16} />
                        Back to Workspace
                    </button>
                </div>
      <h1 className="text-xs sm:text-sm flex-grow text-center font-semibold">{title}</h1>
      <div className="relative">
        <button
          onClick={() => setShowMenu(!showMenu)}
          className="p-1 hover:bg-gray-200 rounded-full"
        >
          <MoreVertical size={20} />
        </button>
        
        {showMenu && (
          <div className="absolute right-0 mt-2 w-36 bg-white rounded-md shadow-lg border border-gray-200">
            <div className="py-1">
              <Button
                onClick={onSave}
                className="w-full text-left px-4 py-2 text-sm text-green-600 hover:bg-gray-100"
                icon={Save}
              >
                Save
              </Button>
              <Button
                onClick={onDelete}
                className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                icon={Trash2}
              >
                Delete
              </Button>
            </div>
          </div>
        )}
      </div>
    </header>
  )
}

export default EditorHeader