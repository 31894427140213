import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { BookOpen, Clock, BarChart2, Circle, ChevronDown, ChevronUp, FileText, ClipboardList, Code } from 'lucide-react'
import Footer from '../Components/Footer'
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader'
import CodeLoadingAnimation from '../Components/CodeLoadingAnimation'
import CourseCardMac from '../Components/CourseCardMac'
import { checkCourseAccess } from '../Components/functions/courseAccess'

const AllCourses = () => {
  const [courses, setCourses] = useState([])
  const [progress, setProgress] = useState([])
  const [coursesLoading, setCoursesLoading] = useState(true)
  const [progressLoading, setProgressLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const BACKEND_BASE_URL = 'https://usitebackend-36ad67a8d6c6.herokuapp.com'
    // Separate fetch functions
    const fetchCourses = async () => {
      try {
        const coursesResponse = await axios.get(`${BACKEND_BASE_URL}/codecademy/fetch/courses`)
        setCourses(coursesResponse.data)
        setCoursesLoading(false)
      } catch (err) {
        console.error('Error fetching courses:', err)
        setError('Error fetching courses')
        setCoursesLoading(false)
      }
    }

    const fetchProgress = async () => {
      const token = localStorage.getItem('token')
      if (token) {
        try {
          const headers = { Authorization: `Bearer ${token}` }
          const progressResponse = await axios.get(`${BACKEND_BASE_URL}/codecademy/user/progress`, { headers })
          setProgress(progressResponse.data.courses)
        } catch (progressError) {
          console.error('Error fetching progress data:', progressError)
        }
        setProgressLoading(false)
      } else {
        setProgressLoading(false)
      }
    }

    // Execute fetches
    fetchCourses()
    fetchProgress()

  }, [])

  if (coursesLoading) {
    return <CodeLoadingAnimation />
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 text-red-500">
        {error}
      </div>
    )
  }

  return (
    <div className="bg-gray-100 min-h-screen font-mono text-gray-800">
      <CodeForCambodiaHeader />
      
      <main className="container mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold mb-8 text-center">
          <span className="text-blue-600">const</span>{' '}
          <span className="text-green-600 Nokora">ថ្នាក់ទាំងអស់</span>{' '}
          <span className="text-blue-600">=</span>{' '}
          <span className="text-orange-500">{'['}</span>
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {courses
            .sort((a, b) => a.course_index - b.course_index)
            .map((course) => {
            // Find progress for the current course
            const courseProgress = progress.find(p => p.course_id === course.id)

            return (
              <CourseCardMac
                key={course.id}
                course={course}
                courseProgress={courseProgress}
                progressLoading={progressLoading}
              />
            )
          })}
        </div>

        <div className="text-center mt-8 text-2xl">
          <span className="text-orange-500">{']'}</span>
          <span className="text-gray-800">;</span>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default AllCourses