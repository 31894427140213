import React, { useState, useEffect } from "react"
import { Calendar, CheckCircle, Terminal, Code, Layers } from "lucide-react"

const learningFeatures = [
  {
    icon: Code, 
    title: 'លំហាត់សរសេរកូដ',
    description: 'អ្នកសិក្សាអាចចូលរួមក្នុងកិច្ចការសរសេរកូដដោយផ្ទាល់នៅក្នុង browser ដោយអនុវត្តគោលគំនិតនៅពេលពួកគេរៀន។ វិធីសាស្រ្តនេះពង្រឹងចំណេះដឹងតាមរយៈការអនុវត្តភ្លាមៗ ជាជាងគ្រាន់តែជាទ្រឹស្តី។',
  },
  {
    icon: CheckCircle, 
    title: 'បានការកែសម្រួលកូដភ្លាមៗ', 
    description: 'ជាមួយនឹងការអនុវត្តកូដភ្លាមៗ និងមតិកែលម្អភ្លាមៗ សិស្សអាចឃើញលទ្ធផលនៃកូដរបស់ពួកគេយ៉ាងឆាប់រហ័ស រៀនពីកំហុស និងកែតម្រូវវិធីសាស្រ្តរបស់ពួកគេ ជំរុញអ្នកកែលម្អដែលណែនាំដោយខ្លួនឯង។',
  },
  {
    icon: Calendar, 
    title: 'ការរៀនសូត្រដោយខ្លួនឯង',
    description: "CodeForCambodia ផ្តល់សេរីភាពក្នុងការរៀនតាមល្បឿនផ្ទាល់ខ្លួនរបស់អ្នក ដោយផ្តល់ឱ្យអ្នកប្រើប្រាស់នូវភាពបត់បែនក្នុងការពិនិត្យមើលប្រធានបទ និងលំហាត់ឡើងវិញ រហូតដល់ពួកគេមានទំនុកចិត្តលើការយល់ដឹងរបស់ពួកគេ។",
  },
  {
    icon: Layers, 
    title: 'ការរៀនបែប Project-Based',
    description: 'ថ្នាក់នេះគឺផ្តល់នូវ projects ដែលអ្នកអាចប្រេីប្រាស់បានហើយអាចយកទៅប្រើប្រាស់ក្នុង portfoilio របស់ុអ្នក។',
  },
  {
    icon: Terminal, 
    title: 'កម្មវិធីសរសេរកូដក្នុង website',
    description: 'កម្មវិធីចងក្រងតាមអ៊ីនធឺណិតសម្រាប់ សរសេរកូដដើម្បីអភិវឌ្ឍជំនាញរបស់ពួកគេតាមរយៈការសាកល្បង និងកំហុស ការលើកទឹកចិត្តដល់ការរុករក និងការពឹងផ្អែកលើខ្លួនឯងក្នុងការរៀនសូត្រ',
  },
]

export default function HandsOnLearning() {
  const [selectedFeature, setSelectedFeature] = useState(learningFeatures[0])

  const getCodeExample = (feature) => (`
  // ${feature.title} Example
  function ${feature.title.replace(/\s+/g, '')}() {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      // Simulating ${feature.title} process
      const interval = setInterval(() => {
        setProgress(prev => (prev < 100 ? prev + 10 : 100));
      }, 1000);

      return () => clearInterval(interval);
    }, []);

    return (
      <div className="${feature.title.toLowerCase().replace(/\s+/g, '-')}">
        <h2>${feature.title}</h2>
        <p>${feature.description}</p>
        <ProgressBar value={progress} />
      </div>
    );
  }
  `)

  return (
    <div className="bg-white py-8 sm:py-16 font-mono">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Line numbers - hidden on mobile */}
        <div className="absolute left-0 top-0 bottom-0 w-12 hidden sm:flex flex-col items-end pr-4 text-gray-400 text-xs border-r border-gray-200">
          {Array.from({ length: 30 }, (_, i) => (
            <div key={i} className="leading-6">{i + 1}</div>
          ))}
        </div>

        <div className="sm:pl-16">
          {/* Import statement */}
          <div className="text-gray-500 mb-6 text-sm sm:text-base">
            <Code className="inline-block mr-2" size={16} />
            <span className="text-purple-600">import</span>{' '}
            <span className="text-blue-600">{'{ HandsOnLearning }'}</span>{' '}
            <span className="text-purple-600">from</span>{' '}
            <span className="text-orange-500">'@/components'</span>;
          </div>

          <h2 className="text-2xl sm:text-3xl font-bold text-center mb-2 text-gray-800">
            <span className="text-orange-500 Nokora">"វេទិកា"</span>;
          </h2>
          <h3 className="text-3xl sm:text-4xl font-bold text-center mb-8 sm:mb-12 text-gray-800">
            <span className="text-blue-500 Nokora">"ការរៀនសូត្រដោយផ្ទាល់"</span>;
          </h3>
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="space-y-4">
              {learningFeatures.map((feature, index) => (
                <div
                  key={index}
                  className={`p-4 rounded-lg cursor-pointer transition-all duration-300 ${
                    selectedFeature === feature 
                      ? 'bg-blue-100 border-2 border-blue-500 shadow-md transform -translate-y-1' 
                      : 'bg-gray-100 hover:bg-gray-200 hover:shadow-sm'
                  }`}
                  onClick={() => setSelectedFeature(feature)}
                >
                  <div className="flex items-center">
                    <feature.icon className="w-6 h-6 mr-3 text-blue-600" />
                    <h4 className="text-base sm:text-lg font-semibold text-gray-800 Nokora">{feature.title}</h4>
                  </div>
                  {selectedFeature === feature && (
                    <p className="mt-2 text-sm sm:text-base text-gray-600 Nokora">{feature.description}</p>
                  )}
                </div>
              ))}
            </div>
            
            <div className="bg-gray-100 rounded-lg overflow-hidden border border-gray-300 shadow-md transition-all duration-300" style={{height: selectedFeature ? 'auto' : '300px', minHeight: '300px'}}>
              <div className="bg-gray-200 px-4 py-2 flex justify-between items-center">
                <span className="text-gray-700 Nokora text-sm sm:text-base">ឧទាហរណ៍កូដ៖ {selectedFeature.title}</span>
                <div className="flex space-x-2">
                  <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-red-500"></div>
                  <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-yellow-500"></div>
                  <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-green-500"></div>
                </div>
              </div>
              <pre className="p-4 text-xs sm:text-sm overflow-x-auto bg-white h-full">
                <code className="text-gray-800 Nokora">{getCodeExample(selectedFeature)}</code>
              </pre>
            </div>
          </div>
          {/* Export statement */}
          <div className="text-gray-500 mt-6 text-sm sm:text-base">
            <span className="text-purple-600">export default</span>{' '}
            <span className="text-blue-600">HandsOnLearning</span>;
          </div>
        </div>
      </div>
    </div>
  )
}