import React from 'react'

const TeamMember = ({ name, title, imageUrl }) => (
  <div className="text-center">
    <div className="w-40 h-40 mx-auto mb-4 overflow-hidden rounded-2xl">
      <img 
        src={imageUrl} 
        alt={name} 
        className="w-full h-full object-cover"
      />
    </div>
    <h3 className="font-semibold text-lg">{name}</h3>
    <p className="text-gray-600">{title}</p>
  </div>
)

const Team = () => {
  const teamMembers = [
    { name: 'Uddom Pang', title: 'Founder & Programmer', imageUrl: 'https://codeforcambodia.s3.us-east-005.backblazeb2.com/team/uddom.jpg' },
    { name: 'Theng Poleak', title: 'Course Creator', imageUrl: 'https://codeforcambodia.s3.us-east-005.backblazeb2.com/team/poleak.jpg' },
      { name: 'Sophanich Kong', title: 'Digital Marketer', imageUrl: 'https://codeforcambodia.s3.us-east-005.backblazeb2.com/team/sophanich.PNG' },
  ]

  return (
    <section className="py-16">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-2 Nokora">ជួបនឹងក្រុមការងារ</h2>
        <p className="text-center text-gray-600 mb-12 Nokora">ប្តេជ្ញាចិត្តក្នុងការផ្តល់ឱកាសដល់យុវជនកម្ពុជាតាមរយៈការសរសេរកូដ</p>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-8 font-mono">
          {teamMembers.map((member, index) => (
            <TeamMember key={index} {...member} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Team