import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { DollarSign, Heart, Edit3, Plus, Gift } from 'lucide-react'

const DonationForm = ({onClose}) => {
  const [isCustom, setIsCustom] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    if (query.get('success')) {
      setMessage('Thank you for your donation! You will receive an email confirmation.')
    }
    if (query.get('canceled')) {
      setMessage(`Donation canceled -- feel free to try again when you're ready.`)
    }
  }, [])

  const handleDonation = (isCustomAmount) => {
    const url = isCustomAmount
      ? 'https://pay.ababank.com/m5TY7WS4reYzAVLW6'
      : 'https://pay.ababank.com/Wukgveba4ZZgSsCB9'
    window.open(url, '_blank')

    onClose()
  }

  if (message) {
    return (
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <p className="text-lg text-center text-gray-800 font-semibold">{message}</p>
      </div>
    )
  }

  return (
    <div className="bg-white p-8 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-blue-500 mb-6 text-center Nokora">ជួយ​អភិវឌ្ឍន៍​អនាគត</h2>
      <p className="text-gray-600 mb-6 text-center Nokora">ការគាំទ្ររបស់អ្នកជួយឱ្យយើងផ្តល់ឱកាសដល់យុវជនកម្ពុជាតាមរយៈការអប់រំផ្នែកកូដ</p>
      <div className="space-y-6">
        <div className="flex flex-col items-center space-y-4">
          <motion.button
            className={`px-12 py-6 rounded-full text-2xl font-bold flex items-center justify-center ${
              !isCustom ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
            }`}
            onClick={() => {
              setIsCustom(false)
              handleDonation(false)
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <DollarSign className="mr-2 h-8 w-8" />
            1
          </motion.button>
          <motion.button
            className="text-orange-500 font-semibold flex items-center Nokora"
            onClick={() => {
              setIsCustom(true)
              handleDonation(true)
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Gift className="mr-2 h-5 w-5" />
            បរិច្ចាគលើសនឹង
          </motion.button>
        </div>
        <motion.button
          className="w-full bg-purple-500 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-orange-600 transition duration-300 flex items-center justify-center"
          onClick={() => handleDonation(true)}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Heart className="mr-2 h-5 w-5" />
          Donate Now
        </motion.button>
      </div>
    </div>
  )
}

export default DonationForm