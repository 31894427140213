import React, { useState } from 'react';
import { ThumbsUp, Clock, Trophy, Eye, Heart, User, Maximize2, Minimize2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const SubmissionCard = ({ submission, index, onVote, onRemoveVote }) => {
  const navigate = useNavigate();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const renderSubmissionPreview = () => {
    const htmlContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            ${submission.css_code}
          </style>
        </head>
        <body>
          ${submission.html_code}
          <script>${submission.js_code}</script>
        </body>
      </html>
    `;
    return htmlContent;
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    }
  };

  return (
    <>
      {isFullScreen ? (
        <div className="fixed inset-0 bg-white z-50">
          <button
            onClick={toggleFullScreen}
            className="absolute top-4 right-4 z-10 p-2 rounded-md bg-gray-100 hover:bg-gray-200 text-gray-600"
          >
            <Minimize2 size={20} />
          </button>
          <iframe
            srcDoc={renderSubmissionPreview()}
            title={`Submission ${submission.id} preview`}
            className="w-full h-full"
            sandbox="allow-scripts"
          />
        </div>
      ) : (
        <div className="transition-colors duration-200 font-mono">
          {/* Preview Section */}
          <div className="aspect-[3/3] overflow-hidden rounded-xl border border-transparent hover:border-blue-200 transition-all duration-300 ease-in-out relative">
            <button
              onClick={toggleFullScreen}
              className="absolute top-2 right-2 z-10 p-2 rounded-md bg-gray-100/90 hover:bg-gray-200 text-gray-600"
            >
              <Maximize2 size={16} />
            </button>
            <iframe
              srcDoc={renderSubmissionPreview()}
              title={`Submission ${submission.id} preview`}
              className="w-full h-full"
              sandbox="allow-scripts"
            />
          </div>

          {/* Card Content */}
          <div className="p-4">
            <div className="flex items-center justify-between text-sm text-gray-600">
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <User className="w-4 h-4" />
                  <span className="font-medium">{submission.first_name} {submission.last_name}</span>
                </div>
              </div>
              <div className="flex items-center gap-2" onClick={() => submission.user_voted ? onRemoveVote(submission.id) : onVote(submission.id)}>
                <Heart className={`w-4 h-4 ${submission.user_voted ? 'text-pink-500 fill-pink-500' : 'text-gray-400'} cursor-pointer hover:scale-110 transition-transform`} />
                <span className="font-medium">{submission.vote_count}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubmissionCard;