"use client"

import React, { useState, useEffect } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { ChevronRight, ChevronDown, FileText, Award, Code, Book, CheckCircle, PlayCircle, Lock, ClipboardList, NotebookPen, AlertTriangle, X, AwardIcon } from 'lucide-react'
import axios from 'axios'
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader'
import Footer from '../Components/Footer'
import CodeLoadingAnimation from '../Components/CodeLoadingAnimation'
import LessonCard from '../Components/Lessons/LessonCard'
import CourseReportCard from '../Components/Lessons/CourseReportCard'
import CourseDescription from '../Components/Lessons/CourseDescription'
import { motion, AnimatePresence } from 'framer-motion'
import { checkCourseAccess } from '../Components/functions/courseAccess'
import CourseErrorModal from '../Components/Course/CourseErrorModal'
import CourseStatusBadge from '../Components/Course/CourseStatusBadge'
import PremiumTutorialModal from '../Components/Dashboard/PremiumTutorialModal'

export default function CoursePage() {
  const [course, setCourse] = useState(null)
  const [modules, setModules] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [expandedModule, setExpandedModule] = useState(null)
  const [userProgress, setUserProgress] = useState(null)
  const [averageGrade, setAverageGrade] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [notification, setNotification] = useState(null)
  const [courseAccessStatus, setCourseAccessStatus] = useState({ isFree: true, hasAccess: true })
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showPremiumModal, setShowPremiumModal] = useState(false)

  const { courseId } = useParams()

  const navigate = useNavigate()

  const showNotification = (message, type) => {
    setNotification({ message, type })
    setTimeout(() => setNotification(null), 3000)
  }
  const resetProgress = async () => {
    setIsDeleting(true)
    try {
      const token = localStorage.getItem('token')
      const headers = { 'Authorization': `Bearer ${token}` }
      await axios.post('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/delete/progress', { course_id: courseId }, { headers })
      showNotification('វឌ្ឍនភាពត្រូវបានលុបដោយជោគជ័យ', 'success')
      setUserProgress(null)
      
      // Refresh the page after successful reset
      window.location.reload()
    } catch (error) {
      console.error('Error deleting progress:', error)
      showNotification('មានបញ្ហាក្នុងការលុបវឌ្ឍនភាព', 'error')
    } finally {
      setIsDeleting(false)
      setIsDeleteModalOpen(false)
    }
  }

  useEffect(() => {
    const fetchCourseAndModules = async () => {
      try {
        const token = localStorage.getItem('token')
        const headers = { 'Authorization': `Bearer ${token}` }

        const [modulesResponse, progressResponse, coursesResponse, averageGradeResponse, courseAccess] = await Promise.all([
          axios.get(`https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/fetch/courses/${courseId}/modules`),
          axios.get(`https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/progress`, { headers }),
          axios.get(`https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/fetch/courses/${courseId}`, { headers }),
          axios.get(`https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/gamify/courses/${courseId}/average-grade`, { headers }),
          checkCourseAccess(courseId)
        ])

        setCourseAccessStatus(courseAccess)

        if (!modulesResponse.data) {
          throw new Error('Failed to fetch course data')
        }
        const coursesData = coursesResponse.data
        const modulesData = modulesResponse.data
        
        const modulesWithLessons = await Promise.all(modulesData.map(async (module) => {
          const lessonsResponse = await axios.get(`https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/fetch/courses/${courseId}/modules/${module.id}/lessons`)
          if (!lessonsResponse.data) {
            throw new Error(`Failed to fetch lessons for module ${module.id}`)
          }
          return { ...module, lessons: lessonsResponse.data }
        }))

        setModules(modulesWithLessons)
        setCourse({ course_name: coursesData.course_name, description: coursesData.description })
        
        const courseProgress = progressResponse.data.courses.find(course => course.course_id === parseInt(courseId))
        setUserProgress(courseProgress)

        setAverageGrade(averageGradeResponse.data)

        setLoading(false)
      } catch (err) {
        setError(err.message)
        setLoading(false)
      }
    }

    fetchCourseAndModules()
  }, [courseId])

  const isLessonLocked = (moduleIndex, lessonIndex) => {
    if (!userProgress) return true
    const currentModuleIndex = modules.findIndex(module => module.id === userProgress.current_module_id)
    const currentLessonIndex = modules[currentModuleIndex]?.lessons.findIndex(lesson => lesson.id === userProgress.current_lesson_id)
    
    return moduleIndex > currentModuleIndex || (moduleIndex === currentModuleIndex && lessonIndex > currentLessonIndex)
  }

  if (loading) {
    return <CodeLoadingAnimation />
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen bg-white">
        <div className="text-xl font-mono text-red-600">Error: {error}</div>
      </div>
    )
  }

  return (
    <div className="flex flex-col min-h-screen text-gray-800 font-mono bg-white">
      <CodeForCambodiaHeader />
      <PremiumTutorialModal isOpen={showPremiumModal} onClose={() => setShowPremiumModal(false)} />

      <main className="flex-grow px-4 py-8">
        <div className="mx-auto relative">
          {/* Line number decoration */}
          <div className="absolute left-0  opacity-50 top-0 bottom-0 w-12 flex flex-col items-end pr-4 text-blue-300 text-xs border-r border-blue-100">
            {Array.from({ length: 30 }, (_, i) => (
              <div key={i} className="leading-6">{i + 1}</div>
            ))}
          </div>

          <div className="pl-16 relative">
            {/* Top decoration */}
            <div className="flex items-center mb-6 pb-4 text-blue-400 opacity-50">
              <Code className="mr-2" size={16} />
              <span className="text-xs font-normal">import Course from '{course.course_name}'</span>
            </div>

            {/* Course title */}
            <div className="flex items-center mb-4 ">
              <FileText className="text-blue-600 mr-2" size={22} />
              <h1 className="text-3xl font-bold text-blue-600">
                {course.course_name}
              </h1>

              <CourseStatusBadge courseAccessStatus={courseAccessStatus} />

            </div>

            <CourseDescription description={course.description} />

            {/* Course stats */}
            <div className="flex flex-wrap justify-between mb-6 gap-4 Nokora">
              <div className="flex items-center">
                <Code className="w-5 h-5 mr-2 text-blue-500" />
                <span className="text-sm text-gray-700">{modules.length} ជំពូក</span>
              </div>
              <div className="flex items-center">
                <Book className="w-5 h-5 mr-2 text-orange-500" />
                <span className="text-sm text-gray-700">{modules.reduce((acc, module) => acc + module.lessons.length, 0)} មេរៀន</span>
              </div>
            </div>

            {/* Continue Learning or Start Course button */}
            <div className="mb-8 Nokora flex flex-col space-y-4">
              <div className="flex items-center space-x-4">
                {userProgress ? (
                  <>
                    <Link
                      to={
                        !courseAccessStatus.isFree && !courseAccessStatus.hasAccess
                          ? '#'
                          : `/courses/${courseId}/modules/${userProgress.current_module_id}/lessons/${userProgress.current_lesson_id}`
                      }
                      onClick={(e) => {
                        if (!courseAccessStatus.isFree && !courseAccessStatus.hasAccess) {
                          e.preventDefault();
                          showNotification('មេរៀននេះត្រូវការ premium', 'error');
                        }
                      }}
                      className={`bg-green-600 text-white px-6 py-2 rounded-md transition-colors duration-200 inline-flex items-center text-sm ${
                        (!courseAccessStatus.isFree && !courseAccessStatus.hasAccess)
                          ? 'opacity-50 cursor-not-allowed'
                          : 'hover:bg-green-700'
                      }`}
                    >
                      <PlayCircle className="mr-2" size={16} />
                      បន្តរការរៀន
                    </Link>
                    <button
                      onClick={() => setIsDeleteModalOpen(true)}
                      className="text-red-500 hover:text-red-700 transition-colors duration-200 text-sm flex items-center"
                    >
                      <AlertTriangle className="mr-1" size={16} />
                      រៀនឡើងវិញ
                    </button>
                  </>
                ) : (
                  <Link
                    to={
                      !courseAccessStatus.isFree && !courseAccessStatus.hasAccess
                        ? '#'
                        : `/courses/${courseId}/modules/${modules[0]?.id}/lessons/${modules[0]?.lessons[0]?.id}`
                    }
                    onClick={(e) => {
                      if (!courseAccessStatus.isFree && !courseAccessStatus.hasAccess) {
                        e.preventDefault();
                        showNotification('មេរៀននេះត្រូវការ premium', 'error');
                      }
                    }}
                    className={`bg-blue-600 text-white px-6 py-2 rounded-md transition-colors duration-200 inline-block text-sm ${
                      (!courseAccessStatus.isFree && !courseAccessStatus.hasAccess)
                        ? 'opacity-50 cursor-not-allowed'
                        : 'hover:bg-blue-700'
                    }`}
                  >
                    ចាប់ផ្តើមការរៀន
                  </Link>
                )}
              </div>
              
              {/* New button on a new line */}
              {!courseAccessStatus.isFree && !courseAccessStatus.hasAccess && (
                <button
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPremiumModal(true);
                  }}
                  className="text-blue-500  ml-2 hover:text-blue-700 transition-colors duration-200 text-sm flex items-center underline"
                >
                  <AwardIcon className="mr-1 text-yellow-500" size={20} />
                  របៀបបាន premium ដោយ FREE
                </button>
              )}

              <CourseErrorModal
                showModal={showErrorModal}
                setShowModal={setShowErrorModal}
                courseAccessStatus={courseAccessStatus}
                onContinueCourse={() => {
                  navigate(`/courses/${courseId}/modules/${userProgress?.current_module_id}/lessons/${userProgress?.current_lesson_id}`)
                }}
                userProgress={userProgress}
              />
            </div>

            {/* Course Progress */}
            {userProgress && (
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2 Nokora">វឌ្ឍនភាពការរៀន</h3>
                <div className="w-full bg-gray-200 rounded-full h-2.5 mb-2 dark:bg-gray-700">
                  <div className="bg-green-600 h-2.5 rounded-full" style={{ width: `${userProgress.progress_percentage}%` }}></div>
                </div>
                <p className="text-sm text-gray-600 Nokora">{parseFloat(userProgress.progress_percentage.toString()).toFixed(2)}% ចប់</p>
              </div>
            )}

            <CourseReportCard averageGrade={averageGrade} />

            {/* Course Syllabus */}
            <div className="border-2 border-blue-200 rounded-lg p-6 bg-white shadow-sm">
              <h2 className="text-3xl font-bold mb-6 text-blue-600">Course Syllabus</h2>
              <ul className="space-y-4">
                {modules.map((module, moduleIndex) => (
                  <li key={module.id} className="border border-blue-100 rounded-md overflow-hidden">
                    <button
                      onClick={() => setExpandedModule(expandedModule === module.id ? null : module.id)}
                      className="w-full text-left flex items-center justify-between p-4 bg-blue-50 hover:bg-blue-100 transition-colors duration-200"
                      aria-expanded={expandedModule === module.id}
                      aria-controls={`module-content-${module.id}`}
                    >
                      <div className="flex items-center">
                        <div className="w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center text-white font-bold mr-4">
                          {moduleIndex + 1}
                        </div>
                        <div>
                          <h3 className="text-xl font-semibold text-blue-600">{module.module_name}</h3>
                          <p className="text-sm text-gray-600 Nokora flex items-center space-x-2">
                            <span className="flex items-center">
                              <FileText className="w-4 h-4 mr-1" />
                              <span className="hidden md:inline">
                                {module.lessons.filter(lesson => lesson.type === 'document' || lesson.type === 'video').length} ឯកសារ
                              </span>
                              <span className="md:hidden">
                                {module.lessons.filter(lesson => lesson.type === 'document' || lesson.type === 'video').length}
                              </span>
                            </span>
                            <span className="flex items-center">
                              <Code className="w-4 h-4 mr-1" />
                              <span className="hidden md:inline">
                                {module.lessons.filter(lesson => 
                                  lesson.type === 'coding' ||
                                  lesson.type === 'web' ||
                                  lesson.type === 'php' ||
                                  lesson.type === 'sql' ||
                                  lesson.type === 'react' ||
                                  lesson.type === 'react-native'
                                ).length} លំហាត់
                              </span>
                              <span className="md:hidden">
                                {module.lessons.filter(lesson => 
                                  lesson.type === 'coding' ||
                                  lesson.type === 'web' ||
                                  lesson.type === 'php' ||
                                  lesson.type === 'sql' ||
                                  lesson.type === 'react' ||
                                  lesson.type === 'react-native'
                                ).length}
                              </span>
                            </span>
                            <span className="flex items-center">
                              <NotebookPen className="w-4 h-4 mr-1" />
                              <span className="hidden md:inline">
                                {module.lessons.filter(lesson => lesson.type === 'quiz').length} ប្រលង
                              </span>
                              <span className="md:hidden">
                                {module.lessons.filter(lesson => lesson.type === 'quiz').length}
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                      {expandedModule === module.id ? (
                        <ChevronDown className="w-5 h-5 text-blue-500 flex-shrink-0" />
                      ) : (
                        <ChevronRight className="w-5 h-5 text-blue-500 flex-shrink-0" />
                      )}
                    </button>
                    {expandedModule === module.id && (
                      <ul id={`module-content-${module.id}`} className="bg-white p-4 space-y-2">
                        {module.lessons.map((lesson, lessonIndex) => (
                          <LessonCard
                            key={lesson.id}
                            lesson={lesson}
                            courseId={courseId}
                            moduleId={module.id}
                            isLocked={isLessonLocked(moduleIndex, lessonIndex)}
                            userProgress={userProgress}
                            courseAccessStatus={courseAccessStatus}
                            onContinueCourse={() => {
                              navigate(`/courses/${courseId}/modules/${userProgress.current_module_id}/lessons/${userProgress.current_lesson_id}`)
                            }}
                          />
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            {/* Bottom decoration */}
            <div className="mt-8 pt-4 text-blue-400  opacity-50">
              <span className="text-xs font-normal">export default Course</span>
            </div>
          </div>
        </div>
      </main>
      <Footer />

      {/* Delete  Progress Modal */}
      <AnimatePresence>
        {isDeleteModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white rounded-lg p-6 max-w-sm w-full m-4"
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-gray-800 Nokora">បញ្ជាក់ការរៀនឡើងវិញ</h2>
                <button 
                  onClick={() => setIsDeleteModalOpen(false)} 
                  className="text-gray-500 hover:text-gray-700"
                  aria-label="Close modal"
                >
                  <X size={24} />
                </button>
              </div>
              <p className="text-gray-600 mb-6 Nokora">
                តើអ្នកប្រាកដជាចង់លុបវឌ្ឍនភាពរបស់អ្នកមែនទេ? សកម្មភាពនេះមិនអាចត្រឡប់វិញបានទេ។
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setIsDeleteModalOpen(false)}
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors Nokora"
                >
                  បោះបង់
                </button>
                <button
                  onClick={resetProgress}
                  disabled={isDeleting}
                  className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed Nokora flex items-center"
                >
                  {isDeleting ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      កំពុងលុប...
                    </>
                  ) : (
                    'លុបវឌ្ឍនភាព'
                  )}
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Notification */}
      <AnimatePresence>
        {notification && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={`fixed bottom-4 right-4 p-4 rounded-md shadow-lg flex items-center ${
              notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
            } text-white`}
          >
            {notification.type === 'success' ? (
              <CheckCircle className="mr-2" size={20} />
            ) : (
              <AlertTriangle className="mr-2" size={20} />
            )}
            <span className="Nokora">{notification.message}</span>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}