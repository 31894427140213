import React from 'react';

const ConfirmationModal = ({ 
    isOpen, 
    onClose, 
    onConfirm, 
    title, 
    children,
    confirmText = "Confirm",
    cancelText = "Cancel",
    confirmButtonClass = "bg-blue-600 hover:bg-blue-700",
    showCancel = true
}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-96 max-w-full">
                <h2 className="text-xl font-bold mb-4">{title}</h2>
                <div className="mb-6">
                    {children}
                </div>
                <div className="flex justify-end space-x-2">
                    {showCancel && (
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                        >
                            {cancelText}
                        </button>
                    )}
                    <button 
                        onClick={onConfirm}
                        className={`px-4 py-2 text-white rounded-lg ${confirmButtonClass}`}
                    >
                        {confirmText}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;