import React, { useState, useEffect, useRef, useCallback } from 'react'
import Editor from '@monaco-editor/react'

import { RefreshCw, VerifiedIcon, Settings, ToggleLeft, Minimize2, Maximize2, Play } from 'lucide-react'
import { Button } from '@headlessui/react'

export const NavItem = ({ icon: Icon, label, active, onClick }) => (
    <button
      onClick={onClick}
      className={`
        w-full flex items-center gap-2 px-3 py-2 text-sm
        ${active ? 'bg-blue-50 text-blue-600 font-medium' : 'text-gray-700 hover:bg-gray-100'}
        rounded-md transition-colors
      `}
    >
      <Icon size={18} />
      <span>{label}</span>
    </button>
  )
  
  export  const Tab = ({ label, active, onClick }) => (
    <button
      onClick={onClick}
      className={`
        px-4 py-2 text-sm border-b-2 
        ${active ? 'border-blue-500 text-blue-600 font-medium' : 'border-transparent text-gray-600 hover:text-gray-800'}
        transition-colors
      `}
    >
      {label}
    </button>
  )
  
  export  const TopBar = ({ lesson, onVerify, onReset, onToggleDesign, toggleFullScreen, isFullScreen, runPhpCode, isLoading, isVerifying }) => (
    <div className="h-12 border-b bg-gray-50 flex items-center justify-between px-4">
      <div className="flex items-center gap-2">
        <div className="flex items-center gap-1">
          <div className="w-3 h-3 rounded-full bg-red-500"></div>
          <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
          <div className="w-3 h-3 rounded-full bg-green-500"></div>
        </div>
        <span className="text-sm text-gray-600 ml-4">{lesson?.lesson_name || 'PHP Lesson'}</span>
      </div>
      <div className="flex items-center gap-3">
              <button
                onClick={onToggleDesign}
                className="p-2 rounded-md text-gray-600 hover:bg-gray-100"
              >
                <ToggleLeft size={20} />
              </button>
              <button
                onClick={toggleFullScreen}
                className="p-2 rounded-md text-gray-600 hover:bg-gray-100"
              >
                {isFullScreen ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
              </button>
              
        <button
          onClick={onReset}
          className="flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-100 rounded"
        >
          <RefreshCw size={16} />
          Reset
        </button>
      </div>
    </div>
  )
  
export const CodeEditor = ({ code, onChange, language = "php" }) => (
    <Editor
      height="100%"
      defaultLanguage={language}
      value={code}
      onChange={onChange}
      theme="light"
      options={{
        minimap: { enabled: false },
        scrollBeyondLastLine: false,
        fontSize: 14,
        lineNumbers: 'on',
        renderLineHighlight: 'line',
        tabSize: 2,
        automaticLayout: true,
        fontFamily: 'JetBrains Mono, monospace',
        padding: { top: 10 },
      }}
    />
  )

