import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight, BookOpen, Bot, CheckCircle, Code, Globe, Layers, Users, Pause, Play } from 'lucide-react';
import TypeWriter from './TypeWriter';

const features = [
  { icon: Globe, text: "កូដនៅលើគេហទំព័រ ដោយមិនចាំបាច់ដំឡើងកម្មវិធីបន្ថែម" },
  { icon: BookOpen, text: "មេរៀនទាំងអស់មានជាភាសាខ្មែរ" },
  { icon: Bot, text: "ជំនួយពីបញ្ញាសិប្បនិម្មិត AI" },
  { icon: CheckCircle, text: "ធ្វើតេស្តដើម្បីវាយតម្លៃចំណេះដឹងរបស់អ្នក" },
  { icon: Layers, text: "វគ្គសិក្សាច្រើនប្រភេទសម្រាប់គ្រប់កម្រិត" },
];

const colorClasses = [
  {
    text: 'text-purple-600',
    bg: 'bg-purple-600',
    bgLight: 'bg-purple-600/20',
  },
  {
    text: 'text-blue-600',
    bg: 'bg-blue-600',
    bgLight: 'bg-blue-600/20',
  },
  {
    text: 'text-green-600',
    bg: 'bg-green-600',
    bgLight: 'bg-green-600/20',
  },
  {
    text: 'text-orange-600',
    bg: 'bg-orange-600',
    bgLight: 'bg-orange-600/20',
  },
];

const LeftPanel = ({ totalUsers }) => {
  const [currentFeature, setCurrentFeature] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [colorIndex, setColorIndex] = useState(0);

  const titles = ["Online", "Free", "Anywhere"];
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const currentTitle = titles[currentTitleIndex];

  const nextFeature = useCallback(() => {
    setCurrentFeature((prev) => (prev + 1) % features.length);
    setColorIndex((prev) => (prev + 1) % colorClasses.length);
  }, []);

  useEffect(() => {
    let timer;
    if (isPlaying) {
      timer = setInterval(nextFeature, 5000);
    }
    return () => clearInterval(timer);
  }, [isPlaying, nextFeature]);

  useEffect(() => {
    const titleTimer = setInterval(() => {
      setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % titles.length);
    }, 5000); // Change every 5 seconds
    return () => clearInterval(titleTimer);
  }, []);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="relative overflow-hidden p-4 sm:p-8">
      <div className="relative z-10">
      <motion.h1 
        className="text-2xl sm:text-4xl font-bold mb-2 text-gray-800 text-center sm:text-left"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        >
        <span className="Nokora text-blue-600">រៀនកូដ </span>
        <TypeWriter 
            key={currentTitle}
            text={currentTitle}
            className="Nokora text-orange-600"
        />
        </motion.h1>

        <motion.p 
          className="mb-6 text-gray-600 text-center sm:text-left"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <TypeWriter 
            text="នរណាក៏អាចកូដបានដែរ"
            className="Nokora text-base sm:text-xl text-gray-600"
          />
        </motion.p>
        <div className="space-y-4 ">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentFeature}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="sm:justify-start sm:items-start flex items-center justify-center "
            >
              <FeatureItem {...features[currentFeature]} colorIndex={colorIndex} />
            </motion.div>
          </AnimatePresence>
        </div>
        <motion.div 
          className="mt-6 sm:justify-start sm:items-start flex items-center justify-center space-x-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <div className="flex space-x-2">
            {features.map((_, index) => (
              <motion.button
                key={index}
                className={`w-2 h-2 rounded-full ${
                  index === currentFeature ? colorClasses[colorIndex].bg : 'bg-gray-300'
                }`}
                whileHover={{ scale: 1.2 }}
                onClick={() => {
                  setCurrentFeature(index);
                  setColorIndex(index % colorClasses.length);
                }}
              />
            ))}
          </div>
          <div className="w-px h-4 bg-gray-300 mx-2" />
          <motion.button 
            onClick={togglePlayPause} 
            className={`${colorClasses[colorIndex].text}`}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            {isPlaying ? <Pause size={20} /> : <Play size={20} />}
          </motion.button>
        </motion.div>
        {totalUsers !== null && (
          <motion.div 
            className="mt-6 sm:justify-start sm:items-start flex items-center justify-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <motion.div
              className="flex items-center flex-wrap justify-center"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 260, damping: 20 }}
            >
              <Users className={`${colorClasses[colorIndex].text} mr-2`} size={20} />
              <span className="text-green-600 mr-2 font-semibold Nokora">អ្នកប្រើប្រាស់សរុប</span>
              <span className={`${colorClasses[colorIndex].text} mx-1`}>=</span>
              <TypeWriter 
                text={totalUsers.toString()}
                className={`${colorClasses[colorIndex].text} text-xl font-bold`}
              />
            </motion.div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

const FeatureItem = ({ icon: Icon, text, colorIndex }) => (
  <motion.div 
    className="flex items-center space-x-3 bg-transparent"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <motion.div 
      className={`flex-shrink-0 w-10 h-10 rounded-full ${colorClasses[colorIndex].bgLight} flex items-center justify-center ${colorClasses[colorIndex].text}`}
      whileHover={{ rotate: 360 }}
      transition={{ duration: 0.5 }}
    >
      <Icon size={20} />
    </motion.div>
    <div className="flex-grow">
      <TypeWriter text={text} className={`text-base sm:text-lg font-medium ${colorClasses[colorIndex].text} Nokora`} />
    </div>
  </motion.div>
);

export default LeftPanel;
