import React, { useEffect } from 'react'
import Editor from '@monaco-editor/react'
import debounce from 'lodash/debounce'

const SPLIT_RATIOS = [25, 50, 75] // Fixed split ratios in percentage

const CodeEditor = ({
  splitPosition,
  isDragging,
  handleDragStart,
  handleDrag,
  handleDragStop,
  activeCodeTab,
  code,
  onChange,
  editorRef,
  handleEditorMount,
  isMobileView,
  preview,
  containerRef
}) => {
  return (
    <div className="h-full w-full" ref={containerRef}>
      <div className="flex h-full">
        {/* Editor Section */}
        <div
          style={{ width: `${splitPosition}%` }}
          className="h-full min-h-0"
        >
          <Editor
            height="100%"
            defaultLanguage={
              activeCodeTab === 'html'
                ? 'html'
                : activeCodeTab === 'css'
                ? 'css'
                : 'javascript'
            }
            language={
              activeCodeTab === 'html'
                ? 'html'
                : activeCodeTab === 'css'
                ? 'css'
                : 'javascript'
            }
            value={code}
            onChange={onChange}
            onMount={handleEditorMount}
            options={{
              minimap: { enabled: false },
              fontSize: 14,
              wordWrap: 'on',
              automaticLayout: true,
              readOnly: false,
              scrollBeyondLastLine: false
            }}
          />
        </div>

        {/* Preview Section */}
        <div 
          style={{ 
            width: `${100 - splitPosition}%`,
            transition: isDragging ? 'none' : 'width 0.2s ease-out'
          }}
          className="h-full min-h-0"
        >
          <div className="h-full border border-gray-200 rounded-md overflow-hidden">
            <div className="bg-gray-100 px-4 py-2 flex items-center space-x-1 border-b border-gray-200">
              <div className="w-2 h-2 rounded-full bg-red-500"></div>
              <div className="w-2 h-2 rounded-full bg-yellow-500"></div>
              <div className="w-2 h-2 rounded-full bg-green-500"></div>
            </div>
            <div className="h-[calc(100%-2.5rem)] overflow-auto">
              {preview}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CodeEditor