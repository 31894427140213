import React, { useState, useEffect } from 'react';
import { Gift, Users, Crown, Sparkles } from 'lucide-react';
import axios from 'axios';

const ReferralPoints = () => {
  const [referralPoints, setReferralPoints] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchReferralPoints = async () => {
      try {
        const response = await axios.get('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/auth/referral-points');
        if (response.data.success) {
          setReferralPoints(response.data.referralPoints);
        }
      } catch (error) {
        console.error('Error fetching referral points:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReferralPoints();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center space-x-2 text-gray-600">
        <div className="animate-spin rounded-full h-4 w-4 border-2 border-blue-500 border-t-transparent"></div>
        <p className="text-sm">កំពុងដំណើរការ...</p>
      </div>
    );
  }

  const pointsToGo = 3 - referralPoints;
  const getPointsMessage = () => {
    if (referralPoints >= 3) {
      return 'អ្នកមានសិទ្ធិប្រើប្រាស់ពេញលេញ! 🎉';
    }
    return `នៅខ្វះ ${pointsToGo} ពិន្ទុទៀត ${pointsToGo === 1 ? '✨' : '💫'}`;
  };

  return (
    <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row items-start md:items-center Nokora justify-between text-gray-600 mb-6 px-4 md:px-0">
      <div className="flex items-center w-full md:w-auto mb-2 md:mb-0">
        <Users className="mr-2 text-blue-500 flex-shrink-0" />
        <span className="Nokora text-sm md:text-base">ពិន្ទុដើម្បីបានគ្រប់ថ្នាក់ និងតាមដានការរៀនសូត្រ</span>
      </div>
      <div className={`w-full md:w-auto ${referralPoints >= 3 ? 'bg-gradient-to-r from-blue-100 via-indigo-50 to-purple-100 animate-gradient-x' : 'bg-gradient-to-r from-blue-50 to-indigo-50'} px-4 md:px-6 py-3 rounded-xl shadow-sm hover:shadow-md transition-all duration-300`}>
        <div className="flex flex-col md:flex-row items-start md:items-center space-y-3 md:space-y-0">
          <div className="flex items-center w-full md:w-auto">
            {referralPoints >= 3 ? (
              <div className="relative">
                <Crown className="w-6 h-6 md:w-8 md:h-8 text-yellow-500 mr-2 md:mr-3 animate-bounce-gentle" />
              </div>
            ) : (
              <Gift className="w-6 h-6 md:w-8 md:h-8 text-yellow-600 mr-2 md:mr-3" />
            )}
            <div className="flex flex-col flex-grow">
              <span className="text-xs md:text-sm font-medium text-gray-600">ពិន្ទុបច្ចុប្បន្ន:</span>
              <div className="flex items-center">
                <span className={`text-lg md:text-xl font-bold ${referralPoints >= 3 ? 'text-indigo-600 animate-pulse' : 'text-blue-600'}`}>
                  {referralPoints} ពិន្ទុ
                </span>
                {referralPoints >= 3 && (
                  <span className="ml-2 inline-flex items-center px-2 md:px-2.5 py-0.5 rounded-full text-xs font-medium bg-gradient-to-r from-yellow-200 to-yellow-400 text-yellow-800">
                    Premium ✨
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="w-full md:w-auto md:ml-4 lg:ml-6">
            <span className={`block text-xs md:text-sm font-medium ${referralPoints >= 3 ? 'text-indigo-600 font-semibold' : 'text-indigo-600'}`}>
              {getPointsMessage()}
            </span>
            <span className="text-xs text-gray-500">គោលដៅ: 3 ពិន្ទុ 🎯</span>
          </div>
        </div>
        {referralPoints < 3 && (
          <div className="mt-2 w-full bg-gray-200 rounded-full h-1.5 md:h-2">
            <div 
              className="bg-blue-600 rounded-full h-1.5 md:h-2 transition-all duration-500"
              style={{ width: `${(referralPoints / 3) * 100}%` }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// Add the required animation
const styles = `
@keyframes bounce-gentle {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}
@keyframes gradient-x {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.animate-bounce-gentle {
  animation: bounce-gentle 2s ease-in-out infinite;
}
.animate-gradient-x {
  background-size: 200% 200%;
  animation: gradient-x 15s ease infinite;
}
`;

const StyleSheet = () => <style>{styles}</style>;

export default function ReferralPointsWithStyles() {
  return (
    <>
      <StyleSheet />
      <ReferralPoints />
    </>
  );
}