import React from 'react';
import { Save } from 'lucide-react';
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-sql';

const HistorySection = ({ history, setQuery }) => {
  React.useEffect(() => {
    Prism.highlightAll();
  }, [history]);

  return (
    <div className="h-full min-h-[60vh] overflow-auto p-4">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Query History</h2>
      {history.length === 0 ? (
        <p className="text-gray-600 italic">No queries have been run yet.</p>
      ) : (
        <ul className="space-y-4">
          {history.slice().reverse().map((query, index) => (
            <li key={index} className="bg-gray-50 p-4 border border-gray-200 rounded">
              <pre className="relative overflow-x-auto">
                <code className="language-sql block font-mono text-sm whitespace-pre-wrap">
                  {query}
                </code>
              </pre>
              <button
                onClick={() => setQuery(query)}
                className="mt-2"
                icon={Save}
              >
                Load Query
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default HistorySection;