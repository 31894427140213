import React from 'react';
import { Award, Lock } from 'lucide-react';

const CourseStatusBadge = ({ courseAccessStatus }) => {
  if (courseAccessStatus.hasAccess) return null;

  const badgeConfig = courseAccessStatus.isFree ? {
    icon: <Award className="h-4 w-4" />,
    text: "Free",
    color: "border-green-500 text-green-600"
  } : {
    icon: <Lock className="h-4 w-4" />,
    text: "Premium",
    color: "border-blue-500 text-blue-600" 
  };

  return (
    <div className="ml-4">
      <div className={`inline-flex items-center gap-2 rounded-md bg-white border px-2.5 py-0.5 text-sm font-mono ${badgeConfig.color}`}>
        {badgeConfig.icon}
        <span>{badgeConfig.text}</span>
      </div>
    </div>
  );
};

export default CourseStatusBadge;