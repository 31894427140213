'use client'

import React, { useState, useEffect } from 'react'
import { Terminal, ExternalLink, Clock, Flame, Trophy, Star } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'
import axios from 'axios'

export default function DashboardComponent({ lastActive, currentStreak, longestStreak, name }) {
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [totalTimeSpent, setTotalTimeSpent] = useState(0)
  const [isTimeExpanded, setIsTimeExpanded] = useState(false)
  const [isStreakExpanded, setIsStreakExpanded] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const fetchTotalTimeSpent = () => {
      const token = localStorage.getItem('token')
      axios.get('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/get-time', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        setTotalTimeSpent(response.data.totalTimeSpent)
      })
      .catch(error => {
        console.error('Error fetching total time spent:', error)
      })
    }

    fetchTotalTimeSpent()
  }, [])

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (3600 * 24))
    const hours = Math.floor((seconds % (3600 * 24)) / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    const parts = []
    if (days > 0) parts.push(`${days}d`)
    if (hours > 0) parts.push(`${hours}h`)
    if (minutes > 0) parts.push(`${minutes}m`)
    if (remainingSeconds > 0 || parts.length === 0) parts.push(`${remainingSeconds}s`)

    return parts.join(' ')
  }

  const getCompactTime = (seconds) => {
    const days = Math.floor(seconds / (3600 * 24))
    const hours = Math.floor((seconds % (3600 * 24)) / 3600)
    if (days > 0) return `${days}d`
    if (hours > 0) return `${hours}h`
    return `${Math.floor(seconds / 60)}m`
  }

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  }

  const itemVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 }
  }

  return (
    <motion.div 
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="overflow-hidden transition-shadow duration-300"
    >
      <motion.div variants={itemVariants} className="flex items-center mb-6">
        <Terminal className="mr-3 text-orange-600 w-4 h-4 sm:w-6 sm:h-6" />
        <h1 className="text-xl sm:text-2xl font-bold text-gray-800">
          <span className="text-blue-600">dashboard</span>{' '}
          <span className="text-orange-500">=</span>{' '}
          <span className="text-blue-600">{'{'}</span>
        </h1>
      </motion.div>
      
      <div className="space-y-4 sm:space-y-6 ml-4 sm:ml-8">
        <motion.div variants={itemVariants} className="flex items-center">
          <span className="text-green-600 w-24 sm:w-32 font-mono text-sm sm:text-base">name:</span>
          <span className="text-orange-500 font-mono text-sm sm:text-base">"{name}"</span>
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2, repeat: Infinity, repeatType: "reverse" }}
            className="text-gray-400 ml-2 sm:ml-4 Nokora text-xs sm:text-base"
          >
            // ស្វាគមន៍មកវិញ
          </motion.span>
        </motion.div>

        <motion.div variants={itemVariants} className="flex items-start">
          <span className="text-green-600 w-24 sm:w-32 font-mono text-sm sm:text-base">description:</span>
          <span className="text-orange-500 Nokora text-sm sm:text-base">"កន្លែងគ្រប់គ្រងការធ្វើដំណើរសរសេរកូដរបស់អ្នក។ តាមដានវឌ្ឍនភាព បន្តការរៀនសូត្រ និងស្វែងរកផ្លូវថ្មីៗ។"</span>
        </motion.div>

        <motion.div variants={itemVariants} className="flex items-center">
          <span className="text-green-600 w-24 sm:w-32 font-mono text-sm sm:text-base">lastActive:</span>
          <span className="text-orange-500 font-mono text-sm sm:text-base">"{new Date(lastActive).toLocaleString('en-US', { timeZone: 'Asia/Phnom_Penh' })}"</span>
        </motion.div>

        <motion.div variants={itemVariants}>
          <div className="flex items-center mb-2">
            <span className="text-green-600 w-24 sm:w-32 font-mono text-sm sm:text-base">totalTimeSpent:</span>
            <span className="text-blue-600 ml-1">{'{'}</span>
            {!isTimeExpanded && (
              <span className="flex items-center ml-2">
                <Clock className="inline-block mr-2 text-orange-500" size={isSmallScreen ? 14 : 16} />
                <span className="text-green-600 text-sm sm:text-base">time:</span> 
                <span className="text-orange-500 ml-1 text-sm sm:text-base">"{getCompactTime(totalTimeSpent)}"</span>
              </span>
            )}
            {!isTimeExpanded && <span className="text-blue-600 ml-1">{'}'}</span>}
            <button 
              onClick={() => setIsTimeExpanded(!isTimeExpanded)}
              className="ml-2 text-blue-500 hover:text-blue-700 transition-colors duration-200 text-sm sm:text-base"
            >
              {isTimeExpanded ? '[-]' : '[+]'}
            </button>
          </div>
          <AnimatePresence>
            {isTimeExpanded && (
              <motion.div 
                className="ml-4 sm:ml-8"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <p className="flex items-center">
                  <Clock className="inline-block mr-2 text-orange-500" size={isSmallScreen ? 14 : 16} />
                  <span className="text-green-600 text-sm sm:text-base">time:</span> 
                  <span className="text-orange-500 ml-1 text-sm sm:text-base">"{formatTime(totalTimeSpent)}"</span>
                </p>
                <span className="text-blue-600">{'}'}</span>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        <motion.div variants={itemVariants}>
          <div className="flex items-center mb-2">
            <span className="text-green-600 w-24 sm:w-32 font-mono text-sm sm:text-base">streak:</span>
            <span className="text-blue-600 ml-1">{'{'}</span>
            {!isStreakExpanded && (
              <span className="flex items-center ml-2">
                <Flame className="mr-1 text-orange-400" size={isSmallScreen ? 12 : 14} />
                <span className="text-green-600 mr-1 text-sm sm:text-base">current:</span>
                <span className="text-blue-600 font-bold text-sm sm:text-base">{currentStreak}</span>
                <span className="text-gray-400 mx-1">/</span>
                <Trophy className="mr-1 text-yellow-400" size={isSmallScreen ? 12 : 14} />
                <span className="text-green-600 mr-1 text-sm sm:text-base">longest:</span>
                <span className="text-blue-600 font-bold text-sm sm:text-base">{longestStreak}</span>
              </span>
            )}
            {!isStreakExpanded && <span className="text-blue-600 ml-1">{'}'}</span>}
            <button 
              onClick={() => setIsStreakExpanded(!isStreakExpanded)}
              className="ml-2 text-blue-500 hover:text-blue-700 transition-colors duration-200 text-sm sm:text-base"
            >
              {isStreakExpanded ? '[-]' : '[+]'}
            </button>
          </div>
          <AnimatePresence>
            {isStreakExpanded && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="ml-4 sm:ml-8"
              >
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                  <div className="bg-gray-100 rounded-lg p-3 sm:p-4">
                    <p className="text-sm sm:text-base font-semibold mb-2 flex items-center">
                      <Flame className="mr-2 text-orange-400" size={isSmallScreen ? 14 : 16} /> 
                      <span className="text-green-600">currentStreak:</span>
                    </p>
                    <p className="text-base sm:text-lg font-bold text-blue-600">{currentStreak}<span className="text-xs sm:text-sm text-gray-600"> // days</span></p>
                  </div>
                  <div className="bg-gray-100 rounded-lg p-3 sm:p-4">
                    <p className="text-sm sm:text-base font-semibold mb-2 flex items-center">
                      <Trophy className="mr-2 text-yellow-400" size={isSmallScreen ? 14 : 16} /> 
                      <span className="text-green-600">longestStreak:</span>
                    </p>
                    <p className="text-base sm:text-lg font-bold text-blue-600">{longestStreak}<span className="text-xs sm:text-sm text-gray-600"> // days</span></p>
                  </div>
                </div>
                <div className="text-center">
                  <p className="text-sm sm:text-base font-semibold flex items-center justify-center text-gray-600">
                    <Star className="mr-2 text-yellow-400" size={isSmallScreen ? 14 : 16} />
                    <span className="text-green-600">status:</span> 
                    <span className="text-orange-500 ml-2">
                      "{currentStreak >= longestStreak ? "New record! 🔥" : `${longestStreak - currentStreak} days to beat your record!`}"
                    </span>
                  </p>
                </div>
                <span className="text-blue-600">{'}'}</span>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        <motion.a 
          variants={itemVariants}
          className="flex items-center text-gray-600 hover:text-blue-600 transition-colors duration-200"
          href="https://t.me/codeforcambodia"
          target='_blank'
          rel="noopener noreferrer"
          whileHover={{ x: 5 }}
        >
          <span className="text-green-600 w-24 sm:w-32 font-mono text-sm sm:text-base">telegramGroup:</span> 
          <span className="text-orange-500 font-mono text-sm sm:text-base">"https://t.me/codeforcambodia"</span>
          <ExternalLink size={isSmallScreen ? 14 : 16} className="ml-2" />
        </motion.a>
      </div>

      <motion.div variants={itemVariants} className="mt-4 sm:mt-6">
        <span className="text-blue-600 text-xl sm:text-2xl font-bold">{'}'}</span>
      </motion.div>
    </motion.div>
  )
}