import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const CourseDescription = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 150; // Adjust this value to change when the description is truncated

  const toggleExpansion = () => setIsExpanded(!isExpanded);

  const renderDescription = () => {
    if (description.length <= maxLength) {
      return <p className="text-gray-600">{description}</p>;
    }

    return (
      <>
        <p className={`text-gray-600 ${isExpanded ? '' : 'line-clamp-3'}`}>
          {description}
        </p>
        <button
          onClick={toggleExpansion}
          className="mt-2 text-blue-600 hover:text-blue-800 transition-colors duration-200 flex items-center Nokora"
        >
          {isExpanded ? (
            <>
              បង្រួម <ChevronUp size={16} className="ml-1" />
            </>
          ) : (
            <>
              ពង្រីក <ChevronDown size={16} className="ml-1" />
            </>
          )}
        </button>
      </>
    );
  };

  return (
    <div className="mb-6 bg-blue-50 p-4 rounded-md Nokora">
      <h3 className="text-lg font-semibold mb-2 text-blue-800">Course Description</h3>
      {renderDescription()}
    </div>
  );
};

export default CourseDescription;