import React from 'react';
import { Loader } from 'lucide-react';

const ProgressStatus = ({ isLoading, error }) => {
  if (!isLoading && !error) return null;

  return (
    <div className="fixed bottom-20 left-4 z-50 max-w-md">
      {isLoading && (
        <div className="bg-blue-50 border border-blue-200 p-4 rounded-lg shadow-sm">
          <div className="flex items-center">
            <Loader className="h-4 w-4 animate-spin text-blue-600" />
            <div className="ml-2 text-blue-800 font-medium Nokora">
              Saving Progress...
            </div>
          </div>
          <div className="text-blue-600 ml-6 mt-1 text-sm Nokora">
            សូមកុំផ្លាស់ប្តូរមេរៀន ឬ refresh 
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 border border-red-200 p-4 rounded-lg shadow-sm">
          <div className="text-red-800 font-medium">
            បរាជ័យក្នុងការរក្សាទុកវឌ្ឍនភាព
          </div>
          <div className="text-red-600 mt-1 text-sm">
            នឹងព្យាយាមម្តងទៀតនៅពេលក្រោយ
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgressStatus;