import { motion } from 'framer-motion';
import { Code, Terminal } from 'lucide-react';

export default function SidePanel() {


    return (
        <div className="hidden lg:block relative w-0 flex-1">
            <div className="absolute inset-0 h-full w-full object-cover">
                <div className="h-full w-full bg-gray-100 p-8 flex flex-col justify-center border-l border-gray-200">
                    <div className="text-gray-800 text-base mb-3">
                        <Code className="inline-block mr-2 text-blue-600" size={16} /> <span className="text-purple-600">function</span> <span className="text-blue-600 Nokora">ចូលគណនី</span>() {'{'}
                    </div>
                    <div className="text-green-600 text-base ml-4 mb-2 Nokora">
                        <span className="text-orange-600">return</span> "ដេីម្បីរៀន សុំចូលគណនី";
                    </div>
                    <div className="text-gray-800 text-base mb-3">
                        {'}'}
                    </div>
                    <div className="text-gray-800 text-base mb-3">
                        <Code className="inline-block mr-2 text-blue-600" size={16} /> <span className="text-purple-600">function</span> <span className="text-blue-600 Nokora">រៀន FREE</span>() {'{'}
                    </div>
                    <div className="text-green-600 text-base ml-4 mb-2 Nokora">
                        <span className="text-orange-600">return</span> "រៀនថ្នាក់ Python, C, HTML & CSS ដើយមិនគិតថ្លៃ";
                    </div>
                    <div className="text-gray-800 text-base mb-3">
                        {'}'}
                    </div>
                    <div className="text-gray-800 text-base mb-3">
                        <Code className="inline-block mr-2 text-blue-600" size={16} /> <span className="text-purple-600">function</span> <span className="text-blue-600 Nokora">ចុះឈ្មោះ FREE</span>() {'{'}
                    </div>
                    <div className="text-green-600 text-base ml-4 mb-2 Nokora">
                        <span className="text-orange-600">return</span> "បង្កើតគណនីមិនគិតថ្លៃ";
                    </div>
                    <div className="text-gray-800 text-base mb-3">
                        {'}'}
                    </div>
                    <div className="mt-6 space-y-2 font-nokora text-sm">
                        <div className="h-7 bg-white border border-gray-300 rounded flex items-center px-2">
                            <span className="text-orange-600 mr-1">const</span> ជំនាញ <span className="text-purple-600 mx-1">=</span> <span className="text-blue-600">buildSkills</span>();
                        </div>
                        <div className="h-7 bg-white border border-gray-300 rounded flex items-center px-2">
                            <span className="text-orange-600 mr-1">const</span> បទពិសោធន៍ <span className="text-purple-600 mx-1">=</span> <span className="text-blue-600">gainExperience</span>();
                        </div>
                        <div className="h-7 bg-white border border-gray-300 rounded flex items-center px-2">
                            <span className="text-orange-600 mr-1">const</span> ចាប់ផ្តើម <span className="text-purple-600 mx-1">=</span> <span className="text-blue-600">startLearning</span>();
                        </div>
                        <div className="Nokora h-7 bg-white border border-gray-300 rounded flex items-center px-2">
                            <span className="text-blue-600">console</span>.log(ជំនាញ, បទពិសោធន៍, ចាប់ផ្តើម);
                        </div>
                    </div>
                    <motion.div className="mt-6 text-gray-600 flex items-center">
                        <Terminal size={16} className="mr-2" />
                        <p className="text-gray-600">Ready to start coding? Let's begin!</p>
                    </motion.div>
                    
                </div>
            </div>
        </div>        
    );
}
