import { useState } from "react"
import { MessageSquare, Search, Plus, Flame } from "lucide-react"
import { Link } from "react-router-dom"
import Header from "../../Components/Headers/CodeForCambodiaHeader"
import Footer from "../../Components/Footer"
import CreatePost from "../../Components/Forum/CreatePost"

export default function ForumPage() {
  const [searchQuery, setSearchQuery] = useState("")
  const [showCreatePost, setShowCreatePost] = useState(false)
  const [posts, setPosts] = useState([
    {
      id: 1,
      title: "Best practices for React performance optimization",
      content: "I've been working on improving the performance of my React application...",
      author: "Sarah Chen",
      avatar: "/placeholder.svg",
      replies: 28,
      views: 875,
      tags: ["react", "performance", "web-dev"],
      timeAgo: "2d ago",
    },
    {
      id: 2,
      title: "How do you handle state management in large applications?",
      content: "Looking for advice on state management patterns for enterprise applications...",
      author: "Mike Johnson",
      avatar: "/placeholder.svg",
      replies: 45,
      views: 1234,
      tags: ["state-management", "architecture"],
      timeAgo: "3d ago",
    },
  ])

  const recommendedTopics = [
    "Programming",
    "Web Development", 
    "UI Design",
    "DevOps",
    "Machine Learning",
  ]

  const suggestedUsers = [
    {
      name: "Alex Rivera",
      avatar: "/placeholder.svg",
      bio: "Full-stack developer & open source contributor",
    },
    {
      name: "Emma Wilson",
      avatar: "/placeholder.svg",
      bio: "UI/UX Designer | Design Systems Expert",
    },
    {
      name: "David Kim",
      avatar: "/placeholder.svg",
      bio: "Software Architect | Tech Lead",
    },
  ]

  const handleNewPost = (newPost) => {
    const id = posts.length + 1
    const postToAdd = {
      id,
      title: newPost.title,
      content: newPost.content,
      author: "Current User",
      avatar: "/placeholder.svg",
      replies: 0,
      views: 0,
      tags: newPost.tags,
      timeAgo: "Just now",
    }
    setPosts([postToAdd, ...posts])
    setShowCreatePost(false)
  }

  const filteredPosts = posts.filter(post => {
    const query = searchQuery.toLowerCase()
    return (
      post.title.toLowerCase().includes(query) ||
      post.author.toLowerCase().includes(query)
    )
  })

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <header className="border-b">
        <div className="container mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">Community</h1>
            <button 
              onClick={() => setShowCreatePost(true)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Create Post
            </button>
          </div>
        </div>
      </header>

      <main className="container mx-auto px-4 py-6">
        {showCreatePost && (
          <CreatePost 
            onClose={() => setShowCreatePost(false)}
            onPostCreated={handleNewPost}
          />
        )}
        
        <div className="grid grid-cols-1 gap-6 md:grid-cols-3 lg:grid-cols-4">
          <div className="md:col-span-2 lg:col-span-3">
            <div className="mb-6 flex items-center gap-2">
              <div className="relative flex-1">
                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-500" />
                <input
                  type="text"
                  placeholder="Search by title or author..."
                  className="w-full pl-8 pr-4 py-2 border rounded"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <button className="px-4 py-2 border rounded hover:bg-gray-50 flex items-center">
                <Plus className="mr-2 h-4 w-4" />
                Add Tags
              </button>
            </div>

            <div className="space-y-4">
              {filteredPosts.map((post) => (
                <Link to={`/forum/post/${post.id}`} key={post.id}>
                  <div className="p-4 border rounded bg-white hover:bg-gray-50 transition-colors mb-4">
                    <div className="flex items-start gap-4">

                      <div className="flex-1 space-y-1">
                        <div className="flex items-center justify-between">
                          <p className="text-sm font-medium">{post.author}</p>
                          <span className="text-sm text-gray-500">{post.timeAgo}</span>
                        </div>
                        <h3 className="font-semibold">{post.title}</h3>
                        <p className="text-sm text-gray-600">{post.content}</p>
                        <div className="flex flex-wrap gap-2">
                          {post.tags.map((tag) => (
                            <span key={tag} className="px-2 py-1 text-sm bg-gray-100 rounded">
                              {tag}
                            </span>
                          ))}
                        </div>
                        <div className="flex items-center gap-4 pt-2">
                          <span className="flex items-center text-sm text-gray-500">
                            <MessageSquare className="mr-1 h-4 w-4" />
                            {post.replies} replies
                          </span>
                          <span className="text-sm text-gray-500">{post.views} views</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>

          <div className="space-y-6">
            <div className="border rounded bg-white">
              <div className="p-4 border-b">
                <h2 className="font-semibold flex items-center gap-2">
                  <Flame className="h-5 w-5 text-orange-500" />
                  Top Discussions
                </h2>
              </div>
              <div className="p-4">
                <div className="space-y-4">
                  {recommendedTopics.map((topic) => (
                    <Link
                      key={topic}
                      href="#"
                      className="block text-sm hover:text-blue-500"
                    >
                      {topic}
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            <div className="border rounded bg-white">
              <div className="p-4 border-b">
                <h2 className="font-semibold">People to Follow</h2>
              </div>
              <div className="p-4">
                <div className="space-y-4">
                  {suggestedUsers.map((user) => (
                    <div key={user.name} className="flex items-start gap-3">
                      <div className="flex-1">
                        <p className="text-sm font-medium">{user.name}</p>
                        <p className="text-xs text-gray-500">{user.bio}</p>
                      </div>
                      <button className="px-3 py-1 text-sm border rounded hover:bg-gray-50">
                        Follow
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer/>
    </div>
  )
}