import React from 'react'

const Tab = React.memo(({ active, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm font-medium border-b-2 ${
      active
        ? 'text-blue-600 border-blue-500'
        : 'text-gray-600 hover:text-gray-800 border-transparent'
    }`}
  >
    {children}
  </button>
))

export default Tab