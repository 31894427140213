"use client"

import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

const CodeLoadingAnimation = () => {
  const [particles, setParticles] = useState([])
  const [lineNumbers, setLineNumbers] = useState([])

  useEffect(() => {
    // Generate random particles
    const newParticles = Array.from({ length: 50 }, () => ({
      x: Math.random() * 100,
      y: Math.random() * 100,
      size: Math.random() * 4 + 1,
    }))
    setParticles(newParticles)

    // Generate line numbers for the code editor effect
    setLineNumbers(Array.from({ length: 15 }, (_, i) => (i + 1).toString().padStart(2, '0')))
  }, [])

  // Generate cube matrix based on height
  const generateCubes = (height) => {
    return Array.from({ length: 3 }, (_, w) =>
      Array.from({ length: 3 }, (_, l) => ({
        w: w + 1,
        l: l + 1,
        animation: {
          transform: [
            `translate(${(w * -50 - 50) + (l * 50 + 50)}%, ${(height * 50 - 200) + (w * 25 - 25) + (l * 25 + 25)}%)`,
            `translate(${(w * -50 - 50) + (l * 100 - 50)}%, ${(height * 50 - 200) + (w * 25 - 25) + (l * 50 - 25)}%)`,
            `translate(${(w * -100 + 50) + (l * 100 - 50)}%, ${(height * 50 - 200) + (w * 50 - 75) + (l * 50 - 25)}%)`,
            `translate(${(w * -100 - 100) + (l * 100 + 100)}%, ${(height * 100 - 400) + (w * 50 - 50) + (l * 50 + 50)}%)`,
            `translate(${(w * -100 - 100) + (l * 50 + 200)}%, ${(height * 100 - 400) + (w * 50 - 50) + (l * 25 + 100)}%)`,
            `translate(${(w * -50 - 200) + (l * 50 + 200)}%, ${(height * 100 - 375) + (w * 25 - 25) + (l * 25 + 100)}%)`,
            `translate(${(w * -50 - 50) + (l * 50 + 50)}%, ${(height * 50 - 200) + (w * 25 - 25) + (l * 25 + 25)}%)`,
          ]
        }
      }))
    )
  }

  // Cube component representing a code block
  const Cube = ({ className }) => (
    <div className={`relative w-[86px] h-[100px] ${className}`}>
      {/* Top face - represents function declaration */}
      <div 
        className="absolute w-[50px] h-[50px] bg-[#1d9099] face"
        style={{
          transform: 'rotate(210deg) skew(-30deg) translate(-75px, -22px) scaleY(0.86)',
          transformOrigin: '0 0',
          zIndex: 2,
          boxShadow: '0 0 15px rgba(29, 144, 153, 0.3)'
        }}
      >
        <div className="absolute inset-0 opacity-30">
          <div className="w-1/2 h-1 bg-white rounded m-2"></div>
          <div className="w-3/4 h-1 bg-white rounded m-2"></div>
        </div>
      </div>
      {/* Left face - represents code lines */}
      <div 
        className="absolute w-[50px] h-[50px] bg-[#D53A33] face"
        style={{
          transform: 'rotate(90deg) skewX(-30deg) scaleY(0.86) translate(25px, -50px)',
          transformOrigin: '0 0',
          boxShadow: '0 0 15px rgba(213, 58, 51, 0.3)'
        }}
      >
        <div className="absolute inset-0 opacity-30">
          <div className="w-3/4 h-1 bg-white rounded m-2"></div>
          <div className="w-1/2 h-1 bg-white rounded m-2"></div>
        </div>
      </div>
      {/* Right face - represents variables */}
      <div 
        className="absolute w-[50px] h-[50px] bg-[#E79C10] face"
        style={{
          transform: 'rotate(-30deg) skewX(-30deg) translate(49px, 65px) scaleY(0.86)',
          transformOrigin: '0 0',
          boxShadow: '0 0 15px rgba(231, 156, 16, 0.3)'
        }}
      >
        <div className="absolute inset-0 opacity-30">
          <div className="w-2/3 h-1 bg-white rounded m-2"></div>
          <div className="w-1/2 h-1 bg-white rounded m-2"></div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-white overflow-hidden">
      {/* Code editor-like header */}
      <div className="absolute top-0 left-0 right-0 h-12 bg-white border-b border-blue-200 flex items-center px-4">
        <div className="flex space-x-2">
          <div className="w-3 h-3 rounded-full bg-[#ff5f57]"></div>
          <div className="w-3 h-3 rounded-full bg-[#febc2e]"></div>
          <div className="w-3 h-3 rounded-full bg-[#28c840]"></div>
        </div>
        <div className="ml-4 text-blue-200 text-sm font-mono">loading.tsx</div>
      </div>

      {/* Line numbers */}
      <div className="absolute left-0 top-12 bottom-0 w-12 bg-white border-r border-blue-200 flex flex-col items-end pr-2 pt-4">
        {lineNumbers.map((num) => (
          <div key={num} className="text-blue-200 text-xs font-mono mb-1">
            {num}
          </div>
        ))}
      </div>

      {/* Particle background */}
      <div className="absolute inset-0">
        {particles.map((particle, index) => (
          <motion.div
            key={index}
            className="absolute bg-blue-400 rounded-full opacity-20"
            animate={{
              y: [particle.y + '%', particle.y - 10 + '%', particle.y + '%'],
            }}
            transition={{
              duration: 2 + Math.random() * 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
            style={{
              left: `${particle.x}%`,
              width: `${particle.size}px`,
              height: `${particle.size}px`,
            }}
          />
        ))}
      </div>

      {/* Code snippets background effect */}
      <div className="absolute inset-0 opacity-40">
        <div className="absolute top-[20%] left-[10%] font-mono text-[#569cd6]">{"const loading = true;"}</div>
        <div className="absolute top-[30%] right-[15%] font-mono text-[#4ec9b0]">{"interface LoadingProps {"}</div>
        <div className="absolute bottom-[25%] left-[20%] font-mono text-[#ce9178]">{'return <Loading />;'}</div>
      </div>
      
      <div className="relative h-[100px] w-[86px] scale-50 mt-12">
        {/* Code block matrix */}
        {[1, 2, 3].map((height) => (
          <div key={`h${height}`} className="absolute">
            {generateCubes(height).map((row, w) =>
              row.map(({ w: width, l: length, animation }) => (
                <motion.div
                  key={`h${height}w${width}l${length}`}
                  className="absolute"
                  style={{ 
                    zIndex: -height,
                    transformStyle: 'preserve-3d'
                  }}
                  animate={{ transform: animation.transform }}
                  transition={{
                    duration: 3,
                    ease: "easeInOut",
                    repeat: Infinity,
                    times: [0, 0.14, 0.28, 0.43, 0.57, 0.71, 0.85, 1]
                  }}
                >
                  <Cube className="transform-style-preserve-3d" />
                </motion.div>
              ))
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CodeLoadingAnimation