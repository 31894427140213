import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { CheckCircle, XCircle, ArrowLeft, Code } from 'lucide-react'
import { Link } from 'react-router-dom'

export default function Response() {
  const location = useLocation()
  const [status, setStatus] = useState(null)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get('success') === 'true') {
      setStatus('success')
    } else if (searchParams.get('canceled') === 'true') {
      setStatus('canceled')
    }
  }, [location])

  const messages = {
    success: {
      title: 'Thank You for Your Donation!',
      description: 'Your generosity will help empower Cambodian youth through coding education.',
      icon: <CheckCircle className="w-16 h-16 text-green-500" />,
      color: 'text-green-500',
    },
    canceled: {
      title: 'Donation Canceled',
      description: `No worries! Feel free to try again when you're ready.`,
      icon: <XCircle className="w-16 h-16 text-orange-500" />,
      color: 'text-orange-500',
    },
  }

  if (!status) return null

  return (
    <div className="min-h-screen bg-white flex items-center justify-center px-4 py-12">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full"
      >
        <div className="text-center">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2, type: 'spring', stiffness: 200 }}
            className='flex justify-center items-center'
          >
            {messages[status].icon}
          </motion.div>
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            className={`mt-6 text-3xl font-bold ${messages[status].color}`}
          >
            {messages[status].title}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            className="mt-4 text-lg text-gray-600"
          >
            {messages[status].description}
          </motion.p>
        </div>

        {status === 'success' && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
            className="mt-8 bg-blue-500 bg-opacity-10 rounded-lg p-4"
          >
            <p className="text-sm text-blue-500 flex items-center">
              <Code className="w-5 h-5 mr-2" />
              You will receive an email confirmation shortly. Thank you for supporting CodeForCambodia!
            </p>
          </motion.div>
        )}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.7, duration: 0.5 }}
          className="mt-8"
        >
          <Link to="/" className="block">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-white bg-purple-500 rounded-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-all duration-300"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Return to Homepage
            </motion.button>
          </Link>
        </motion.div>
      </motion.div>
    </div>
  )
}