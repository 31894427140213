import React from 'react';
import Header from '../../Components/Headers/CodeForCambodiaHeader';
import Footer from '../../Components/Footer';
import Post from '../../Components/Forum/Post';
import CommentSection from '../../Components/Forum/CommentSection';
import Sidebar from '../../Components/Forum/Sidebar';

const PostPage = () => {
  return (
    <div className="min-h-screen bg-white">
      <Header />
      <div className="max-w-[1200px] mx-auto px-4 py-5 flex">
        <main className="flex-grow mr-6">
          <Post />
          <CommentSection />
        </main>
        <Sidebar />
      </div>
      <Footer />
    </div>
  );
};

export default PostPage;