import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Calendar, Trophy, Users, Zap, Award, Terminal, Medal, Info, User } from 'lucide-react';
import Header from '../Components/Headers/CodeForCambodiaHeader';
import Footer from '../Components/Footer';
import SubmissionCard from '../Components/Challenge/SubmissionCard';
import SubmissionsList from '../Components/Challenge/SubmissionList';
import CodeLoadingAnimation from '../Components/CodeLoadingAnimation';
import SearchBar from '../Components/Challenge/SearchBar';
import GeneralModal from '../Components/Modals/GeneralModal';

const ChallengePage = () => {
    const [challenge, setChallenge] = useState(null);
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('newest');
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const navigate = useNavigate();
  const [showMobileInfo, setShowMobileInfo] = useState(false);
  const [searchCount, setSearchCount] = useState(0);
  const [showLoginModal, setShowLoginModal] = useState(false);

  useEffect(() => {
    const fetchCurrentChallenge = async () => {
      try {
        const response = await axios.get('http://localhost:4000/codecademy/challenges/challenge');
        const currentChallenge = response.data.data.find(c => c.status === 'active');
        if (currentChallenge) {
          setChallenge(currentChallenge);
          fetchSubmissions(currentChallenge.id);
        }
      } catch (err) {
        setError('Failed to load challenge');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchCurrentChallenge();
  }, []);

  useEffect(() => {
    if (challenge) {
      fetchSubmissions(challenge.id);
    }
  }, [sortBy, page, searchTerm]);

  const fetchSubmissions = async (challengeId) => {
    try {
        setIsSearching(!!searchTerm);
        const token = localStorage.getItem('token');
        
        let url = `http://localhost:4000/codecademy/challenges/submission/challenges/${challengeId}/submissions?sort=${sortBy}&page=${page}&limit=10`;
        
        if (searchTerm) {
            url += `&searchTerm=${searchTerm}`;
        }

        console.log("Fetching submissions from:", url);

        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        
        const response = await axios.get(url, { headers });
        console.log("Response:", response.data);
        
        if (response.data.success) {
            setSubmissions(response.data.data);
            setSearchCount(response.data.total || 0);
        } else {
            setError('Failed to fetch submissions');
        }
        setIsSearching(false);
    } catch (err) {
        console.error('Error fetching submissions:', err);
        setError('Failed to fetch submissions');
        setIsSearching(false);
    }
};

  const handleSearch = (term) => {
    setSearchTerm(term);
    setPage(1);
    setIsSearching(true);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setPage(1);
    setIsSearching(false);
  };

    const handleParticipate = () => {
        navigate(`/workspace`);
    };

    const handleSortChange = (newSort) => {
        setSortBy(newSort);
        setPage(1);
    };

    const castVote = async (submissionId) => {
        try {
            if (!localStorage.getItem('token')) {
                setShowLoginModal(true);
                return;
            }

            await axios.post(
                `http://localhost:4000/codecademy/challenges/vote/submissions/${submissionId}/vote`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            setSubmissions((prevSubmissions) =>
                prevSubmissions.map((submission) =>
                    submission.id === submissionId
                        ? { ...submission, user_voted: 1, vote_count: submission.vote_count + 1 }
                        : submission
                )
            );
        } catch (error) {
            console.error('Error casting vote:', error);
            
        }
    };

    const removeVote = async (submissionId) => {
        try {
            if (!localStorage.getItem('token')) {
                setShowLoginModal(true);
                return;
            }

            await axios.delete(
                `http://localhost:4000/codecademy/challenges/vote/submissions/${submissionId}/vote`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            setSubmissions((prevSubmissions) =>
                prevSubmissions.map((submission) =>
                    submission.id === submissionId
                        ? { ...submission, user_voted: 0, vote_count: submission.vote_count - 1 }
                        : submission
                )
            );
        } catch (error) {
            console.error('Error removing vote:', error);

        }
    };

    if (loading)
        return (
            <CodeLoadingAnimation/>
        );

    if (error)
        return (
            <div className="text-center text-red-600 p-4">
                {error}
            </div>
        );

    if (!challenge)
        return (
            <div className="text-center p-4">
                No active challenge found.
            </div>
        );

    return (
        <div className="min-h-screen bg-gray-50">
            <Header/>
            {/* Hero Section */}
            <div className="bg-white relative overflow-hidden font-mono">
                <div className="absolute inset-0 bg-blue-600 transform -skew-y-6 origin-top-left z-0"></div>
                <div className="max-w-7xl mx-auto px-4 py-12 relative z-10">
                    <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-6">
                        <div className="space-y-4">
                            <h1 className="text-4xl md:text-5xl font-bold text-white">
                                READY, SET, CODE:
                                <br />
                                <span className="text-blue-200 Nokora">{challenge.title}</span>
                            </h1>
                            <p className="text-blue-100 max-w-2xl Nokora">{challenge.description}</p>
                        </div>
                        <button
                            onClick={handleParticipate}
                            className="bg-white Nokora text-blue-600 px-8 py-4 rounded-lg font-bold text-lg hover:bg-blue-50 transform hover:-translate-y-1 transition-all group flex items-center gap-2"
                        >
                            ចូលរួមប្រកួត
                            <Zap className="w-5 h-5 group-hover:animate-pulse" />
                        </button>
                    </div>
                </div>
            </div>

            {/* Challenge Info Sections */}
            <div className={`${showMobileInfo ? 'block' : 'hidden'} font-mono md:grid max-w-7xl mx-auto px-4 py-12 md:grid-cols-3 gap-8`}>
                <div className="bg-white p-6 rounded-lg border-t-4 border-blue-500">
                    <div className="flex items-center gap-3 mb-4">
                        <Terminal className="w-6 h-6 text-blue-500" />
                        <h2 className="text-xl font-bold Nokora">អំពីការប្រកួត</h2>
                    </div>
                    <div className="space-y-3 text-gray-600">
                        <div className="space-y-4">
                            <div className="flex items-center gap-3">
                                <Trophy className="w-5 h-5 text-yellow-500" />
                                <p className="font-medium Nokora">ជ័យលាភីលេខ១៖ <span className="text-green-600 font-bold">រង្វាន់ $២៥</span></p>
                            </div>
                            <div className="flex items-center gap-3">
                                <Medal className="w-5 h-5 text-gray-400" />
                                <p className="font-medium Nokora">ជ័យលាភីលេខ២៖ <span className="text-green-600 font-bold">រង្វាន់ $១០</span></p>
                            </div>
                            <div className="flex items-center gap-3">
                                <Award className="w-5 h-5 text-amber-600" />
                                <p className="font-medium Nokora">ជ័យលាភីលេខ៣៖ <span className="text-green-600 font-bold">រង្វាន់ $៥</span></p>
                            </div>
                        </div>
                        <div className="flex items-center gap-2 text-sm mt-4">
                            <Calendar className="w-4 h-4" />
                            <span className="Nokora">បញ្ចប់នៅ៖ {new Date(challenge.end_date).toLocaleDateString()}</span>
                        </div>
                    </div>
                </div>

                <div className="bg-white p-6 rounded-lg border-t-4 border-blue-500">
                    <div className="flex items-center gap-3 mb-4">
                        <Trophy className="w-6 h-6 text-blue-500" />
                        <h2 className="text-xl font-bold Nokora">របៀបចូលរួម</h2>
                    </div>
                    <div className="space-y-3 text-gray-600 Nokora">
                        <p>១. ចុចលើ "ចូលរួមប្រកួត"</p>
                        <p>២. សរសេរកូដរបស់អ្នកនៅក្នុងកន្លែងធ្វើការ</p>
                        <p>៣. Submit សម្រាប់អោយសហគមន៍បោះឆ្នោត</p>
                        <div className="flex items-center gap-2 text-sm mt-4">
                            <Users className="w-4 h-4" />
                            <span>អ្នកចូលរួម {submissions.length} នាក់</span>
                        </div>
                    </div>
                </div>

                <div className="bg-white p-6 rounded-lg border-t-4 border-blue-500">
                    <div className="flex items-center gap-3 mb-4">
                        <Award className="w-6 h-6 text-blue-500" />
                        <h2 className="text-xl font-bold Nokora">តម្រូវការបច្ចេកទេស</h2>
                    </div>
                    <div className="space-y-3 text-gray-600 Nokora">
                        <p>បច្ចេកវិទ្យាដែលត្រូវការ៖</p>
                        <div className="flex flex-wrap gap-2">
                            {(typeof challenge.tech_stack === 'string'
                                ? JSON.parse(challenge.tech_stack)
                                : challenge.tech_stack
                            ).map((tech, index) => (
                                <span
                                    key={index}
                                    className="px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm"
                                >
                                    {tech}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile Info Button */}
            <div className="md:hidden max-w-7xl mx-auto px-4 py-6 font-mono">
                <button 
                    onClick={() => setShowMobileInfo(prev => !prev)} 
                    className="Nokora w-full bg-white text-blue-600 px-6 py-3 rounded-lg font-medium text-lg border border-blue-200 hover:bg-blue-50 transition-all flex items-center justify-center gap-2"
                >
                    <Info className="w-5 h-5" />
                    ព័ត៌មានបន្ថែម
                </button>
            </div>


        
        <SubmissionsList 
          submissions={submissions}
          sortBy={sortBy}
          handleSortChange={handleSortChange}
          page={page}
          setPage={setPage}
          castVote={castVote}
          removeVote={removeVote}
          isSearching={isSearching}
          onSearch={handleSearch}
          onClear={clearSearch}
          searchCount={searchCount}
        />

            <GeneralModal
                isOpen={showLoginModal}
                onClose={() => setShowLoginModal(false)}
                title="Login Required"
                >
                <div className="text-center space-y-6 py-4 Nokora items-center flex justify-center flex-col">
                    <div className="flex flex-col items-center">
                        <Trophy className="w-16 h-16 text-yellow-500 mb-4" />
                        <h3 className="text-2xl font-semibold text-gray-800 mb-2">Join the Community!</h3>
                        <p className="text-gray-600 max-w-sm">ចូលគណនីដើម្បីបោះឆ្នោតឱ្យ project ដែលអ្នកចូលចិត្ត និងក្លាយជាផ្នែកមួយនៃសហគមន៍អ្នកសរសេរកូដយើង។</p>
                    </div>
                    <button
                        onClick={() => {
                            setShowLoginModal(false);
                            navigate('/login');
                        }}
                        className="bg-blue-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-blue-700 transform hover:scale-105 transition-all duration-200 flex items-center justify-center gap-2 shadow-lg"
                    >
                        <User className="w-5 h-5" />
                        Sign in to Vote
                    </button>
                </div>
            </GeneralModal>

            <Footer/>
        </div>
    );
};

export default ChallengePage;