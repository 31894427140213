import React from 'react';
import { Check, X, Code, Terminal, Zap } from 'lucide-react';
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader';

const PricingPage = () => {
  const plans = [
    {
      name: 'Basic',
      price: '0',
      period: '',
      features: ['Access to basic courses', 'Community forum access', 'Monthly coding challenge'],
      notIncluded: ['Premium courses', 'One-on-one mentorship', 'Job placement assistance'],
      cta: 'Start Coding',
      popular: false,
    },
    {
      name: 'Pro',
      price: '2.99',
      period: '/month',
      features: ['All Basic features', 'Access to all courses', 'Priority community support', 'Monthly live Q&A sessions'],
      notIncluded: ['One-on-one mentorship', 'Job placement assistance'],
      cta: 'Upgrade to Pro',
      popular: true,
    },
    {
      name: 'Elite',
      price: '4.99',
      period: '/3 months',
      features: ['All Pro features', 'One-on-one mentorship', 'Job placement assistance', 'Exclusive industry workshops'],
      notIncluded: [],
      cta: 'Go Elite',
      popular: false,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50   font-mono text-gray-800">
      <CodeForCambodiaHeader/>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold sm:text-4xl">
            <Terminal className="inline-block mr-2 text-blue-600" />
            <span className="text-blue-600">const</span> pricing <span className="text-blue-600">=</span> <span className="text-purple-600">new</span> <span className="text-green-600">Pricing</span>()
          </h2>
          <p className="mt-4 text-xl text-gray-600">// Choose the perfect plan for your coding journey</p>
        </div>

        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none">
          {plans.map((plan, index) => (
            <div key={plan.name} className={`bg-white border ${plan.popular ? 'border-blue-500 shadow-lg' : 'border-gray-200'} rounded-lg overflow-hidden`}>
              <div className={`p-6 ${plan.popular ? 'bg-blue-50' : 'bg-gray-50'}`}>
                <h3 className="text-lg leading-6 font-medium">
                  <Code className="inline-block mr-2 text-blue-600" />
                  <span className="text-blue-600">class</span> {plan.name}Plan<span className="text-blue-600">:</span>
                </h3>
                <div className="mt-4 flex items-baseline text-3xl font-extrabold">
                  ${plan.price}
                  <span className="ml-1 text-xl font-medium text-gray-500">{plan.period}</span>
                </div>
                {plan.popular && (
                  <p className="mt-2 text-sm text-blue-600 font-semibold">// Most Popular</p>
                )}
              </div>
              <div className="px-6 pt-6 pb-8">
                <h4 className="text-sm leading-5 font-medium mb-4">
                  <span className="text-blue-600">def</span> features(self):
                </h4>
                <ul className="space-y-4">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-start">
                      <Check className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                  {plan.notIncluded.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-start text-gray-400">
                      <X className="h-6 w-6 text-red-500 mr-2 flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className="mt-8">
                  <button
                    className={`w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white ${
                      plan.popular ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-800 hover:bg-gray-900'
                    } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                  >
                    <Zap className="w-5 h-5 mr-2" />
                    {plan.cta}
                  </button>
                </div>
              </div>
              <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
                <p className="text-xs text-gray-500">
                  <span className="text-blue-600"># Limited time offer. Terms apply.</span>
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <p className="text-base text-gray-600">
            <span className="text-blue-600">if</span> hesitating:
          </p>
          <p className="mt-2 text-xl font-semibold text-gray-800">
            print(<span className="text-green-600">"Don't miss out on accelerating your coding career!"</span>)
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;