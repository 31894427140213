import React from 'react';
import { X, Play, Share2, Users, AwardIcon } from 'lucide-react';

const PremiumTutorialModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl max-w-2xl w-full     overflow-hidden shadow-xl">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b">
          <h3 className="text-lg font-semibold Nokora">របៀបទទួលបាន Premium</h3>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>

        {/* Video Tutorial Section */}
        <div className="p-6">
          <div className="relative w-full bg-gray-900 rounded-lg overflow-hidden" style={{ paddingBottom: '56.25%' }}>
            <video
              className="absolute inset-0 w-full h-full object-cover"
              controls
              autoPlay
            >
              <source src="https://codeforcambodia.s3.us-east-005.backblazeb2.com/compressedDemoAccount.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        {/* Steps Section */}
        <div className="px-6 pb-6 pt-6">
          <div className="space-y-4">
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
                <Share2 className="w-4 h-4 text-blue-600" />
              </div>
              <div>
                <p className="Nokora text-sm text-gray-600">នៅពេលមិត្តភក្តិរបស់អ្នកចុះឈ្មោះ សូមប្រើអ៊ីមែលរបស់អ្នកជាការណែនាំ</p>
              </div>
            </div>
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
                <Users className="w-4 h-4 text-blue-600" />
              </div>
              <div>
                <p className="Nokora text-sm text-gray-600">នៅពេលមិត្តភក្តិប្រើ email account របស់អ្នក អ្នកនឹងទទួលបាន ១ ពិន្ទុ</p>
              </div>
            </div>

            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
                <AwardIcon className="w-4 h-4 text-blue-600" />
              </div>
              <div>
                <p className="Nokora text-sm text-gray-600">នៅពេលអ្នកមាន ៣ ពិន្ទុ អ្នកនឹងទទួលបាន Premium</p>
              </div>
            </div>
          </div>
        </div>

        {/* Action Button */}
        <div className="p-4 border-t">
          <button
            className="w-full bg-blue-600 text-white rounded-lg py-2 px-4 hover:bg-blue-700 transition-colors Nokora font-medium"
            onClick={onClose}
          >
            យល់ហើយ
          </button>
        </div>
      </div>
    </div>
  );
};

export default PremiumTutorialModal;