import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Terminal } from 'lucide-react';
import { motion } from 'framer-motion';

export default function ViewAllCoursesButton() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className='w-full flex justify-center items-center mt-4'>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Link
          to="/courses"
          className="inline-block font-mono text-left bg-white border-2 border-gray-300 rounded-md transition-all duration-300"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <motion.div 
            className="p-3 relative overflow-hidden"
            whileHover={{ scale: 1.03 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <motion.div
              className="absolute top-0 left-0 w-full h-0.5 bg-blue-500"
              initial={{ scaleX: 0 }}
              animate={{ scaleX: isHovered ? 1 : 0 }}
              transition={{ duration: 0.3 }}
            />
            <div className="flex items-center space-x-2">
              <Terminal className="text-gray-600" size={18} />

              <div className="flex items-center text-blue-600">
                <span className="Nokora font-medium">ថ្នាក់ទាំងអស់</span>
                <motion.div
                  animate={{ x: isHovered ? 5 : 0 }}
                  transition={{ type: "spring", stiffness: 300, damping: 10 }}
                >
                  <ChevronRight className="ml-1" size={14} />
                </motion.div>
              </div>
            </div>
          </motion.div>
        </Link>
      </motion.div>
    </div>
  );
}