import * as React from "react"
import { Gift, ChevronDown, X, EyeOff, Download, Check, Copy, Share2 } from "lucide-react"
import axios from "axios"

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto px-4 sm:px-0">
      <div className="flex min-h-screen items-end sm:items-center justify-center text-center sm:p-0">
        <div 
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={onClose}
          aria-hidden="true"
        />

        <div className="relative inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom sm:align-middle shadow-xl transition-all w-full sm:max-w-md mx-auto sm:w-full">
          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <button
              onClick={onClose}
              className="absolute right-4 top-4 text-gray-400 hover:text-gray-500"
              aria-label="Close modal"
            >
              <X className="h-5 w-5" />
            </button>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default function FreePythonDropdown() {
  const [isOpen, setIsOpen] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [isVisible, setIsVisible] = React.useState(true)
  const [isEligible, setIsEligible] = React.useState(false)
  const [message, setMessage] = React.useState("")
  const [downloadLink, setDownloadLink] = React.useState("")
  const [isChecking, setIsChecking] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [isCopying, setIsCopying] = React.useState(false)
  const [isSharing, setIsSharing] = React.useState(false)
  const [copySuccess, setCopySuccess] = React.useState(false)
  const [shareSuccess, setShareSuccess] = React.useState(false)

  React.useEffect(() => {
    const hidden = localStorage.getItem("pythonDropdownHidden")
    if (hidden === "true") {
      setIsVisible(false)
    }
  }, [])

  const checkEligibility = async () => {
    try {
      setIsChecking(true)
      setError(null)
      
      const response = await axios.get("https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/refer/download", {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      })

      setIsEligible(response.data.is_eligible)
      setMessage(response.data.message)
      if (response.data.download_link) {
        setDownloadLink(response.data.download_link)
      }
    } catch (err) {
      setError("សូមចុះឈ្មោះមុនសិន ដើម្បីទទួលបានមេរៀន Python")
      console.error("Error:", err)
    } finally {
      setIsChecking(false)
    }
  }

  const handleDownload = async () => {
    if (downloadLink) {
      window.open(downloadLink, "_blank")
      // Show success message
      const successMessage = document.createElement('div')
      successMessage.className = 'fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow-lg Nokora'
      successMessage.textContent = 'កំពុងទាញយក Python Resources...'
      document.body.appendChild(successMessage)
      setTimeout(() => document.body.removeChild(successMessage), 3000)
    }
  }

  const handleCopy = async () => {
    try {
      setIsCopying(true)
      await navigator.clipboard.writeText("https://codeforcambodia.com")
      setCopySuccess(true)
      // Show success message
      const successMessage = document.createElement('div')
      successMessage.className = 'fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow-lg Nokora'
      successMessage.textContent = 'បានចម្លង Link ជោគជ័យ!'
      document.body.appendChild(successMessage)
      setTimeout(() => document.body.removeChild(successMessage), 3000)
    } catch (err) {
      const errorMessage = document.createElement('div')
      errorMessage.className = 'fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded shadow-lg Nokora'
      errorMessage.textContent = 'មិនអាចចម្លងបានទេ សូមព្យាយាមម្តងទៀត'
      document.body.appendChild(errorMessage)
      setTimeout(() => document.body.removeChild(errorMessage), 3000)
    } finally {
      setTimeout(() => {
        setIsCopying(false)
        setCopySuccess(false)
      }, 2000)
    }
  }

  const handleShare = async () => {
    try {
      setIsSharing(true)
      if (navigator.share) {
        await navigator.share({
          title: "Code For Cambodia - Free Python Courses",
          text: "រៀន Python ជាមួយ Code For Cambodia ដោយឥតគិតថ្លៃ!",
          url: "https://codeforcambodia.com",
        })
        setShareSuccess(true)
        const successMessage = document.createElement('div')
        successMessage.className = 'fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow-lg Nokora'
        successMessage.textContent = 'បានចែករំលែកជោគជ័យ!'
        document.body.appendChild(successMessage)
        setTimeout(() => document.body.removeChild(successMessage), 3000)
      } else {
        await handleCopy()
      }
    } catch (err) {
      if (err.name !== "AbortError") {
        const errorMessage = document.createElement('div')
        errorMessage.className = 'fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded shadow-lg Nokora'
        errorMessage.textContent = 'មិនអាចចែករំលែកបានទេ សូមព្យាយាមម្តងទៀត'
        document.body.appendChild(errorMessage)
        setTimeout(() => document.body.removeChild(errorMessage), 3000)
      }
    } finally {
      setTimeout(() => {
        setIsSharing(false)
        setShareSuccess(false)
      }, 2000)
    }
  }

  const closeModal = () => setShowModal(false)

  const handleHide = () => {
    setIsVisible(false)
    localStorage.setItem("pythonDropdownHidden", "true")
    const successMessage = document.createElement('div')
    successMessage.className = 'fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow-lg Nokora'
    successMessage.textContent = 'Hide Menu'
    document.body.appendChild(successMessage)
    setTimeout(() => document.body.removeChild(successMessage), 3000)
  }

  React.useEffect(() => {
    if (isOpen) {
      checkEligibility()
    }
  }, [isOpen])

  if (!isVisible) return null

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 text-base transition-all duration-300 ease-in-out hover:-translate-y-0.5"
        aria-expanded={isOpen}
      >
        <span className="bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 bg-clip-text text-transparent font-bold">
          FREE Python
        </span>
        <Gift className="h-4 w-4 text-yellow-500 animate-bounce" />
        <ChevronDown 
          className={`ml-1 h-4 w-4 transition-transform duration-300 ${
            isOpen ? "rotate-180" : ""
          }`} 
        />
      </button>
      
      {isOpen && (
        <div className="absolute right-0 sm:right-0 mt-2 w-screen sm:w-64 md:w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10 max-w-[calc(100vw-2rem)] sm:max-w-none mx-4 sm:mx-0 -right-4 sm:right-0">
          <div className="p-4 space-y-3">
            {isChecking ? (
              <div className="text-center text-sm text-gray-600 Nokora">
                កំពុងពិនិត្យមើល...
              </div>
            ) : error ? (
              <div className="text-center text-sm text-red-600 Nokora">
                {error}
              </div>
            ) : isEligible ? (
              <button
                onClick={handleDownload}
                className="w-full text-base flex items-center justify-center space-x-2 text-blue-500 hover:text-blue-700 transition-colors duration-300 Nokora"
              >
                <Download className="h-4 w-4" />
                <span className="underline decoration-dotted underline-offset-4">
                  ទាញយកមេរៀន Python
                </span>
              </button>
            ) : (
              <button
                onClick={() => setShowModal(true)}
                className="w-full text-center py-2 text-sm text-gray-600 hover:text-gray-800 transition-colors duration-300 Nokora"
              >
                {message || "ចុចដើម្បីមើលព័ត៌មានបន្ថែម"}
              </button>
            )}
            
            <div className="border-t border-gray-100" />
            
            <button
              onClick={handleHide}
              className="w-full flex items-center justify-center space-x-2 text-xs text-gray-400 hover:text-gray-600 transition-colors duration-300 Nokora"
            >
              <EyeOff className="h-3 w-3" />
              <span>Hide Menu</span>
            </button>
          </div>
        </div>
      )}

      <Modal isOpen={showModal} onClose={closeModal}>
        <div className="space-y-4 max-h-[80vh] overflow-y-auto">
          <h3 className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 bg-clip-text text-transparent Nokora">
            ទទួលបានមេរៀន Python ដោយឥតគិតថ្លៃ! 🎁
          </h3>
          
          <p className="text-gray-600 Nokora text-sm sm:text-base">
            អនុវត្តតាមជំហានទាំងនេះដើម្បីទទួលបានមេរៀន Python របស់យើង៖
          </p>
          
          <ol className="list-decimal pl-4 space-y-2 text-gray-600 Nokora text-sm sm:text-base">
            <li>ហៅមិត្តមកចុះឈ្មោះ តាមរយៈ Link ខាងក្រោម</li>
            <li>នៅពេលគាត់ចុះឈ្មោះ សូមប្រាប់គាត់ឱ្យដាក់ email របស់អ្នកនៅកន្លែង "ហៅមិត្ត"</li>
            <li>បន្ទាប់ពីគាត់ចុះឈ្មោះរួច អ្នកនឹងទទួលបានមេរៀន Python ដោយស្វ័យប្រវត្តិ</li>
            <li>ត្រលប់មកកាន់ទំព័រនេះវិញ ដើម្បីទាញយក Resources</li>
          </ol>
          
          <div className="mt-6 p-4 bg-blue-50 rounded-lg">
            <p className="text-xs sm:text-sm text-blue-600 Nokora">
              💡 គន្លឹះ៖ កាន់តែច្រើននាក់ដែលអ្នកហៅមកចុះឈ្មោះ កាន់តែមានឱកាសទទួលបាន Premium Resources បន្ថែម!
            </p>
          </div>
          
          <div className="flex flex-col sm:flex-row gap-2 mt-4">
            <button
              onClick={handleCopy}
              className={`flex-1 px-4 py-2 border border-gray-300 rounded-md flex items-center justify-center gap-2 font-medium transition-all duration-200 Nokora
                ${isCopying || copySuccess ? 'bg-green-50 border-green-500 text-green-600' : 'hover:bg-gray-50'}`}
            >
              {copySuccess ? (
                <Check className="h-4 w-4" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
              ចម្លង Link
            </button>
            
            <button
              onClick={handleShare}
              className={`flex-1 px-4 py-2 rounded-md flex items-center justify-center gap-2 font-medium text-white transition-all duration-200 Nokora
                ${isSharing || shareSuccess ? 'bg-green-500' : 'bg-blue-500 hover:bg-blue-600'}`}
            >
              {shareSuccess ? (
                <Check className="h-4 w-4" />
              ) : (
                <Share2 className="h-4 w-4" />
              )}
              ចែករំលែក
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}