import axios from 'axios';

export const fetchReferralPoints = async (setReferralPoints) => {
    try {
      const response = await axios.get('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/auth/referral-points');
      if (response.data.success) {
        setReferralPoints(response.data.referralPoints);
      }
    } catch (error) {
      console.error('Error fetching referral points:', error);
    }
};