// utils/courseAccess.js
import axios from 'axios';

/**
 * Checks if a course is free or premium and if the user has access
 * @param {number} courseId - The ID of the course to check
 * @returns {Promise<{isFree: boolean, hasAccess: boolean}>} Object containing course access status
 * @throws {Error} If there's an error checking course access
 */
export const checkCourseAccess = async (courseId) => {
  try {
    const response = await axios.get(`https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/auth/course-access/${courseId}`);
    
    if (response.data.success) {
      
      return {
        isFree: response.data.courseAccess.isFree,
        hasAccess: response.data.courseAccess.hasAccess
      };
    }

    throw new Error('Failed to get course access status');
  } catch (error) {
    console.error('Error checking course access:', error);
    // Re-throw the error to be handled by the calling component
    throw new Error(error.response?.data?.message || 'Error checking course access');
  }
};

// Usage example:
/*
try {
  const { isFree, hasAccess } = await checkCourseAccess(courseId);
  if (!isFree && !hasAccess) {
    // Handle premium course without access
  }
} catch (error) {
  // Handle error
}
*/