import React, { useState } from 'react';
import { ChevronDown, Code, Eye, EyeOff, Gift, Mail} from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SidePanel from '../../Components/Account/SidePanel';

// Add sanitization utilities at the top
const sanitizeInput = {
  text: (value) => value.trim().replace(/[<>]/g, ''),
  name: (value) => value.trim().replace(/[^a-zA-Z\s]/g, ''),
  email: (value) => value.trim().toLowerCase(),
  number: (value) => value.replace(/[^0-9]/g, '')
};

const Register = ({onRegisterSuccess}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const [referralEmail, setReferralEmail] = useState('');
  const [showReferralInput, setShowReferralInput] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  // Add new input handler
  const handleInputChange = (setter) => (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value;

    switch (name) {
      case 'firstName':
      case 'lastName':
        sanitizedValue = sanitizeInput.name(value);
        break;
      case 'email':
      case 'referralEmail':
        sanitizedValue = sanitizeInput.email(value);
        break;
      case 'age':
        sanitizedValue = sanitizeInput.number(value);
        break;
      default:
        sanitizedValue = sanitizeInput.text(value);
    }

    setFieldErrors(prev => ({
      ...prev,
      [name]: ''
    }));

    setter(sanitizedValue);
  };

  // Replace existing validation logic with enhanced version
  const validateForm = () => {
    const newErrors = {};

    if (firstName.length < 2) newErrors.firstName = 'First name must be at least 2 characters';
    if (lastName.length < 2) newErrors.lastName = 'Last name must be at least 2 characters';

    const ageNum = parseInt(age);
    if (isNaN(ageNum) || ageNum < 7 || ageNum > 120) {
      newErrors.age = 'Age must be between 7 and 120';
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) newErrors.email = 'Invalid email format';

    if (password.length < 6) newErrors.password = 'Password must be at least 6 characters';
    if (password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';

    if (!['male', 'female', 'other'].includes(gender.toLowerCase())) {
      newErrors.gender = 'Please select a gender';
    }

    if (showReferralInput && referralEmail && referralEmail.length > 0) {
      if (!emailRegex.test(referralEmail)) {
        newErrors.referralEmail = 'Invalid referral email format';
      }
      if (referralEmail.toLowerCase() === email.toLowerCase()) {
        newErrors.referralEmail = 'You cannot refer yourself';
      }
    }

    setFieldErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
  
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }
  
    try {
      // Create registration data object
      const registrationData = {
        first_name: sanitizeInput.name(firstName),
        last_name: sanitizeInput.name(lastName),
        age: parseInt(sanitizeInput.number(age)),
        gender: sanitizeInput.text(gender),
        email: sanitizeInput.email(email),
        password
      };
  
      // Only add referral_email if showReferralInput is true AND referralEmail has a value
      if (showReferralInput && referralEmail.trim()) {
        registrationData.referral_email = sanitizeInput.email(referralEmail);
      }
  
      const response = await axios.post(
        'https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/register', 
        registrationData
      );
  
      if (response.data.token) {
        onRegisterSuccess(response.data.token);
        navigate('/dashboard');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        // Handle validation errors from backend
        if (error.response.data.errors) {
          const backendErrors = {};
          error.response.data.errors.forEach(err => {
            // Map backend errors to form fields
            backendErrors[err.path] = err.msg;
          });
          setFieldErrors(backendErrors);
          setError('Please fix the highlighted errors');
        } else {
          setError(error.response.data.message || 'An error occurred during registration');
        }
      } else {
        console.log(error);
        setError('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Add utility function for input styling
  const getInputClassName = (fieldName) => `
    appearance-none block w-full px-3 py-2 border 
    ${fieldErrors[fieldName] ? 'border-red-300' : 'border-gray-300'} 
    rounded-md shadow-sm placeholder-gray-400 
    focus:outline-none focus:ring-blue-500 focus:border-blue-500 
    sm:text-sm
  `;

  return (
    <div className="flex min-h-screen bg-white text-gray-800 font-mono">
      <div className="flex-1 flex flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 overflow-y-auto  py-10">
        <div className="w-full max-w-md mx-auto lg:max-w-lg">
          <div className="text-left mb-2">
            <Link to="/" className="text-3xl font-extrabold text-gray-900 block text-center hover:text-blue-600 transition-colors">
              <span className="text-blue-600">CodeForCambodia</span>
            </Link>
          </div>

          <div className="bg-gray-50 p-8 rounded-lg border border-gray-200 shadow-sm">
            <div className="mb-6 text-sm text-gray-500">
              <span className="text-orange-500">function</span> <span className="text-blue-600">register</span>() {'{'}
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                    <span className="text-orange-500">let</span> firstName <span className="text-orange-500">=</span>
                  </label>
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    required
                    value={firstName}
                    onChange={handleInputChange(setFirstName)}
                    className={getInputClassName('firstName')}
                  />
                  {fieldErrors.firstName && (
                    <div className="text-red-600 text-sm">{fieldErrors.firstName}</div>
                  )}
                </div>

                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                    <span className="text-orange-500">let</span> lastName <span className="text-orange-500">=</span>
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    required
                    value={lastName}
                    onChange={handleInputChange(setLastName)}
                    className={getInputClassName('lastName')}
                  />
                  {fieldErrors.lastName && (
                    <div className="text-red-600 text-sm">{fieldErrors.lastName}</div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label htmlFor="age" className="block text-sm font-medium text-gray-700 mb-1">
                    <span className="text-orange-500">let</span> age <span className="text-orange-500">=</span>
                  </label>
                  <input
                    id="age"
                    name="age"
                    type="number"
                    required
                    value={age}
                    onChange={handleInputChange(setAge)}
                    className={getInputClassName('age')}
                  />
                  {fieldErrors.age && (
                    <div className="text-red-600 text-sm">{fieldErrors.age}</div>
                  )}
                </div>

                <div>
                  <label htmlFor="gender" className="block text-sm font-medium text-gray-700 mb-1">
                    <span className="text-orange-500">let</span> gender <span className="text-orange-500">=</span>
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    required
                    value={gender}
                    onChange={handleInputChange(setGender)}
                    className={getInputClassName('gender')}
                  >
                    <option value="">Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  {fieldErrors.gender && (
                    <div className="text-red-600 text-sm">{fieldErrors.gender}</div>
                  )}
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  <span className="text-orange-500">let</span> email <span className="text-orange-500">=</span>
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={handleInputChange(setEmail)}
                  className={getInputClassName('email')}
                />
                {fieldErrors.email && (
                  <div className="text-red-600 text-sm">{fieldErrors.email}</div>
                )}
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                  <span className="text-orange-500">let</span> password <span className="text-orange-500">=</span>
                </label>
                <div className="relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={getInputClassName('password')}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    {showPassword ? <EyeOff className="h-5 w-5 text-gray-400" /> : <Eye className="h-5 w-5 text-gray-400" />}
                  </button>
                </div>
                {fieldErrors.password && (
                  <div className="text-red-600 text-sm">{fieldErrors.password}</div>
                )}
              </div>

              <div>
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-1">
                  <span className="text-orange-500">let</span> confirmPassword <span className="text-orange-500">=</span>
                </label>
                <div className="relative">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    autoComplete="new-password"
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className={getInputClassName('confirmPassword')}
                  />
                  <button
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    {showConfirmPassword ? <EyeOff className="h-5 w-5 text-gray-400" /> : <Eye className="h-5 w-5 text-gray-400" />}
                  </button>
                </div>
                {fieldErrors.confirmPassword && (
                  <div className="text-red-600 text-sm">{fieldErrors.confirmPassword}</div>
                )}
              </div>

              <div className="space-y-2">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-200"></div>
                  </div>
                  <div className="relative flex justify-center">
                    <button
                      type="button"
                      onClick={() => setShowReferralInput(!showReferralInput)}
                      className="inline-flex items-center gap-x-2 bg-gray-50 px-4 py-2 text-sm text-gray-600 hover:text-blue-600 transition-colors duration-200 group"
                    >
                      <span className="text-orange-500">{'//'}</span>
                      <span className="Nokora font-medium">
                        {showReferralInput ? 'បិទ' : 'តើអ្នកត្រូវបានណែនាំដោយមិត្តភក្តិឬ?'}
                      </span>
                      <div className={`transform transition-transform duration-200 ${showReferralInput ? 'rotate-180' : ''}`}>
                        <ChevronDown className="h-4 w-4" />
                      </div>
                    </button>
                  </div>
                </div>

                {showReferralInput && (
                  <div className="bg-gradient-to-r from-blue-50 to-indigo-50 p-4 rounded-md border border-blue-100">
                    <div className="space-y-3">
                      <div className="flex items-center space-x-2">
                        <Gift className="h-4 w-4 text-blue-500" />
                        <span className="text-sm text-blue-600 font-medium">Referral Program</span>
                      </div>
                      
                      <div>
                        <label htmlFor="referralEmail" className="block text-sm font-medium text-gray-700 mb-1">
                          <span className="text-orange-500">const</span> <span className="text-blue-600 Nokora">នែណាំដោយ</span> <span className="text-orange-500">=</span>
                        </label>
                        <div className="relative">
                          <input
                            id="referralEmail"
                            name="referralEmail"
                            type="email"
                            value={referralEmail}
                            onChange={handleInputChange(setReferralEmail)}
                            className={getInputClassName('referralEmail')}
                            placeholder="សុំវាយ Email មិត្តភក្តិ"
                          />
                          <Mail className="h-4 w-4 text-gray-400 absolute left-2.5 top-2.5" />
                        </div>
                        {fieldErrors.referralEmail && (
                          <div className="text-red-600 text-sm">{fieldErrors.referralEmail}</div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {error && (
                <div className="text-red-600 text-sm">
                  <span className="text-orange-500">console</span>.error("{error}");
                </div>
              )}

              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-yellow-300 disabled:opacity-50"
                >
                  {isLoading ? 'Processing...' : 'createAccount()'};
                </button>
              </div>
            </form>
            <div className="mt-6 text-sm text-gray-500">{'}'}</div>
          </div>

          <div className="mt-8 text-center">
            <Link to="/login" className="text-blue-600 hover:text-blue-500">
              <span className="text-orange-500 Nokora">មានគណនីហើយ?</span><br></br><span className="text-blue-600">login</span>() {'{'}...{'}'}
            </Link>
          </div>
        </div>
      </div>
      <SidePanel/>
    </div>
  );
};

export default Register;