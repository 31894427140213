import React from 'react';
import { Link } from 'react-router-dom';
import { Search } from 'lucide-react';

const Advertisement = () => {
  return (
    <div className="container mx-auto px-4 py-12 font-mono">
      <Link 
        to="/search"
        className="block relative overflow-hidden rounded-2xl bg-white border border-blue-200 hover:border-blue-300 transition-all duration-300 shadow-sm hover:shadow-md transform hover:-translate-y-1"
      >
        <div className="relative z-10 p-8">
          <div className="flex items-center justify-between">
            <div className="flex-1">
              <h2 className="text-3xl font-bold mb-4 text-blue-800 Nokora">
                ស្រាវជ្រាវគ្រប់ឯកសារកូដដោយងាយៗ
              </h2>
              <p className="text-lg text-gray-600 mb-6 Nokora">
                ស្រាវជ្រាវឯកសារកូដជាមួយ search engine ដោយចូលប្រើ និងស្វែងរកមេរៀន និងឯកសារភ្លាមៗតាមអ្វីអ្នកស្រាវជ្រាវ។
              </p>
              <div className="inline-flex items-center space-x-2 text-blue-600 font-semibold">
                <code className="text-blue-400">{'<'}</code>
                <span className="Nokora">ស្រាវជ្រាវ</span>
                <code className="text-blue-400">{'/>'}</code>
                <Search className="w-5 h-5 animate-pulse" />
              </div>
            </div>
            
            <div className="hidden md:block">
              <div className="w-32 h-32 rounded-full bg-blue-50 flex items-center justify-center border border-blue-100">
                <Search className="w-16 h-16 text-blue-500" />
              </div>
            </div>
          </div>
        </div>

        {/* Decorative elements */}
        <div className="absolute top-0 left-0 w-full h-full opacity-5">
          <div className="absolute top-0 right-0 w-40 h-40 bg-blue-200 rounded-full transform translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 left-0 w-32 h-32 bg-blue-200 rounded-full transform -translate-x-1/2 translate-y-1/2"></div>
        </div>
      </Link>
    </div>
  );
};

export default Advertisement;
