import React, { useState } from 'react';
import { MapPin, Phone, Mail, Send } from 'lucide-react';
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader';

export default function ContactPage() {
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [errors, setErrors] = useState({});

  const validateFormData = (name, email, message) => {
    const newErrors = {};
    
    if (name.length < 2) {
      newErrors.name = 'Name must be at least 2 characters long';
    }
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    
    if (message.length < 10) {
      newErrors.message = 'Message must be at least 10 characters long';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const name = formData.get('name');
    const email = formData.get('email');
    const message = formData.get('message');

    if (!validateFormData(name, email, message)) {
      setSubmissionStatus('error');
      return;
    }

    try {
      const response = await fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/user/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });
    
      if (response.ok) {
        const result = await response.json();
        console.log('Form submitted successfully:', result);
        setSubmissionStatus('success');
        event.target.reset(); // Clear the form
        setErrors({});
      } else {
        const errorData = await response.json();
        console.error('Form submission failed:', errorData.error);
        setSubmissionStatus('error');
      }
    } catch (error) {
      console.error('An error occurred while submitting the form:', error);
      setSubmissionStatus('error');
    }
  };

  return (
    <div className="font-mono">
      <CodeForCambodiaHeader />

      <div className="max-w-7xl mx-auto min-h-screen bg-white py-12 px-4 sm:px-6 lg:px-8 ">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">{'<ContactUs />'}</h1>
          <p className="text-xl text-gray-600">// Get in touch with CodeForCambodia. We're here to help!</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="border-2 border-gray-300 rounded-lg overflow-hidden">
            <div className="p-6">
              <h2 className="text-2xl font-bold mb-2">{'<SendMessage>'}</h2>
              <p className="text-gray-600 mb-4">// Fill out the form below and we'll get back to you as soon as possible.</p>
              {submissionStatus === 'success' && (
                <div className="mb-4 p-2 bg-green-100 text-green-700 rounded">
                  Message sent successfully!
                </div>
              )}
              {submissionStatus === 'error' && (
                <div className="mb-4 p-2 bg-red-100 text-red-700 rounded">
                  An error occurred. Please try again.
                </div>
              )}
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="space-y-2">
                  <label htmlFor="name" className="block text-sm font-semibold">name:</label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Your name"
                    required
                    className="w-full px-3 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  {errors.name && <p className="text-red-600 text-sm">{errors.name}</p>}
                </div>
                <div className="space-y-2">
                  <label htmlFor="email" className="block text-sm font-semibold">email:</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Your email"
                    required
                    className="w-full px-3 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  {errors.email && <p className="text-red-600 text-sm">{errors.email}</p>}
                </div>
                <div className="space-y-2">
                  <label htmlFor="message" className="block text-sm font-semibold">message:</label>
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Your message"
                    required
                    className="w-full px-3 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"
                  ></textarea>
                  {errors.message && <p className="text-red-600 text-sm">{errors.message}</p>}
                </div>
                <button
                  type="submit"
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md flex items-center justify-center"
                >
                  <Send className="mr-2 h-4 w-4" /> submit()
                </button>
              </form>
            </div>
          </div>

          <div className="border-2 border-gray-300 rounded-lg overflow-hidden">
            <div className="p-6">
              <h2 className="text-2xl font-bold mb-2">{'<ContactInfo>'}</h2>
              <p className="text-gray-600 mb-4">// You can also reach us using the following contact details.</p>
              <div className="space-y-4">
                <div className="flex items-center space-x-2 text-gray-700">
                  <Phone className="h-5 w-5" />
                  <span>phone = "+61 415 420 979"</span>
                </div>
                <div className="flex items-center space-x-2 text-gray-700">
                  <Mail className="h-5 w-5" />
                  <span>email = "codeforcambodia@gmail.com"</span>
                </div>
              </div>
            </div>
            <div className="px-6 py-4 bg-gray-50">
              <p className="text-sm text-gray-500">
                {`/* CodeForCambodia is dedicated to empowering Cambodian youth through technology education and innovation. */`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
