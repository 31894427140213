import { useState } from "react"
import { Smile, Image, Link, X } from "lucide-react"

export default function CreatePost({ onClose, onPostCreated }) {
  const [title, setTitle] = useState("")
  const [content, setContent] = useState("")
  const [tags, setTags] = useState([])
  const [newTag, setNewTag] = useState("")
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  // Common emojis for quick access
  const quickEmojis = ["🎉", "📚", "💡", "🚀", "✨", "💪", "🎯", "🌟"]

  const handleAddTag = (e) => {
    e.preventDefault()
    if (newTag.trim() && !tags.includes(newTag.trim())) {
      setTags([...tags, newTag.trim()])
      setNewTag("")
    }
  }

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove))
  }

  const handleAddEmoji = (emoji) => {
    setContent(content + emoji)
    setShowEmojiPicker(false)
  }

  const handleSubmit = async () => {
    if (!title.trim() || !content.trim()) {
      alert("Please fill in both title and content")
      return
    }

    try {
      // Create the post object
      const newPost = {
        title: title.trim(),
        content: content.trim(),
        tags,
      }

      // In a real application, you would make an API call here
      // const response = await fetch('/api/posts', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify(newPost),
      // })
      
      // For now, we'll just pass the new post to the parent component
      onPostCreated(newPost)

      // Clear form and close modal on success
      setTitle("")
      setContent("")
      setTags([])
      onClose()
    } catch (error) {
      console.error('Error creating post:', error)
      alert('Failed to create post. Please try again.')
    }
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="w-full max-w-3xl mx-auto bg-white rounded-lg relative">
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 p-2 hover:bg-gray-100 rounded-full"
        >
          <X className="h-5 w-5" />
        </button>

        <div className="p-6">
          <h2 className="text-xl font-semibold mb-4">Create a New Post</h2>
          
          <input
            type="text"
            placeholder="Post title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full mb-4 p-2 border rounded focus:outline-none focus:border-blue-500"
          />

          <textarea
            placeholder="Hey everyone! Share your thoughts..."
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="w-full min-h-[200px] text-lg mb-6 p-2 border rounded resize-none focus:outline-none focus:border-blue-500"
          />
          
          <div className="space-y-4">
            <div>
              <label className="text-sm text-gray-600">Add tags:</label>
              <div className="flex items-center gap-2 mt-2">
                <input
                  type="text"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleAddTag(e)}
                  placeholder="Enter tag"
                  className="px-2 py-1 border rounded"
                />
                <button
                  onClick={handleAddTag}
                  className="px-3 py-1 text-sm border rounded hover:bg-gray-50"
                >
                  Add Tag
                </button>
              </div>
              <div className="flex flex-wrap gap-2 mt-2">
                {tags.map((tag) => (
                  <span
                    key={tag}
                    className="px-2 py-1 bg-gray-100 rounded-full text-sm flex items-center gap-1"
                  >
                    {tag}
                    <button
                      onClick={() => handleRemoveTag(tag)}
                      className="ml-1 hover:text-red-500"
                    >
                      <X className="h-3 w-3" />
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center border-t p-4">
          <div className="flex gap-2">
            <button 
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <Smile className="h-5 w-5" />
            </button>

            <button className="p-2 hover:bg-gray-100 rounded-full">
              <Image className="h-5 w-5" />
            </button>
            
            <button className="p-2 hover:bg-gray-100 rounded-full">
              <Link className="h-5 w-5" />
            </button>
          </div>

          {showEmojiPicker && (
            <div className="absolute mt-2 bg-white border rounded-lg shadow-lg p-4">
              <div className="grid grid-cols-4 gap-2">
                {quickEmojis.map((emoji) => (
                  <button
                    key={emoji}
                    onClick={() => handleAddEmoji(emoji)}
                    className="text-xl p-2 hover:bg-gray-100 rounded"
                  >
                    {emoji}
                  </button>
                ))}
              </div>
            </div>
          )}

          <div className="flex gap-2">
            <button 
              onClick={onClose}
              className="px-4 py-2 border rounded hover:bg-gray-50"
            >
              Cancel
            </button>
            <button 
              onClick={handleSubmit}
              className="px-4 py-2 bg-[#ff7b72] hover:bg-[#ff7b72]/90 text-white rounded"
            >
              Post
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}