import { X } from "lucide-react"

export default function GeneralModal({ isOpen, onClose, title, children }) {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 backdrop-blur-sm">
      <div className="w-full max-w-lg mx-auto bg-white rounded-lg shadow-xl relative transform transition-all duration-300 ease-in-out scale-100">
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 p-2 hover:bg-gray-100 rounded-full transition-colors duration-200 text-gray-500 hover:text-gray-700"
        >
          <X className="h-5 w-5" />
        </button>

        <div className="p-8">
          {title && (
            <h2 className="text-2xl font-semibold mb-6 text-gray-800 border-b pb-4">
              {title}
            </h2>
          )}
          <div className="text-gray-600">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
