import React from 'react';
import { Link } from 'react-router-dom';

export default function LoginLink() {
  return (
    <div className="mt-6 text-center text-base lg:text-lg">
      <span className="text-orange-500 Nokora">មានគណនីហើយ?</span>{' '}
      <Link 
        to="/login" 
        className="font-mono text-blue-600 hover:text-blue-900 font-medium transition-colors duration-150"
      >
        Login
      </Link>
    </div>
  );
}