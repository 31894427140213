import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { SandpackProvider, SandpackLayout, SandpackCodeEditor, SandpackPreview } from "@codesandbox/sandpack-react";
import { Code, Play, FileText, Check, AlertCircle } from 'lucide-react';
import options from '../../Components/Lessons/OptionsQuill';
import parse from 'html-react-parser';
import Header from '../../Components/Headers/CodeForCambodiaHeader';
import Footer from '../../Components/Footer';
import SandpackContent from '../../Components/Lessons/React/SandpackContent';
import axios from 'axios';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

function Button({ children, onClick, className, icon: Icon }) {
  return (
    <button
      onClick={onClick}
      className={`flex items-center gap-2 px-4 ${className}`}
    >
      {Icon && <Icon className="w-4 h-4" />}
      <span className="hidden md:inline">{children}</span>
    </button>
  );
}

function ReactLesson({ lesson }) {
  const [activeSection, setActiveSection] = useState('instructions');
  const [code, setCode] = useState(lesson.starter_react);
  const [verifying, setVerifying] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { width, height } = useWindowSize();

  const tabs = useMemo(() => [
    { id: 'instructions', icon: FileText, label: 'Instructions' },
    { id: 'code', icon: Code, label: 'Code' },
    { id: 'preview', icon: Play, label: 'Preview' },
  ], []);

  const handleSectionChange = useCallback((sectionId) => {
    setActiveSection(sectionId);
  }, []);

  const verifyCode = async () => {
    try {
      setVerifying(true);
      setError('');
      setSuccess('');
      
      const response = await axios.post('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/react-verify/verify', {
        code,
        expectedOutput: lesson.sample_output
      });
      
      if (response.data.correct) {
        setShowConfetti(true);
        setSuccess(response.data.message);
        setTimeout(() => {
          setShowConfetti(false);
          setSuccess('');
        }, 5000);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Error verifying code');
    } finally {
      setVerifying(false);
    }
  };

  const commonOptions = useMemo(() => ({
    showNavigator: false,
    showTabs: false,
    showLineNumbers: true,
    showInlineErrors: true,
    visibleFiles: ["/App.js"],
    dependencies: {
      "react-router-dom": "^6.15.0",
      "@emotion/react": "^11.11.1",
      "@emotion/styled": "^11.11.0",
      "@mui/material": "^5.14.5",
      "@mui/icons-material": "^5.14.5"
    }
  }), []);

  const previewOptions = useMemo(() => ({
    ...commonOptions,
    showLineNumbers: false,
    showInlineErrors: false,
  }), [commonOptions]);

  const commonFiles = useMemo(() => ({
    "/styles.css": `
      body { margin: 0; padding: 0; }
      #root { height: 100vh; }
    `
  }), []);

  return (
    <div className="min-h-screen flex flex-col bg-white text-gray-800 font-sans">
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          recycle={false}
          numberOfPieces={500}
          gravity={0.3}
        />
      )}
      <Header/>
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="bg-gray-100 p-2 flex items-center space-x-2 border-b border-gray-200">
          <div className="flex space-x-1">
            <div className="w-3 h-3 rounded-full bg-red-500"></div>
            <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
            <div className="w-3 h-3 rounded-full bg-green-500"></div>
          </div>
          <h1 className="text-sm flex-grow text-center font-semibold Nokora">
            {lesson.lesson_name}
          </h1>
          <button
            onClick={verifyCode}
            disabled={verifying}
            className={`flex items-center gap-2 px-4 py-1.5 rounded-md font-mono text-gray-700 border border-gray-300 transition-all duration-150 hover:bg-gray-50 ${
              verifying ? 'opacity-75 cursor-not-allowed' : ''
            }`}
          >
            <Check className="w-4 h-4" />
            <span>{verifying ? 'Verifying...' : 'Verify Code'}</span>
          </button>
        </header>

        <div className="flex-1 flex overflow-hidden">
          <nav className="bg-gray-100 w-12 md:w-48 flex-shrink-0 border-r border-gray-200">
            <div className="flex-1">
              {tabs.map(({ id, icon: Icon, label }) => (
                <Button
                  key={id}
                  onClick={() => handleSectionChange(id)}
                  className={`py-3 w-full ${
                    activeSection === id
                      ? 'bg-blue-100 text-blue-600'
                      : 'text-gray-600 hover:bg-gray-200'
                  }`}
                  icon={Icon}
                >
                  {label}
                </Button>
              ))}
            </div>
          </nav>

          <main className="flex-1 overflow-hidden relative">
            {/* Instructions Section */}
            <div className={`absolute inset-0 transition-opacity duration-150 ${
              activeSection === 'instructions' ? 'opacity-100 z-10' : 'opacity-0 -z-10'
            }`}>
              <div className="h-full w-full bg-white overflow-y-auto pb-4">
                <div className="max-w-none p-4 prose">
                  {parse(lesson.instructions, options)}
                </div>
              </div>
            </div>

            {/* Code Editor Section */}
            <div className={`absolute inset-0 transition-opacity duration-150 ${
              activeSection === 'code' ? 'opacity-100 z-10' : 'opacity-0 -z-10'
            }`}>
              <div className="flex flex-col h-full">
                <SandpackContent 
                  code={code} 
                  setCode={setCode} 
                  options={commonOptions}
                />
                {error && (
                  <div className="bg-red-50 border-t border-red-200 p-4 flex items-start gap-3">
                    <AlertCircle className="w-5 h-5 text-red-500 flex-shrink-0 mt-0.5" />
                    <div className="text-red-700 text-sm whitespace-pre-wrap">{error}</div>
                  </div>
                )}
                {success && (
                  <div className="bg-green-50 border-t border-green-200 p-4 flex items-start gap-3">
                    <Check className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
                    <div className="text-green-700 text-sm">{success}</div>
                  </div>
                )}
              </div>
            </div>

            {/* Preview Section */}
            <div className={`absolute inset-0 transition-opacity duration-150 ${
              activeSection === 'preview' ? 'opacity-100 z-10' : 'opacity-0 -z-10'
            }`}>
              <SandpackProvider 
                template="react"
                files={{
                  ...commonFiles,
                  "/App.js": lesson.sample_output
                }}
                theme="light"
                options={previewOptions}
              >
                <div className="h-full w-full overflow-hidden">
                  <SandpackLayout>
                    <SandpackPreview showNavigator={false} />
                  </SandpackLayout>
                </div>
              </SandpackProvider>
            </div>
          </main>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default ReactLesson;