// Then, replace the current StepTwo with this version:
import React, { useState } from 'react';
import InputField from './InputField';
import { ChevronDown, Gift, Mail } from 'lucide-react';

const StepTwo = ({ formData, handleChange, fieldErrors }) => {
  const [showReferralInput, setShowReferralInput] = useState(false);

  const getInputClassName = (fieldName) => `
    w-full px-3 py-2 border 
    ${fieldErrors?.[fieldName] ? 'border-red-300' : 'border-gray-300'} 
    rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500
  `;

  return (
    <div className="space-y-4 text-gray-800">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <InputField
            label="First Name"
            name="first_name"
            type="text"
            value={formData.first_name}
            onChange={handleChange}
            error={fieldErrors?.first_name}
            className={getInputClassName('first_name')}
          />
        </div>
        <div>
          <InputField
            label="Last Name"
            name="last_name"
            type="text"
            value={formData.last_name}
            onChange={handleChange}
            error={fieldErrors?.last_name}
            className={getInputClassName('last_name')}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <InputField
            label="Age"
            name="age"
            type="number"
            value={formData.age}
            onChange={handleChange}
            error={fieldErrors?.age}
            className={getInputClassName('age')}
          />
        </div>
        <div>
          <label htmlFor="gender" className="block text-sm font-medium text-gray-700 mb-1">
            <span className="text-orange-500">let</span> gender <span className="text-orange-500">=</span>
          </label>
          <select
            id="gender"
            name="gender"
            required
            value={formData.gender}
            onChange={handleChange}
            className={getInputClassName('gender')}
          >
            <option value="">Select</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
          {fieldErrors?.gender && (
            <p className="mt-1 text-xs text-red-600">{fieldErrors.gender}</p>
          )}
        </div>
      </div>

      <div className="space-y-2 pt-2">
        <div className="relative">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-200"></div>
          </div>
          <div className="relative flex justify-center">
            <button
              type="button"
              onClick={() => setShowReferralInput(!showReferralInput)}
              className="inline-flex items-center gap-x-2 bg-gray-50 px-4 py-2 text-sm text-gray-600 hover:text-blue-600 transition-colors duration-200 group"
            >
              <span className="text-orange-500">{'//'}</span>
              <span className="Nokora font-medium">
                {showReferralInput ? 'បិទ' : 'តើអ្នកត្រូវបានណែនាំដោយមិត្តភក្តិឬ?'}
              </span>
              <div className={`transform transition-transform duration-200 ${showReferralInput ? 'rotate-180' : ''}`}>
                <ChevronDown className="h-4 w-4" />
              </div>
            </button>
          </div>
        </div>

        <div className="relative">
          <div 
            className={`transition-all duration-300 ease-in-out ${
              showReferralInput 
                ? 'opacity-100 visible max-h-48' 
                : 'opacity-0 invisible max-h-0'
            }`}
          >
            <div className="bg-gradient-to-r from-blue-50 to-indigo-50 p-4 rounded-md border border-blue-100 mt-3">
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <Gift className="h-4 w-4 text-blue-500" />
                  <span className="text-sm text-blue-600 font-medium">Referral Program</span>
                </div>
                
                <div>
                  <label htmlFor="referral_email" className="block text-sm font-medium text-gray-700 mb-1">
                    <span className="text-orange-500">const</span> <span className="text-blue-600 Nokora">នែណាំដោយ</span> <span className="text-orange-500">=</span>
                  </label>
                  <div className="relative">
                    <input
                      id="referral_email"
                      name="referral_email"
                      type="email"
                      value={formData.referral_email}
                      onChange={handleChange}
                      className={`${getInputClassName('referral_email')} Nokora pl-8`}
                      placeholder="សុំវាយ Email មិត្តភក្តិ (optional)"
                    />
                    <Mail className="h-4 w-4 text-gray-400 absolute left-2.5 top-2.5" />
                  </div>
                  {fieldErrors?.referral_email && (
                    <p className="mt-1 text-xs text-red-600">{fieldErrors.referral_email}</p>
                  )}
                  <p className="mt-2 text-xs text-gray-600 flex items-center space-x-1 Nokora">
                    <span>💡</span>
                    <span>
                      វាយ email មិត្តភក្តិ គាត់នឹងទទួលបាន <span className="font-medium text-blue-600">free ថ្នាក់ Python</span> ហើយបើមិត្តអ្នកវាយ email របស់អ្នក អ្នកនឹងបានថ្នាក់ free ដែល!
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
