import React from 'react'
import { Code, Github, Twitter, Linkedin } from 'lucide-react'
import { Link } from 'react-router-dom'

const Footer = ({ backgroundColor = 'white' }) => {
  const year = new Date().getFullYear()

  return (
    <footer className="bg-gray-100 border-t border-gray-300 py-4 sm:py-6 font-mono">
      <div className="container mx-auto px-4">
        <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
          <p className="text-xs sm:text-sm text-gray-600 text-center sm:text-left">
            &copy; {year} CodeForCambodia. All rights reserved.
          </p>
          <nav className="w-full sm:w-auto">
            <ul className="flex flex-wrap justify-center sm:justify-end space-x-2 sm:space-x-4">
              <li><a href="https://t.me/uddompang" target="_blank" rel="noopener noreferrer" className="text-xs sm:text-sm text-gray-600 hover:text-gray-800 py-1 px-2">Telegram for Media</a></li>
              <li><Link to="/donation" className="text-xs sm:text-sm text-gray-600 hover:text-gray-800 py-1 px-2">Donate</Link></li>
              <li><Link to="/about" className="text-xs sm:text-sm text-gray-600 hover:text-gray-800 py-1 px-2">About</Link></li>
              <li><Link to="/contact" className="text-xs sm:text-sm text-gray-600 hover:text-gray-800 py-1 px-2">Contact</Link></li>
              <li><Link to="/privacy-policy" className="text-xs sm:text-sm text-gray-600 hover:text-gray-800 py-1 px-2">Privacy Policy</Link></li>
            </ul>
          </nav>
        </div>
        <div className="mt-4 text-center flex flex-col items-center">
          <code className=" mb-2 text-xs text-gray-500">{'console.log("Happy coding!");'}</code>
          <Link to="/donation" className="text-sm text-blue-600 font-semibold">
            Your donation powers the future of Cambodian tech talent!
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer