import React, { useState } from 'react'
import { Trophy, X, Facebook, Code, Clock, Target, Copy, Check, Zap, Share2 } from 'lucide-react'

export default function MilestoneModal({ showMilestoneModal, setShowMilestoneModal, milestoneLabel }) {
  const [isCopied, setIsCopied] = useState(false)

  if (!showMilestoneModal) return null

  const shareMessage = `អស្ចារ្យណាស់! ខ្ញុំបានសម្រេចគោលដៅថ្មីដោយរៀនបានរយៈពេល ${milestoneLabel} ជាមួយ CodeForCambodia! សូមអបអរសាទរខ្ញុំផង! 🎉🇰🇭 #CodeForCambodia #ក្តីស្រមៃកូដកម្ពុជា`
  const url = 'https://codeforcambodia.com'

  const shareOnFacebook = () => {
    const encodedUrl = encodeURIComponent(url)
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&hashtag=%23CodeForCambodia`

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      window.open(shareUrl, '_blank')
    } else {
      window.open(shareUrl, '_blank', 'width=600,height=400,resizable=yes,scrollbars=yes')
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareMessage)
      .then(() => {
        setIsCopied(true)
        setTimeout(() => setIsCopied(false), 2000)
      })
      .catch(err => console.error('Failed to copy text: ', err))
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50" onClick={() => setShowMilestoneModal(false)}>
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full Nokora">
        <div className="p-6 border-4 border-blue-500 rounded-lg bg-gradient-to-br from-blue-50 to-white">
          <button
            onClick={() => setShowMilestoneModal(false)}
            className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 transition-colors duration-200"
            aria-label="បិទ"
          >
            <X className="h-6 w-6" />
          </button>

          <div className="text-center">
            <div className="flex justify-center mb-4">
              <div className="relative">
                <div className="absolute inset-0 bg-blue-500 rounded-full animate-ping opacity-75"></div>
                <Trophy size={64} className="text-yellow-400 relative z-10" />
              </div>
            </div>
            <h2 className="text-3xl font-bold text-blue-600 mb-2" style={{fontFamily: "'Moul', cursive"}}>
              NEW HOURS REACHED!
            </h2>
            <p className="text-xl text-gray-700 mb-6">
              អ្នកបានរៀន <span className="font-semibold text-blue-600">{milestoneLabel}</span>
            </p>
          </div>

          <div className="grid grid-cols-3 gap-4 mb-6 text-center">
            <div className="bg-blue-100 p-3 rounded-lg flex flex-col items-center">
              <Code className="w-8 h-8 text-blue-600 mb-2" />
              <p className="text-blue-700 font-semibold text-sm">Code Mastery</p>
            </div>
            <div className="bg-green-100 p-3 rounded-lg flex flex-col items-center">
              <Clock className="w-8 h-8 text-green-600 mb-2" />
              <p className="text-green-700 font-semibold text-sm">Time Invested</p>
            </div>
            <div className="bg-purple-100 p-3 rounded-lg flex flex-col items-center">
              <Target className="w-8 h-8 text-purple-600 mb-2" />
              <p className="text-purple-700 font-semibold text-sm">Goal Achieved</p>
            </div>
          </div>

          <div className="bg-white p-4 rounded-lg shadow-inner border border-gray-200 mb-6">
            <p className="text-gray-700 text-sm">{shareMessage}</p>
          </div>

          <div className="space-y-4">
            <button 
              onClick={copyToClipboard} 
              className="w-full py-2 px-4 border border-blue-500 rounded-md text-blue-500 hover:bg-blue-50 transition-colors duration-200 flex items-center justify-center"
            >
              {isCopied ? (
                <>
                  <Check className="mr-2 h-4 w-4" />
                  Copied!
                </>
              ) : (
                <>
                  <Copy className="mr-2 h-4 w-4" />
                  Copy Achievement
                </>
              )}
            </button>

            <button 
              onClick={() => setShowMilestoneModal(false)}
              className="w-full py-2 px-4 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-200 flex items-center justify-center"
            >
              <Zap className="mr-2 h-5 w-5" />
              បន្តការសរសេរកូដ
            </button>

            <div className="flex items-center justify-center space-x-4">
              <span className="text-gray-600 text-sm">ចែករំលែក៖</span>
              <button
                onClick={shareOnFacebook}
                className="py-1 px-3 border border-blue-500 rounded-md text-blue-600 hover:bg-blue-50 transition-colors duration-200 text-sm flex items-center"
              >
                <Facebook className="h-4 w-4 mr-2" />
                Facebook
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}