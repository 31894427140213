"use client"

import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Clock, ChevronRight, ChevronDown, ChevronUp, HardDrive } from 'lucide-react'

export default function LearningPathCard({ learningPath, onClick }) {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleDescription = (e) => {
    e.stopPropagation()
    setIsExpanded(!isExpanded)
  }



  return (
    <motion.div
      className="w-full max-w-sm"
      transition={{ type: "spring", stiffness: 400, damping: 10 }}
    >
      <div 
        className="bg-white border border-gray-200 rounded-lg  overflow-hidden cursor-pointer group relative"
        onClick={onClick}
      >
        <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-indigo-50 transform -skew-y-6 origin-top-right transition-transform duration-300 ease-in-out group-hover:skew-y-0" />
        <div className="relative z-10 p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <img 
                src={learningPath.icon} 
                alt="" 
                className="w-10 h-10 object-contain mr-3"
              />
              <span className="text-blue-600 text-xs font-semibold bg-blue-100 rounded-full px-3 py-1 Nokora">ជំនាញ</span>
            </div>
            <span className="text-gray-600 text-sm font-medium">
              {learningPath.level}
            </span>
          </div>
          <h3 className="text-xl font-bold text-gray-800 mb-2 font-mono">
            {learningPath.learning_path_name}
          </h3>
          <div className="relative mb-4">
            <p className={`text-gray-600 text-sm Nokora ${isExpanded ? '' : 'line-clamp-2'}`}>
              {learningPath.description}
            </p>
            <button
              onClick={toggleDescription}
              className="absolute bottom-0 right-0 bg-white text-blue-600 p-1 rounded-full shadow-sm"
            >
              {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
            </button>
          </div>

          <motion.div
            className="flex items-center text-blue-600 font-medium Nokora"
            whileHover={{ x: 5 }}
            onClick={onClick}
          >
            រៀនជំនាញ
            <ChevronRight className="w-4 h-4 ml-1" />
          </motion.div>
        </div>
      </div>
    </motion.div>
  )
}