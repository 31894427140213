import React from 'react'
import { Code, Users, Lightbulb, GraduationCap } from 'lucide-react'
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader'

export default function AboutPage() {
  return (
    <div className=" font-mono">
      <CodeForCambodiaHeader />
      <div className="max-w-7xl mx-auto min-h-screen bg-white py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">{'<AboutCodeForCambodia />'}</h1>
          <p className="text-xl text-gray-600">// Empowering Cambodian youth through code</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <div className="border-2 border-gray-300 rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Code className="mr-2" />
              {'<OurVision>'}
            </h2>
            <p className="text-gray-700 mb-4">
              // A Cambodia where every youth has the opportunity to learn coding,
              // fostering innovation and driving technological advancement in the nation.
            </p>
            <p className="text-gray-700">
              {`console.log("Empowering through code");`}
            </p>
          </div>

          <div className="border-2 border-gray-300 rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Lightbulb className="mr-2" />
              {'<OurMission>'}
            </h2>
            <p className="text-gray-700 mb-4">
              // To provide accessible, high-quality coding education to Cambodian youth,
              // equipping them with the skills to shape the future of technology in Cambodia and beyond.
            </p>
            <p className="text-gray-700">
              {`function createFutureTechLeaders() {`}
              <br />
              {`  educate();`}
              <br />
              {`  inspire();`}
              <br />
              {`  empower();`}
              <br />
              {`}`}
            </p>
          </div>
        </div>

        <div className="mb-12">
          <h2 className="text-3xl font-bold text-center mb-6">{'<WhoWeAre>'}</h2>
          <div className="bg-gray-100 rounded-lg p-6">
            <p className="text-gray-700 mb-4">
              {`/* CodeForCambodia is a non-profit organization dedicated to bringing coding education to every corner of Cambodia. We believe that in the digital age, coding is not just a skill—it's a superpower. Our team of passionate educators and tech enthusiasts work tirelessly to create curricula, workshops, and online resources that make learning to code accessible, engaging, and fun for Cambodian youth. */`}
            </p>
            <p className="text-gray-700">
              {`const ourBeliefs = ['Anyone can code', 'Technology is for everyone', 'Learning should be fun'];`}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          <div className="border-2 border-gray-300 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-4 flex items-center">
              <Users className="mr-2" />
              {'<Community>'}
            </h3>
            <p className="text-gray-700">
              // We foster a supportive community of learners, mentors, and industry professionals, creating a network that extends beyond the classroom.
            </p>
          </div>

          <div className="border-2 border-gray-300 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-4 flex items-center">
              <GraduationCap className="mr-2" />
              {'<Education>'}
            </h3>
            <p className="text-gray-700">
              // Our curriculum is designed to be comprehensive yet accessible, covering everything from basic programming concepts to advanced web and mobile development.
            </p>
          </div>

          <div className="border-2 border-gray-300 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-4 flex items-center">
              <Lightbulb className="mr-2" />
              {'<Innovation>'}
            </h3>
            <p className="text-gray-700">
              // We encourage creative problem-solving and innovative thinking, preparing our students to tackle real-world challenges with technology.
            </p>
          </div>
        </div>

        <div className="text-center">
          <h2 className="text-3xl font-bold mb-6">{'<JoinUs>'}</h2>
          <p className="text-gray-700 mb-4">
            // Whether you're a student eager to learn, a professional looking to mentor, or an organization wanting to support our mission, there's a place for you in the CodeForCambodia family.
          </p>
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            {'getInvolved()'}
          </button>
        </div>
      </div>
    </div>
  )
}