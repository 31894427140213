import React from 'react';

import Profile from '../Components/Dashboard/Profile';
import CodeForCambodiaHeader from '../Components/Headers/CodeForCambodiaHeader';
import Footer from '../Components/Footer';

const ProfilePage = () => {
  return (
    <>
        <CodeForCambodiaHeader/>
            <Profile/>
        <Footer/>
    </>
  );
};

export default ProfilePage;