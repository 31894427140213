import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { ChevronLeft, ChevronRight, PieChart, DollarSign } from 'lucide-react'

const FundraiserCard = ({ card, openModal }) => (
  <motion.div
    className="bg-white rounded-lg overflow-hidden border border-gray-200 h-full flex flex-col"
    whileHover={{ y: -5, transition: { duration: 0.2 } }}
  >
    <div className="p-6 flex-grow flex flex-col">
      <h3 className="text-xl font-bold text-gray-800 mb-2 Nokora">{card.title}</h3>
      <p className="text-gray-600 mb-4 Nokora flex-grow">{card.description}</p>
      
      <div className="mb-6">
        <h4 className="text-lg font-semibold text-gray-700 mb-2 Nokora">ការបែងចែកមូលនិធិ</h4>
        <div className="space-y-2">
          {card.allocations.map((allocation, index) => (
            <div key={index} className="flex items-center">
              <div className={`w-3 h-3 rounded-full mr-2 ${allocation.color}`}></div>
              <span className="text-sm text-gray-600 flex-grow Nokora">{allocation.category}</span>
              <span className="text-sm font-semibold">{allocation.percentage}%</span>
            </div>
          ))}
        </div>
      </div>
      
      
      <motion.button
        className="w-full bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-4 rounded-full text-sm transition duration-300 ease-in-out flex items-center justify-center Nokora"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => openModal()}
      >
        <PieChart className="w-4 h-4 mr-2" />
        បរិច្ចាគឥឡូវនេះ
      </motion.button>
    </div>
  </motion.div>
)

export default function FundraisingCards({ openModal }) {
  const [displayAll, setDisplayAll] = useState(false)
  const fundraisers = [
    {
      title: "ផ្តល់ឱកាសដល់យុវជនកម្ពុជាតាមរយៈកូដ",
      description: "ជួយយើងប្រមូលថវិកា ដើម្បីផ្តល់ការអប់រំកូដដោយឥតគិតថ្លៃ និងបង្កើតឱកាសសម្រាប់យុវជនកម្ពុជា។",
      allocations: [
        { category: "កែលម្អហេដ្ឋារចនា website", percentage: 33, color: "bg-blue-500" },
        { category: "សេវាកម្ម Server Hosting", percentage: 12, color: "bg-yellow-500" },
        { category: "ការអភិវឌ្ឍវគ្គសិក្សា", percentage: 25, color: "bg-indigo-500" },
        { category: "សម្ភារៈបង្រៀន", percentage: 15, color: "bg-green-500" },
        { category: "ការគាំទ្រសិស្ស", percentage: 15, color: "bg-purple-500" }
      ]
    },
    {
      title: "ពង្រីកការឈានដល់របស់ CodeForCambodia",
      description: "គាំទ្របេសកកម្មរបស់យើងក្នុងការនាំយកការអប់រំកូដទៅកាន់ខេត្តនានាទូទាំងប្រទេសកម្ពុជា។",
      allocations: [
        { category: "ការបង្កើតមាតិកា", percentage: 13, color: "bg-red-500" },
        { category: "ការផ្សព្វផ្សាយ និងការទំនាក់ទំនង", percentage: 17, color: "bg-green-500" },
        { category: "Marketing", percentage: 35, color: "bg-yellow-500" },
        { category: "ការចំណាយលើការធ្វើដំណើរ", percentage: 20, color: "bg-blue-500" },
        { category: "ឧបករណ៍បណ្តុះបណ្តាល", percentage: 15, color: "bg-purple-500" }
      ]
    },
    {
      title: "បន្ថែមមេរៀន",
      description: "ជួយយើងបង្កើតវគ្គសិក្សាកម្រិតខ្ពស់ដើម្បីជួយសិស្សកម្ពុជាឱ្យឈានដល់ភាពជោគជ័យក្នុងឧស្សាហកម្មបច្ចេកវិទ្យា។",
      allocations: [
        { category: "ការបង្កើតមាតិកា", percentage: 25, color: "bg-yellow-500" },
        { category: "ការអភិវឌ្ឍវគ្គសិក្សា", percentage: 20, color: "bg-indigo-500" },
        { category: "ប្រាក់បៀវត្សរបស់បុគ្គលិក", percentage: 25, color: "bg-purple-500" },
        { category: "ឧបករណ៍បច្ចេកវិទ្យា", percentage: 15, color: "bg-blue-500" },
        { category: "ការស្រាវជ្រាវ និងការអភិវឌ្ឍន៍", percentage: 15, color: "bg-green-500" }
      ]
    }
  ]

  return (
    <div className={`py-12 px-4 sm:px-6 lg:px-8 ${displayAll ? 'fixed inset-0 overflow-y-auto bg-white z-50' : ''}`}>
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-center items-center mb-8">
          <h2 className="text-3xl font-bold text-center text-gray-900 Nokora">តើ <span className="text-blue-600">CodeForCambodia</span> នឹងធ្វើអ្វីជាមួយ <span className="text-green-600">ថវិការ</span>?</h2>
        </div>
        <div className={`grid gap-8 ${displayAll ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3'}`}>
          {fundraisers.map((card, index) => (
            <FundraiserCard key={index} card={card} openModal={openModal} />
          ))}
        </div>
      </div>
    </div>
  )
}
