import React, { useState } from 'react';

const Comment = ({ comment, depth = 0, onAddReply }) => {
  const [replyingTo, setReplyingTo] = useState(null);
  const [newReply, setNewReply] = useState('');

  const handleSubmitReply = (e) => {
    e.preventDefault();
    if (!newReply.trim()) return;
    
    onAddReply(comment.id, newReply.trim());
    setNewReply('');
    setReplyingTo(null);
  };

  return (
    <div className={`${depth > 0 ? 'border-l-2 border-[#edeff1] pl-4 ml-3' : ''}`}>
      <div className="py-2">
        <div className="flex items-start gap-2">
          <div className="flex flex-col items-center w-[26px] pt-2">
            <button className="text-[#878a8c] hover:text-[#cc3700] p-0">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
                <path d="M8 2.5 4 6.5h8l-4-4z" />
              </svg>
            </button>
            <span className="text-xs font-bold text-[#1a1a1b] my-1">{comment.votes}</span>
            <button className="text-[#878a8c] hover:text-[#7193ff] p-0">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
                <path d="M8 13.5 4 9.5h8l-4 4z" />
              </svg>
            </button>
          </div>
          <div className="flex-1 pt-1">
            <div className="flex items-center gap-1 text-xs leading-4 mb-1">
              <a href="#" className="font-medium text-[#1a1a1b] hover:underline">{comment.author}</a>
              <span className="text-[#787c7e]">{comment.timestamp}</span>
            </div>
            <div className="text-[14px] leading-[21px] text-[#1a1a1b] mb-2">
              {comment.content}
            </div>
            <div className="flex items-center gap-2 text-xs text-[#878a8c]">
              <button onClick={() => setReplyingTo(comment.id)} className="flex items-center gap-1 p-1 hover:bg-[#1a1a1b]/[0.05] rounded">
                <svg className="w-4 h-4" viewBox="0 0 16 16" fill="currentColor">
                  <path d="M9.5 3l-5 5 5 5V3z" />
                  <path d="M12.5 3v10h-2V3h2z" />
                </svg>
                Reply
              </button>
              <button className="flex items-center gap-1 p-1 hover:bg-[#1a1a1b]/[0.05] rounded">
                Share
              </button>
              <button className="flex items-center gap-1 p-1 hover:bg-[#1a1a1b]/[0.05] rounded">
                Report
              </button>
              <button className="flex items-center gap-1 p-1 hover:bg-[#1a1a1b]/[0.05] rounded">
                Save
              </button>
              <button className="flex items-center gap-1 p-1 hover:bg-[#1a1a1b]/[0.05] rounded">
                Follow
              </button>
            </div>
          </div>
        </div>
      </div>

      {replyingTo === comment.id && (
        <form onSubmit={handleSubmitReply} className="ml-10 mb-4">
          <textarea
            value={newReply}
            onChange={(e) => setNewReply(e.target.value)}
            placeholder={`Reply to ${comment.author}...`}
            className="w-full p-2 text-sm border border-[#edeff1] rounded focus:border-[#1a1a1b] focus:outline-none"
            rows={4}
          />
          <div className="flex gap-2 mt-2">
            <button
              type="submit"
              className="px-4 py-1 text-sm font-bold text-white bg-[#0079d3] rounded-full hover:bg-[#0079d3]/90"
            >
              Reply
            </button>
            <button
              type="button"
              onClick={() => setReplyingTo(null)}
              className="px-4 py-1 text-sm font-bold text-[#0079d3] border border-[#0079d3] rounded-full hover:bg-[#0079d3]/[0.05]"
            >
              Cancel
            </button>
          </div>
        </form>
      )}

      {comment.replies?.map(nestedReply => (
        <Comment key={nestedReply.id} comment={nestedReply} depth={depth + 1} onAddReply={onAddReply} />
      ))}
    </div>
  );
};

export default Comment;