import React from 'react'
import { motion } from 'framer-motion'

const testimonials = [
  {
    name: "សុខ វិសាល",
    location: "ភ្នំពេញ",
    quote: "ជំនាញកូដដែលទទួលបានតាមរយៈវគ្គសិក្សារបស់ CodeForCambodia បានធ្វើឱ្យខ្ញុំមានទំនុកចិត្តច្រើនអំពីសមត្ថភាពក្នុងការសរសេរកូដនិងបង្កើតកម្មវិធី។",
    initials: "សវ"
  },
  {
    name: "ស្រីនាង ចាន់ថា",
    location: "សៀមរាប",
    quote: "គោលដៅរបស់ខ្ញុំគឺរៀនសរសេរកូដដើម្បីនៅពេលខ្ញុំចូលធ្វើការវិញ ខ្ញុំនឹងក្លាយជាបេក្ខជនការងារដែលមានភាពប្រកួតប្រជែងជាងមុន។",
    initials: "សច"
  },
  {
    name: "រតនា គឹមសាន",
    location: "បាត់ដំបង",
    quote: "ខ្ញុំបានសាកល្បងវេទិកាកូដជាច្រើន ប៉ុន្តែគ្មានវេទិកាណាមួយផ្គូផ្គងនឹងភាពងាយស្រួលនៃការរៀនដែលខ្ញុំទទួលបានជាមួយ CodeForCambodia ឡើយ។",
    initials: "រគ"
  }
]

const RetroCodeBubble = ({ children, className }) => (
  <div className={`relative ${className}`}>
    <div className="absolute inset-0 bg-blue-200 rounded-lg transform rotate-3"></div>
    <div className="relative bg-blue-100 rounded-lg p-1 transform -rotate-3">
      <div className="bg-white rounded-lg p-4 border-2 border-blue-400 shadow-inner">
        {children}
      </div>
    </div>
  </div>
)

export default function TestimonialsSection() {
  return (
    <section className="py-16 bg-gray-100 relative overflow-hidden font-mono">
      {/* Retro code-like floating elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {['const', 'let', 'var', 'function', 'if', 'for', 'while', 'return'].map((keyword, index) => (
          <motion.div
            key={index}
            className="absolute text-blue-600 text-opacity-20 text-xs sm:text-sm"
            initial={{ x: Math.random() * 100 + '%', y: Math.random() * 100 + '%' }}
            animate={{
              x: [null, Math.random() * 100 + '%'],
              y: [null, Math.random() * 100 + '%'],
              rotate: [0, 360],
            }}
            transition={{
              duration: Math.random() * 20 + 10,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
          >
            {keyword}
          </motion.div>
        ))}
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <h2 className="text-3xl sm:text-4xl font-bold text-center text-blue-600 mb-12 Nokora">
          &lt;ពាក្យពីសិស្ស/&gt;
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <RetroCodeBubble key={index} className="transform hover:scale-105 transition-transform duration-300">
              <div className="relative z-10">
                <div className="w-16 h-16 rounded-full bg-gradient-to-br from-blue-400 to-purple-500 flex items-center justify-center mb-4 mx-auto">
                  <span className="text-white text-xl font-bold Nokora">{testimonial.initials}</span>
                </div>
                <blockquote className="text-gray-700 mb-4 Nokora relative">
                  <span className="absolute top-0 left-0 text-4xl text-blue-400 opacity-50">&lt;</span>
                  <p className="pl-6 pr-6 pt-2 pb-2 text-sm">{testimonial.quote}</p>
                  <span className="absolute bottom-0 right-0 text-4xl text-blue-400 opacity-50">/&gt;</span>
                </blockquote>
                <div className="font-semibold text-gray-900 Nokora text-center">{testimonial.name}</div>
                <div className="text-gray-600 Nokora text-center text-sm">{testimonial.location}</div>
              </div>
            </RetroCodeBubble>
          ))}
        </div>
      </div>
    </section>
  )
}