import React from 'react'

const Button = React.memo(({ children, onClick, disabled, className, icon: Icon }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-2 sm:px-3 py-1 sm:py-2 rounded-md text-xs sm:text-sm font-medium transition-colors duration-150 flex items-center ${className}`}
  >
    {Icon && <Icon size={14} className="mr-1 sm:mr-2" />}
    {children}
  </button>
))

export default Button