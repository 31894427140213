import React from 'react';

const InputField = ({ label, name, type, value, onChange, error, className }) => (
  <div>
    <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
      <span className="text-orange-500">let</span> {label} <span className="text-orange-500">=</span>
    </label>
    <input
      id={name}
      name={name}
      type={type}
      required
      value={value}
      onChange={onChange}
      className={className}
    />
    {error && (
      <p className="mt-1 text-xs text-red-600">{error}</p>
    )}
  </div>
);

export default InputField;
