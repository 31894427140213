import React from 'react';
import { X, MousePointer2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

export default function DevToolsModal({ isOpen, onClose }) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 Nokora"
        >
          <motion.div
            initial={{ scale: 0.9, y: 20 }}
            animate={{ scale: 1, y: 0 }}
            exit={{ scale: 0.9, y: 20 }}
            className="bg-white rounded-xl shadow-2xl max-w-2xl w-full overflow-hidden"
          >
            <div className="p-6 space-y-6">
              <div className="flex justify-between items-center">
                <h2 className="text-3xl font-bold text-gray-800">របៀបបើក DevTools</h2>
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                  <X size={24} />
                </button>
              </div>

              <div className="space-y-4">
                <div className="bg-gray-100 p-4 rounded-lg">
                  <h3 className="text-xl font-semibold mb-2 text-gray-700">វិធីចុចខាងស្តាំ (Windows)</h3>
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0 w-1/2 relative">
                      <div className="bg-white border border-gray-200 rounded-md p-2 shadow-sm">
                        <div className="h-32 bg-gray-200 rounded flex items-center justify-center text-gray-400">
                          ខ្លឹមសារគេហទំព័រ
                        </div>
                      </div>
                      <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                        <MousePointer2 size={24} className="text-blue-600" />
                      </div>
                      <div className="absolute right-0 top-1/2 transform translate-y-4">
                        <div className="bg-white border border-gray-200 rounded shadow-lg p-2">
                          <ul className="space-y-1 text-sm">
                            <li className="px-2 py-1 hover:bg-blue-100 rounded">ថយក្រោយ</li>
                            <li className="px-2 py-1 hover:bg-blue-100 rounded">ទៅមុខ</li>
                            <li className="px-2 py-1 hover:bg-blue-100 rounded">ផ្ទុកឡើងវិញ</li>
                            <li className="px-2 py-1 hover:bg-blue-100 rounded">រក្សាទុកជា...</li>
                            <li className="px-2 py-1 bg-blue-500 text-white rounded">ពិនិត្យ</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow">
                      <p className="text-gray-600">
                        ចុចខាងស្តាំនៅកន្លែងណាមួយលើគេហទំព័រ ហើយជ្រើសរើស "Inspect" ពីម៉ឺនុយបរិបទដើម្បីបើក DevTools។
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="bg-gray-100 p-4 rounded-lg">
                      <h3 className="text-xl font-semibold mb-2 text-gray-700">ផ្លូវកាត់ក្តារចុច (Windows/Linux)</h3>
                      <p className="text-gray-600">ចុច <kbd className="bg-gray-200 px-2 py-1 rounded">F12</kbd></p>
                      <p className="text-gray-600 mt-2">ឬប្រើ:</p>
                      <p className="text-gray-600">
                        <kbd className="bg-gray-200 px-2 py-1 rounded">Ctrl</kbd> + 
                        <kbd className="bg-gray-200 px-2 py-1 rounded ml-1">Shift</kbd> + 
                        <kbd className="bg-gray-200 px-2 py-1 rounded ml-1">I</kbd>
                      </p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-lg">
                      <h3 className="text-xl font-semibold mb-2 text-gray-700">ផ្លូវកាត់ក្តារចុច (Mac)</h3>
                      <p className="text-gray-600">
                        <kbd className="bg-gray-200 px-2 py-1 rounded">Cmd</kbd> + 
                        <kbd className="bg-gray-200 px-2 py-1 rounded ml-1">Option</kbd> + 
                        <kbd className="bg-gray-200 px-2 py-1 rounded ml-1">I</kbd>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  <button
                    onClick={onClose}
                    className="w-full px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors duration-200 shadow-lg hover:shadow-xl transform hover:scale-105 active:scale-95"
                  >
                    យល់ហើយ អរគុណ!
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}