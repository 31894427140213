import React, { useState, useEffect } from 'react';
import Header from '../../Components/Headers/CodeForCambodiaHeader';
import Footer from '../../Components/Footer';
import { Terminal, Code, Play, CheckCircle } from 'lucide-react';
import parse from 'html-react-parser';
import ReactNativeCompiler from '../../Components/Lessons/ReactNative/ReactNativeCompiler';
import axios from 'axios';
import options from '../../Components/Lessons/OptionsQuill';

const Button = React.memo(({ children, onClick, className, icon: Icon, disabled }) => (
  <button
    onClick={onClick}
    className={`px-2 sm:px-3 py-1 sm:py-2 rounded-md text-xs sm:text-sm font-medium transition-colors duration-150 flex items-center ${className}`}
    disabled={disabled}
  >
    {Icon && <Icon size={14} className="mr-1 sm:mr-2" />}
    {children}
  </button>
));

const ReactNativeLesson = ({ lesson = { lesson_name: 'React Native Lesson', instructions: '<p>Sample instructions</p>', sample_output: '' } }) => {
  const [activeSection, setActiveSection] = useState('instructions');
  const [platform, setPlatform] = useState('web');
  const [theme, setTheme] = useState('light');
  const [snackCode, setSnackCode] = useState(lesson.starter_code || '');
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [currentCode, setCurrentCode] = useState(lesson.starter_code || '');

  // Base URL for direct code embedding
  const baseUrl = 'https://snack.expo.dev/embedded';

  // Generate a unique channel ID
  const [channel] = useState(() => Math.random().toString(36).substring(2, 15));

  // Use the persisted code instead of re-encoding the starter code each time
  const encodedCode = encodeURIComponent(snackCode || lesson.starter_code);
  const url = `${baseUrl}?code=${encodedCode}&platform=${platform}&theme=${theme}&channel=${channel}&postMessage=true`;

  // Listen for messages from the Snack iframe
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== 'https://snack.expo.dev') return;
    
      try {
        const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
  
        console.log('Parsed message data:', data);
  
        // Handle different message types
        if (data.type === 'CODE_CHANGED') {
          if (data.payload && data.payload.code) {
            const codeFiles = data.payload.code; // codeFiles is an object with filenames as keys
            const appCode = codeFiles['App.js'];
  
            if (appCode !== undefined) {
              setCurrentCode(appCode);
              setSnackCode(appCode);
              console.log('App.js code updated:', appCode);
            } else {
              console.warn('App.js not found in code payload:', codeFiles);
            }
          } else {
            console.warn('CODE_CHANGED message missing payload.code');
          }
        } else {
          console.log('Unhandled message type:', data.type);
        }
      } catch (error) {
        console.error('Error handling Snack message:', error);
      }
    };
  
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [channel]);
  
  // Debug logging
  useEffect(() => {
    console.log('Current code updated:', currentCode);
  }, [currentCode]);

  const handleVerifyCode = async () => {
    setIsVerifying(true);
    setVerificationStatus(null);

    try {
      console.log('Sending code for verification:', currentCode); // Debug log
      const response = await axios.post('/api/verify-code', {
        code: currentCode, // Use currentCode instead of snackCode
        expectedOutput: lesson.sample_output
      });

      setVerificationStatus({
        success: response.data.success,
        message: response.data.message
      });
    } catch (error) {
      console.error('Verification error:', error); // Debug log
      setVerificationStatus({
        success: false,
        message: error.response?.data?.message || 'An error occurred during verification'
      });
    } finally {
      setIsVerifying(false);
    }
  };

  const renderVerificationAlert = () => {
    if (!verificationStatus) return null;

    return (
      <div className={`mt-4 p-4 rounded-lg ${verificationStatus.success ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'}`}>
        <p>{verificationStatus.message}</p>
      </div>
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Header/>
      <header className="bg-gray-100 p-2 flex items-center justify-between border-b border-gray-200 sticky top-0 z-10">
        <div className="flex items-center space-x-2">
          <div className="flex space-x-1">
            <div className="w-3 h-3 rounded-full bg-red-500"></div>
            <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
            <div className="w-3 h-3 rounded-full bg-green-500"></div>
          </div>
          <h1 className="text-xs sm:text-sm font-semibold">{lesson.lesson_name}</h1>
        </div>
        
        {/* {activeSection === 'code' && (
          <Button
            onClick={handleVerifyCode}
            className={`bg-blue-600 text-white hover:bg-blue-700 ${isVerifying ? 'opacity-50 cursor-not-allowed' : ''}`}
            icon={CheckCircle}
            disabled={isVerifying}
          >
            {isVerifying ? 'Verifying...' : 'Verify Code'}
          </Button>
        )} */}
      </header>

      <div className="flex flex-grow">
        <nav className="bg-gray-100 w-12 md:w-48 flex flex-col border-r border-gray-200 sticky">
          {[
            { id: 'instructions', icon: Terminal, label: 'Instructions' },
            { id: 'code', icon: Code, label: 'Code' },
            { id: 'expected', icon: Play, label: 'Expected' }
          ].map(({ id, icon: Icon, label }) => (
            <Button
              key={id}
              onClick={() => setActiveSection(id)}
              className={`justify-start w-full py-2 sm:py-3 ${
                activeSection === id ? 'bg-blue-100 text-blue-600' : 'text-gray-600 hover:bg-gray-200'
              }`}
              icon={Icon}
            >
              <span className="hidden md:inline">{label}</span>
            </Button>
          ))}
        </nav>

        <main className="flex-grow">
          <div className={`${activeSection === 'instructions' ? 'block' : 'hidden'}`}>
            <div className="prose prose-sm sm:prose lg:prose-lg p-4 max-w-none Nokora">
              {lesson?.instructions ? parse(lesson.instructions, options) : (
                <p className="text-gray-600 italic">No instructions available for this lesson.</p>
              )}
            </div>
          </div>

          <div className={`${activeSection === 'code' ? 'block' : 'hidden'}`}>
            <div className="space-y-4">
              <iframe
                src={url}
                className="w-full h-[500px]"
                frameBorder="0"
                allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; xr-spatial-tracking"
                sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
              />
              {renderVerificationAlert()}
            </div>
          </div>

          <div className={`${activeSection === 'expected' ? 'block' : 'hidden'}`}>
            <div className="prose prose-sm sm:prose lg:prose-lg max-w-none">
              <ReactNativeCompiler output={lesson.sample_output}/>
            </div>
          </div>
        </main>
      </div>
      <Footer/>
    </div>
  );
};

export default ReactNativeLesson;
