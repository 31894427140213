import React, { useState } from 'react'
import { ArrowRight, Code, Users, BookOpen, X } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'
import CommunityJoinSection from '../Components/Donation/CommunityJoinSection'
import Header from '../Components/Headers/CodeForCambodiaHeader'
import Footer from '../Components/Footer'
import DonationSection from '../Components/Donation/DonationSection'
import Team from '../Components/Donation/Team'
import DonationForm from '../Components/Donation/DonationForm'
import DonationModal from '../Components/Donation/DonationModal'

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
}

const stagger = {
  animate: {
    transition: {
      staggerChildren: 0.1
    }
  }
}

export default function Component() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  return (
    <>
      <Header/>
      <motion.div 
        className="min-h-screen bg-white font-sans overflow-x-hidden"
        initial="initial"
        animate="animate"
        variants={stagger}
      >
        <main className="mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
          <motion.section className="text-center mb-12 sm:mb-16" variants={fadeInUp}>
            <motion.h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4" variants={fadeInUp}>
              <motion.span className="text-blue-600 font-mono" variants={fadeInUp}>Great futures are coded</motion.span>
              <br />
              <motion.span className="text-orange-500 font-mono" variants={fadeInUp}>with a small donation</motion.span>
            </motion.h1>
            <motion.p className="text-lg sm:text-xl text-gray-600 mb-6 sm:mb-8 Nokora" variants={fadeInUp}>
              អនាគតយុវជនខ្មែរជាច្រើនត្រូវបានបង្កើតឡើងដោយការបរិច្ចាគតិចតួច
            </motion.p>
            <motion.div className="flex justify-center" variants={fadeInUp}>
              <motion.button 
                className="font-mono bg-blue-600 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-300 text-sm sm:text-base"
                onClick={openModal}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Donate now
              </motion.button>
            </motion.div>
          </motion.section>
          
          <motion.section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 mb-12 sm:mb-16 Nokora" variants={stagger}>
            {[
              {
                bg: "bg-blue-600",
                icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 sm:h-8 sm:w-8 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                </svg>,
                title: "14%",
                description: "សេដ្ឋកិច្ចឌីជីថលនៅកម្ពុជាគ្រោងនឹងកើនឡើង 14% ជារៀងរាល់ឆ្នាំ។ ប៉ុន្តែយុវជនជាច្រើនមិនទាន់ត្រៀមខ្លួនរួចរាល់សម្រាប់ការងារផ្នែកបច្ចេកវិទ្យា ដោយសារកង្វះខាតការអប់រំសមស្រប។"
              },
              {
                bg: "bg-green-500",
                icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 sm:h-8 sm:w-8 mr-2 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                </svg>,
                title: "3.3 លាននាក់",
                description: "មានតែ 32% នៃយុវជនកម្ពុជាប៉ុណ្ណោះដែលបន្តការសិក្សានៅឧត្តមសិក្សា។ នេះបង្ហាញពីតម្រូវការចាំបាច់សម្រាប់ធនធានអប់រំឌីជីថល។"
              },
              {
                bg: "bg-orange-500",
                icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 sm:h-8 sm:w-8 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                </svg>,
                title: "តម្រូវការកំពុងកើនឡើង",
                description: "អ្នកអភិវឌ្ឍន៍បណ្តាញអាចរកប្រាក់ចំណូលពី $600 ដល់ $1,200 ក្នុងមួយខែ។ វិស្វករបណ្តាញអាចរកបានពី $800 ដល់ $1,500 នេះបង្កើតឱកាសការងារល្អៗសម្រាប់យុវជនកម្ពុជា។"
              }
            ].map((item, index) => (
              <motion.div key={index} className={`${item.bg} text-white p-4 sm:p-6 rounded-lg`} variants={fadeInUp}>
                <div className="flex items-center mb-3 sm:mb-4">
                  {item.icon}
                  <h2 className="text-2xl sm:text-3xl font-bold">{item.title}</h2>
                </div>
                <p className="text-base sm:text-lg">{item.description}</p>
              </motion.div>
            ))}
          </motion.section>

          <motion.section className="text-center mb-8 sm:mb-12" variants={fadeInUp}>
            <motion.h2 className="text-2xl sm:text-3xl font-bold mb-6 sm:mb-8" variants={fadeInUp}>
              <motion.span className="text-blue-600 font-mono" variants={fadeInUp}>Donating to CodeForCambodia</motion.span>
              <br />
              <motion.span className="text-orange-500 Nokora" variants={fadeInUp}>ធ្វើឱ្យមានផលយ៉ាងពិតប្រាកដ</motion.span>
            </motion.h2>
            <motion.div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 Nokora" variants={stagger}>
              {[
                { icon: <Code className="h-6 w-6 sm:h-8 sm:w-8 text-blue-600" />, title: "រៀនសរសេរកូដ", description: "ផ្តល់នូវការអប់រំផ្នែកសរសេរកូដប្រកបដោយគុណភាពសម្រាប់យុវជនកម្ពុជា។" },
                { icon: <Users className="h-6 w-6 sm:h-8 sm:w-8 text-orange-500" />, title: "កសាងសហគមន៍", description: "ជំរុញការគាំទ្រសហគមន៍បច្ចេកវិទ្យានៅកម្ពុជា" },
                { icon: <BookOpen className="h-6 w-6 sm:h-8 sm:w-8 text-blue-600" />, title: "បង្កើតឱកាស", description: "បើកផ្លូវរឆ្ពោះទៅរកអនាគតការងារ និងការបង្កើតថ្មីកាន់តែប្រសើរ" }
              ].map((item, index) => (
                <motion.div key={index} className="flex flex-col items-center" variants={fadeInUp}>
                  <motion.div className="bg-blue-100 p-3 sm:p-4 rounded-full mb-3 sm:mb-4" whileHover={{ scale: 1.1 }}>
                    {item.icon}
                  </motion.div>
                  <h3 className="font-semibold mb-2 Nokora text-lg sm:text-xl">{item.title}</h3>
                  <p className="text-gray-600 text-base sm:text-lg">{item.description}</p>
                </motion.div>
              ))}
            </motion.div>
          </motion.section>

          <DonationSection openModal={openModal} />

          <Team/>
          <CommunityJoinSection openModal={openModal} />
        </main>
        <Footer/>
      </motion.div>

      <DonationModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  )
}