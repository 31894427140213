import React from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-sql';

const SQLDisplay = ({ code, title }) => {
  React.useEffect(() => {
    Prism.highlightAll();
  }, [code]);

  return (
    <div className="p-4 bg-gray-50 border-l-4 border-gray-500">
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <pre className="relative overflow-x-auto">
        <code className="language-sql block whitespace-pre-wrap font-mono text-sm bg-white p-4 rounded">
          {code}
        </code>
      </pre>
    </div>
  );
};

export default SQLDisplay;