import React from 'react';

const Sidebar = () => {
  return (
    <aside className="w-80">
      <div className="bg-white rounded border  p-4 mb-4">
        <h2 className="text-base font-medium mb-2">About Community</h2>
        <p className="text-sm mb-4">A place to discuss Criminal Minds, share theories, and connect with other fans!</p>
        <div className="flex justify-between text-sm mb-4">
          <div>
            <div className="font-medium">100k</div>
            <div className="text-[#7c7c7c]">Members</div>
          </div>
          <div>
            <div className="font-medium">1.2k</div>
            <div className="text-[#7c7c7c]">Online</div>
          </div>
        </div>
        <button className="w-full bg-[#0079d3] text-white font-bold py-1 px-4 rounded-full text-sm">Join</button>
      </div>
      <div className="bg-white rounded border p-4 mb-4">
        <h2 className="text-base font-medium mb-2">Rules</h2>
        <ol className="list-decimal list-inside text-sm">
          <li className="mb-2">Be civil and respectful</li>
          <li className="mb-2">No spoilers in titles</li>
          <li className="mb-2">Use spoiler tags when necessary</li>
          <li className="mb-2">No piracy or illegal streaming links</li>
        </ol>
      </div>
      <div className="bg-white rounded border p-4">
        <h2 className="text-base font-medium mb-2">Related Communities</h2>
        <ul className="text-sm">
          <li className="mb-2"><a href="#" className="text-[#0079d3] hover:underline">r/TVCrimeDramas</a></li>
          <li className="mb-2"><a href="#" className="text-[#0079d3] hover:underline">r/TrueCrime</a></li>
          <li className="mb-2"><a href="#" className="text-[#0079d3] hover:underline">r/UnsolvedMysteries</a></li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;