import React, { useState, useEffect } from 'react';
import { Search, X } from 'lucide-react';

const SearchBar = ({ onSearch, onClear, isSearching }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isTyping) {
        onSearch(searchTerm);
        setIsTyping(false);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm, isTyping, onSearch]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsTyping(true);
  };

  const handleClear = () => {
    setSearchTerm('');
    onClear();
  };

  return (
    <div className="w-full mx-auto px-4">
      <div className="relative">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            className="w-full h-12 pl-12 pr-12 Nokora rounded-full bg-white border-0 focus:ring-2 focus:ring-blue-500 placeholder:text-gray-400 outline-none"
            placeholder="វាយ first name ឬ last name?"
          />
          <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          {(searchTerm || isSearching) && (
            <button
              onClick={handleClear}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 h-8 w-8 text-gray-400 hover:text-gray-600 focus:outline-none"
            >
              <X className="h-4 w-4" />
              <span className="sr-only">Clear search</span>
            </button>
          )}
        </div>
        {isSearching && searchTerm && (
          <div className="absolute top-full left-0 right-0 mt-2 text-sm text-gray-500 text-center bg-white py-1 rounded-md shadow-sm border border-gray-200">
            Searching for "{searchTerm}"...
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;