import { useState, useEffect } from 'react'
import Confetti from 'react-confetti'

export default function ConfettiWrapper({ showConfetti }) {
  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    function updateDimensions() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  if (!showConfetti) return null

  return (
    <Confetti 
      width={windowDimensions.width}
      height={windowDimensions.height}
    />
  )
}