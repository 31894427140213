import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CourseCard from './CourseCard';
import { Code } from 'lucide-react';

function CourseList({ courses }) {
  const [showAll, setShowAll] = useState(false);
  const [displayCount, setDisplayCount] = useState(2);

  useEffect(() => {
    const updateDisplayCount = () => {
      if (window.innerWidth >= 1280) setDisplayCount(8); // 2xl
      else if (window.innerWidth >= 1024) setDisplayCount(8); // lg 
      else if (window.innerWidth >= 768) setDisplayCount(4); // md
      else setDisplayCount(2); // xs
    };

    // Set initial count
    updateDisplayCount();

    // Add resize listener
    window.addEventListener('resize', updateDisplayCount);

    // Cleanup
    return () => window.removeEventListener('resize', updateDisplayCount);
  }, []);

  const displayedCourses = showAll ? courses : courses.slice(0, displayCount);

  return (
    <div className="container mx-auto px-4 py-8 font-mono text-blue-800 min-h-screen relative">
      {/* Vertical line */}
      <div className="absolute left-0 top-0 bottom-0 w-px bg-blue-200"></div>

      <div className="mb-8 text-center relative">
        <div className="absolute left-0 right-0 h-px bg-blue-200 top-1/2 transform -translate-y-1/2"></div>
        <span className="relative bg-white px-4">
          <Code className="inline-block mr-2 text-blue-600" size={24} />
          <h1 className="text-3xl font-bold inline-block text-blue-700 Nokora">ថ្នាក់ដែលមាន</h1>
          <span className="ml-3 inline-flex items-center px-3 py-1 rounded-full Nokora text-sm font-medium bg-blue-100 text-blue-800">
            {courses.length} ថ្នាក់សរុប
          </span>
        </span>
      </div>

      <div className="mb-6 opacity-70 border-b border-blue-200 pb-2">
        <span className="text-purple-600">import</span>{' '}
        <span className="text-blue-600">{'{ CourseCard }'}</span>{' '}
        <span className="text-purple-600">from</span>{' '}
        <span className="text-orange-500">'./components'</span>;
      </div>

      <div className="mb-4 border-l-2 border-blue-200 pl-4">
        <span className="text-purple-600">const</span>{' '}
        <span className="text-blue-600 Nokora">ថ្នាក់</span>{' '}
        <span className="text-purple-600">=</span>{' '}
        <span className="text-orange-500">{'() => {'}</span>
      </div>

      <div className="pl-8 mb-4 border-l-2 border-blue-200">
        <span className="text-purple-600">return</span>{' '}
        <span className="text-orange-500">{'('}</span>
      </div>

      <div className="pl-12 relative">
        <div className="absolute left-0 top-0 bottom-0 w-px bg-blue-200"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
          {displayedCourses && displayedCourses
            .sort((a, b) => a.course_index - b.course_index)
            .map((course) => (
              <CourseCard key={course.id} course={course} />
            ))}
        </div>
        {courses.length > displayCount && (
          <div className="text-center mt-8">
            <button
              onClick={() => setShowAll(!showAll)}
              className="group relative inline-flex items-center justify-center px-8 py-3 font-mono text-base font-medium text-blue-600 bg-transparent border border-blue-300 rounded-lg shadow-sm hover:bg-blue-50/30 transition-all duration-300 transform hover:-translate-y-0.5 hover:shadow-md hover:border-blue-400"
            >
              <div className="absolute inset-0 bg-[linear-gradient(110deg,transparent_0.5%,rgba(59,130,246,0.1)_1%,transparent_45%)] opacity-0 group-hover:opacity-100 group-hover:animate-shimmer"></div>
              {showAll ? (
                <span className="flex items-center space-x-2">
                  <code className="text-blue-400">{'<'}</code>
                  <span>Show Less</span>
                  <code className="text-blue-400">{'/>'}</code>
                  <svg className="w-4 h-4 transform rotate-180 transition-transform duration-300 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </span>
              ) : (
                <span className="flex items-center space-x-2">
                  <code className="text-blue-400">{'<'}</code>
                  <span>View All Courses</span>
                  <code className="text-blue-400">{'/>'}</code>
                  <svg className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </span>
              )}
            </button>
          </div>
        )}
      </div>

      <div className="pl-8 mt-4 border-l-2 border-blue-200">
        <span className="text-orange-500">{')'}</span>;
      </div>

      <div className="mt-4 border-l-2 border-blue-200 pl-4">
        <span className="text-orange-500">{'}'}</span>;
      </div>

      <div className="mt-6 opacity-70 border-t border-blue-200 pt-2">
        <span className="text-purple-600">export default</span>{' '}
        <span className="text-blue-600">CourseList</span>;
      </div>

      {/* Horizontal lines at the bottom */}
      <div className="mt-8 space-y-1">
        <div className="h-px bg-blue-200"></div>
        <div className="h-px bg-blue-200"></div>
        <div className="h-px bg-blue-200"></div>
      </div>
    </div>
  );
}

export default CourseList;