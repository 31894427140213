import React, { useState } from 'react';
import Comment from './Comment';

const CommentSection = () => {
  const [replies, setReplies] = useState([
    { 
      id: 1, 
      author: 'darthbiblia', 
      content: 'Can you watch them through the CBS app? I\'m hoping so. I\'ve only got a couple of episodes of season 9 left on Netflix :(',
      votes: 3, 
      timestamp: '10y ago',
      replies: [
        {
          id: 3,
          author: 'thabooshka', 
          content: 'Unfortunately you can only watch a few of the later episodes of season 10 on CBS.',
          votes: 1,
          timestamp: '10y ago'
        }
      ]
    },
    // ... other comments
  ]);
  const [newComment, setNewComment] = useState('');

  const handleAddComment = (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    const newReply = {
      id: Date.now(), // Simple way to generate unique IDs
      author: 'Current User', // In a real app, get from auth
      content: newComment.trim(),
      votes: 0,
      timestamp: 'just now',
      replies: []
    };

    setReplies([...replies, newReply]);
    setNewComment('');
  };

  const handleAddReply = (parentId, replyContent) => {
    const newReply = {
      id: Date.now(),
      author: 'Current User',
      content: replyContent,
      votes: 0,
      timestamp: 'just now'
    };

    setReplies(prevReplies => {
      return prevReplies.map(reply => {
        if (reply.id === parentId) {
          return {
            ...reply,
            replies: [...(reply.replies || []), newReply]
          };
        }
        return reply;
      });
    });
  };

  return (
    <div>
      <div className="bg-[#f6f7f8] p-2 mb-4 rounded border  text-xs text-[#1a1a1b]">
        <svg className="inline-block w-4 h-4 mr-1" viewBox="0 0 16 16" fill="currentColor">
          <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm1 11H7V9h2v2zm0-3H7V3h2v5z"/>
        </svg>
        Archived post. New comments cannot be posted and votes cannot be cast.
      </div>
      <div className="bg-white rounded border mb-4">
        <form onSubmit={handleAddComment} className="p-4 border-b">
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="What are your thoughts?"
            className="w-full p-2 text-sm border border-[#edeff1] rounded focus:border-[#1a1a1b] focus:outline-none"
            rows={4}
          />
          <div className="flex justify-end mt-2">
            <button
              type="submit"
              className="px-4 py-1 text-sm font-bold text-white bg-[#0079d3] rounded-full hover:bg-[#0079d3]/90"
            >
              Comment
            </button>
          </div>
        </form>

        <div className="p-2">
          {replies.map((reply) => (
            <Comment 
              key={reply.id} 
              comment={reply} 
              onAddReply={handleAddReply}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommentSection;