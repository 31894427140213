"use client"

import React, { useState, useEffect, useRef } from 'react'
import { X, ChevronDown, Search } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'
import axios from 'axios'

// List of provinces (unchanged)
const provinces = [
  "ភ្នំពេញ", "បន្ទាយមានជ័យ", "បាត់ដំបង", "កំពង់ចាម", "កំពង់ឆ្នាំង", "កំពង់ស្ពឺ",
  "កំពង់ធំ", "កំពត", "កណ្ដាល", "កែប", "កោះកុង", "ក្រចេះ", "មណ្ឌលគិរី",
  "ឧត្តរមានជ័យ", "ប៉ៃលិន", "ព្រះវិហារ", "ព្រៃវែង", "ពោធិ៍សាត់",
  "រតនគិរី", "សៀមរាប", "ព្រះសីហនុ", "ស្ទឹងត្រែង", "ស្វាយរៀង", "តាកែវ",
  "ត្បូងឃ្មុំ"
]

// ជម្រើសសម្រាប់ប្រអប់ទម្លាក់ (អ្នកអាចជំនួសទាំងនេះដោយជម្រើសពិតរបស់អ្នក)
const occupations = ["និស្សិត", "មានការងារធ្វើ", "គ្មានការងារធ្វើ", "ធ្វើការដោយខ្លួនឯង", "ផ្សេងៗ"]
const educationLevels = ["វិទ្យាល័យ", "បរិញ្ញាបត្រ", "អនុបណ្ឌិត", "បណ្ឌិត", "អត់មានសញ្ញាប័ត្រ", "ផ្សេងៗ"]
const studyFields = ["អត់មានរៀន", "វិទ្យាសាស្ត្រកុំព្យូទ័រ", "វិស្វកម្ម", "ពាណិជ្ជកម្ម", "សិល្បៈ", "ផ្សេងៗ"]
const codingExperiences = ["គ្មានបទពិសោធន៏", "កម្រិតដំបូង", "កម្រិតមធ្យម", "កម្រិតខ្ពស់", "ជំនាញ"]
const learningReasons = ["តម្រូវការសិក្សាសម្រាប់សាលា", "ស្រលាញ់ចង់រៀន", "ប្តូរអាជីព", "កែលម្អជំនាញ", "ផ្សេងៗ"]
const interestTopics = ["រៀនភាសាគ្រឹះ", "បង្កើត website", "បង្កើត app", "វិទ្យាសាស្ត្រទិន្នន័យ", "AI/Machine Learning", "ផ្សេងៗ"]
const devices = ["ទូរសព្ទ", "កុំព្យូទ័រ", "iPad", "ផ្សេងៗ"]

export default function SurveyModal({ isOpen, onClose, setShowSurveyModal }) {
  const [error, setError] = useState('')
  const [formData, setFormData] = useState({
    location: '',
    occupation: '',
    educationLevel: '',
    currentStudyField: '',
    previousCodingExperience: '',
    reasonForLearning: '',
    topicOfInterest: '',
    device: navigator.userAgent
  })
  const [isDropdownOpen, setIsDropdownOpen] = useState({
    location: false,
    occupation: false,
    educationLevel: false,
    currentStudyField: false,
    previousCodingExperience: false,
    reasonForLearning: false,
    topicOfInterest: false,
    device: false
  })
  const [searchTerm, setSearchTerm] = useState('')
  const dropdownRefs = {
    location: useRef(null),
    occupation: useRef(null),
    educationLevel: useRef(null),
    currentStudyField: useRef(null),
    previousCodingExperience: useRef(null),
    reasonForLearning: useRef(null),
    topicOfInterest: useRef(null),
    device: useRef(null)
  }

  const filteredProvinces = provinces.filter(province =>
    province.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const handleDropdownToggle = (field) => {
    setIsDropdownOpen(prev => ({ ...prev, [field]: !prev[field] }))
  }

  const handleOptionSelect = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }))
    setIsDropdownOpen(prev => ({ ...prev, [field]: false }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const token = localStorage.getItem('token')

    // check if all fields are filled
    if (Object.values(formData).every(value => value !== '')) { 
      console.log(formData)
      axios.post('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/survey', formData, {
        headers: { 'Authorization': `Bearer ${token}` }
      })
      .then(response => {
        onClose()
        console.log(response)
      })
      .catch(error => {
        setError(error.response.data.message)
      })
    } else {
      setError('សូមបំពេញទាំងអស់ក្នុងការស្ទង់មតិនេះ។')
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.entries(dropdownRefs).forEach(([field, ref]) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsDropdownOpen(prev => ({ ...prev, [field]: false }))
        }
      })
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const closeAttempts = parseInt(localStorage.getItem('surveyCloseAttempts') || '0')

    console.log(closeAttempts)
    if (closeAttempts >= 3) {
      setShowSurveyModal(false)
    }
  }, [])

  const closeAttempts = parseInt(localStorage.getItem('surveyCloseAttempts') || '0')

  useEffect(() => {
    if (isOpen) {
      console.log(closeAttempts)
      if (closeAttempts >= 3) {
        setShowSurveyModal(false)
      }
    }
  }, [isOpen, setShowSurveyModal])

  const handleClose = () => {
    const closeAttempts = parseInt(localStorage.getItem('surveyCloseAttempts') || '0')
    localStorage.setItem('surveyCloseAttempts', (closeAttempts + 1).toString())
    onClose()
  }

  if (!isOpen || closeAttempts>= 3) return null

  const renderDropdown = (field, options, placeholder) => (
    <div className="relative" ref={dropdownRefs[field]}>
      <button
        type="button"
        onClick={() => handleDropdownToggle(field)}
        className="w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-left text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
      >
        {formData[field] || placeholder}
        <ChevronDown className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
      </button>
      <AnimatePresence>
        {isDropdownOpen[field] && (
          <motion.ul
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg max-h-60 overflow-auto"
          >
            {options.map((option) => (
              <li
                key={option}
                onClick={() => handleOptionSelect(field, option)}
                className="px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
              >
                {option}
              </li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  )

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 Nokora px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.2 }}
        className="relative w-full max-w-md rounded-lg bg-white shadow-xl"
      >
        <div className="flex items-center justify-between rounded-t-lg bg-gray-100 px-4 py-3">
          <h2 className="text-lg font-medium text-gray-900">ការស្ទង់មតិ</h2>
          <button onClick={handleClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-5 w-5" />
            <span className="sr-only">Close</span>
          </button>
        </div>
        <div className="py-2 px-4 sm:px-6 text-sm text-gray-700 bg-blue-50 border-l-4 border-blue-500">
          <p className="mb-2 font-semibold">ដើម្បីជួយក្នុងការអភិវឌ្ឍន៍ website</p>
          <p className="mb-2">សុំអ្នកចូលរួមបំពេញការស្ទង់មតិនេះ។</p>
          <p className="italic">សូមអរគុណទុកជាមុន។</p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4 p-4 sm:p-6 max-h-[calc(100vh-200px)] overflow-y-auto">
          <div className="space-y-2">
            <label htmlFor="location" className="block text-sm font-medium text-gray-700">
              ខេត្ត
            </label>
            <div className="relative" ref={dropdownRefs.location}>
              <button
                type="button"
                onClick={() => handleDropdownToggle('location')}
                className="w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-left text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                {formData.location || 'Select a province'}
                <ChevronDown className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
              </button>
              <AnimatePresence>
                {isDropdownOpen.location && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg"
                  >
                    <div className="p-2">
                      <div className="relative">
                        <input
                          type="text"
                          className="w-full rounded-md border border-gray-300 pl-8 pr-3 py-2 text-sm"
                          placeholder="Search provinces..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <Search className="absolute left-2 top-2 h-5 w-5 text-gray-400" />
                      </div>
                    </div>
                    <ul className="max-h-60 overflow-auto py-1">
                      {filteredProvinces.map((province) => (
                        <li
                          key={province}
                          onClick={() => handleOptionSelect('location', province)}
                          className="px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                        >
                          {province}
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          <div className="space-y-2">
            <label htmlFor="occupation" className="block text-sm font-medium text-gray-700">
              ការងារ
            </label>
            {renderDropdown('occupation', occupations, 'Select occupation')}
          </div>
          <div className="space-y-2">
            <label htmlFor="educationLevel" className="block text-sm font-medium text-gray-700">
              កម្រិតសិក្សារ
            </label>
            {renderDropdown('educationLevel', educationLevels, 'Select education level')}
          </div>
          <div className="space-y-2">
            <label htmlFor="currentStudyField" className="block text-sm font-medium text-gray-700">
              ជំនាញសិក្សារ
            </label>
            {renderDropdown('currentStudyField', studyFields, 'Select study field')}
          </div>
          <div className="space-y-2">
            <label htmlFor="previousCodingExperience" className="block text-sm font-medium text-gray-700">
              បទពិសោធន៏កូដ
            </label>
            {renderDropdown('previousCodingExperience', codingExperiences, 'Select experience level')}
          </div>
          <div className="space-y-2">
            <label htmlFor="reasonForLearning" className="block text-sm font-medium text-gray-700">
              មូលហេតុចង់រៀន
            </label>
            {renderDropdown('reasonForLearning', learningReasons, 'Select reason')}
          </div>
          <div className="space-y-2">
            <label htmlFor="topicOfInterest" className="block text-sm font-medium text-gray-700">
              Topic of Interest
            </label>
            {renderDropdown('topicOfInterest', interestTopics, 'Select topic')}
          </div>
          <button
            type="submit"
            className="w-full rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none transition-colors duration-300 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Submit Survey
          </button>
          <button onClick={handleClose} className="w-full rounded-md bg-gray-300 px-4 py-2 text-sm font-medium text-black hover:bg-gray-400 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
            Close 
          </button>
          {error && <p className="text-red-500 text-base md:text-xl text-center">{error}</p>}
        </form>
      </motion.div>
    </div>
  )
}