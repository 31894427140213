import React from 'react';
import { Loader } from 'lucide-react';

const LearningPathStatus = ({ isLoadingProgress, isSaving, error }) => {
  if (!isLoadingProgress && !isSaving && !error) return null;

  return (
    <div className="fixed bottom-20 right-4 z-50 max-w-md">
      {(isLoadingProgress || isSaving) && (
        <div className="rounded-md border bg-blue-50 border-blue-200 p-4">
          <div className="flex items-center">
            <Loader className="h-4 w-4 animate-spin text-blue-600" />
            <div className="text-blue-800 ml-2 font-medium">
              {isLoadingProgress ? 'កំពុងផ្ទុកវឌ្ឍនភាព' : 'កំពុងរក្សាទុកវឌ្ឍនភាព'}
            </div>
          </div>
          <div className="text-blue-600 ml-6">
            សូមរង់ចាំមួយភ្លែត...
          </div>
        </div>
      )}
      
      {error && (
        <div className="rounded-md border border-red-200 bg-red-50 p-4">
          <div className="font-medium text-red-800">មានបញ្ហាក្នុងការរក្សាទុកវឌ្ឍនភាព</div>
          <div className="text-red-600">
            នឹងព្យាយាមម្តងទៀតនៅពេលក្រោយ
          </div>
        </div>
      )}
    </div>
  );
};

export default LearningPathStatus;