import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Flame, Code2, ChevronRight, Facebook, Copy, Check } from 'lucide-react'

export default function StreakUpdateNotification({ show, currentStreak }) {
  const [isCopied, setIsCopied] = useState(false)

  const shareOnFacebook = () => {
    const shareMessage = `I just increased my coding streak to ${currentStreak + 1} days! 🔥 #CodingStreak #CodeForCambodia`
    const url = 'https://codeforcambodia.com'
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(shareMessage)}`
    
    window.open(shareUrl, '_blank', 'width=600,height=400,resizable=yes,scrollbars=yes')
  }

  const copyToClipboard = () => {
    const copyMessage = `I just increased my coding streak to ${currentStreak + 1} days! 🔥 #CodingStreak`
    navigator.clipboard.writeText(copyMessage).then(() => {
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 2000)
    })
  }

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0, scale: 0.8, y: 50 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.8, y: 50 }}
          transition={{ type: "spring", stiffness: 300, damping: 25 }}
          className="fixed bottom-4 right-4 bg-white rounded-lg shadow-lg p-4 font-mono text-white z-50 max-w-sm w-full"
        >
          <div className="flex items-center space-x-2 mb-2">
            <Code2 className="text-yellow-400" size={24} />
            <span className="text-xl font-bold text-gray-800">Streak Updated!</span>
          </div>
          <div className="bg-gray-100 border-gray-300 border rounded-md p-3">
            <div className="flex items-center space-x-2">
              <ChevronRight className="text-green-600" size={20} />
              <span className="text-green-600">const newStreak = </span>
              <motion.div
                initial={{ scale: 1 }}
                animate={{ scale: [1, 1.2, 1] }}
                transition={{ duration: 0.5, repeat: Infinity, repeatDelay: 1 }}
              >
                <Flame className="text-orange-400" size={24} />
              </motion.div>
              <motion.span
                className="text-2xl font-bold text-orange-400"
                initial={{ scale: 1 }}
                animate={{ scale: [1, 1.2, 1] }}
                transition={{ duration: 0.5, repeat: Infinity, repeatDelay: 1 }}
              >
                {currentStreak + 1} 
              </motion.span>
            </div>
          </div>
          <motion.div
            className="mt-2 text-sm text-blue-500"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            Keep coding to maintain your streak!
          </motion.div>
          <div className="mt-3 flex space-x-2">
            <motion.button
              className="flex-1 bg-blue-500 text-white py-2 px-4 rounded-md flex items-center justify-center hover:bg-blue-600 transition-colors duration-200"
              onClick={shareOnFacebook}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7 }}
            >
              <Facebook className="mr-2" size={18} />
              Share
            </motion.button>
            <motion.button
              className="flex-1 bg-gray-200 text-gray-800 py-2 px-4 rounded-md flex items-center justify-center hover:bg-gray-300 transition-colors duration-200"
              onClick={copyToClipboard}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
            >
              {isCopied ? (
                <>
                  <Check className="mr-2" size={18} />
                  Copied!
                </>
              ) : (
                <>
                  <Copy className="mr-2" size={18} />
                  Copy
                </>
              )}
            </motion.button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}