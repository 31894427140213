import React, { useState } from 'react'
import { Send, X, ChevronDown, ChevronUp, Link2 } from 'lucide-react'

const Button = ({ children, onClick, className, icon: Icon, disabled }) => (
  <button
    onClick={onClick}
    className={`px-3 py-2 rounded-md text-sm font-medium flex items-center ${className}`}
    disabled={disabled}
  >
    {Icon && <Icon className="w-4 h-4 mr-2" />}
    {children}
  </button>
)

const SubmissionHeader = ({ 
    submission, 
    selectedChallenge, 
    setSelectedChallenge, 
    challenges, 
    onSubmit, 
    onDelete 
}) => {
  const [showHelp, setShowHelp] = useState(false);

  return (
    <div className="border-b bg-white">
      <div className="px-4 py-3">
        {submission ? (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">

              <div className="flex items-center space-x-3">
                <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                <span className="text-sm text-gray-600 Nokora">
                  បានដាក់ទៅកាន់ការប្រកួត៖ <span className="font-medium text-gray-900">{submission.challenge_title}</span>
                </span>
              </div>
              <div className="flex items-center gap-4">
              <button
                  onClick={() => setShowHelp(!showHelp)}
                  className="text-blue-500 hover:text-blue-700 underline text-sm flex items-center gap-1"
                >
                  <Link2 className="w-4 h-4" />
                  <span className="Nokora">របៀបប្រកួត</span>
                  {showHelp ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
                </button>
                <Button
                  onClick={onDelete}
                  className="text-gray-600 hover:bg-gray-100 Nokora"
                  icon={X}
                >
                  ដកពីការប្រកួត
                </Button>
              </div>
            </div>
            {showHelp && (
              <div className="bg-gray-50 p-4 rounded-md text-sm text-gray-600 space-y-2">
                <p className="Nokora"><strong>របៀបប្រកួត៖</strong></p>
                <ol className="list-decimal pl-4 space-y-1 Nokora">
                  <li>អ្នកអភិវឌ្ឍន៍ផ្សេងទៀតអាចមើលនិងបោះឆ្នោតឱ្យ project ដែលបាន submit</li>
                  <li> project ដែលមានការបោះឆ្នោតច្រើនជាងគេនឹងឈ្នះការប្រកួត</li>
                  <li>អ្នកឈ្នះនឹងទទួលបានរង្វាន់ ចឹងហៅមិត្តភក្តិអោយមកជួយបោះឆ្នោតមក!</li>
                </ol>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">

              <div className="flex items-center space-x-3">
                <div className="w-2 h-2 bg-yellow-500 rounded-full"></div>
                <span className="text-sm text-gray-600 Nokora"> project នេះមិនទាន់បានប្រកួតនោះទេ</span>
              </div>
              <div className="flex items-center gap-4">
              <button
                  onClick={() => setShowHelp(!showHelp)}
                  className="text-blue-500 hover:text-blue-700 underline text-sm flex items-center gap-1"
                >
                  <Link2 className="w-4 h-4" />
                  <span className="Nokora">របៀបប្រកួត</span>
                  {showHelp ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
                </button>
                <Button
                  onClick={onSubmit}
                  className="bg-blue-500 text-white hover:bg-blue-600 Nokora"
                  icon={Send}
                >
                  ដាក់ project ប្រកួត
                </Button>
              </div>
            </div>
            {showHelp && (
              <div className="bg-gray-50 p-4 rounded-md text-sm text-gray-600 space-y-2">
                <p className="Nokora"><strong>របៀបប្រកួត៖</strong></p>
                <ol className="list-decimal pl-4 space-y-1 Nokora">
                  <li>ដាក់ project របស់អ្នកទៅកាន់ការប្រកួត</li>
                  <li>អ្នកអភិវឌ្ឍន៍ផ្សេងទៀតអាចមើលនិងបោះឆ្នោតឱ្យ project ដែលបាន submit</li>
                  <li> project ដែលមានការបោះឆ្នោតច្រើនជាងគេនឹងឈ្នះការប្រកួត</li>
                  <li>អ្នកឈ្នះនឹងទទួលបានរង្វាន់ ចឹងហៅមិត្តភក្តិអោយមកជួយបោះឆ្នោតមក!</li>
                </ol>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SubmissionHeader