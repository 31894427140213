'use client'

import React, { useState } from 'react'
import { domToReact } from 'html-react-parser'
import { Copy, Check } from 'lucide-react'

const CopyButton = ({ content, isInline }) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(content)
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 2000)
    } catch (err) {
      console.error('Failed to copy text: ', err)
    }
  }

  return (
    <button
      className={`p-1 bg-gray-700 text-purple-400 rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-purple-500 transition-colors duration-200 ${
        isInline ? 'ml-2 inline-flex items-center' : 'absolute top-2 right-2'
      }`}
      onClick={copyToClipboard}
      aria-label={isCopied ? "Copied" : "Copy to clipboard"}
    >
      {isCopied ? (
        <Check className="w-3 h-3 sm:w-4 sm:h-4" />
      ) : (
        <Copy className="w-3 h-3 sm:w-4 sm:h-4" />
      )}
    </button>
  )
}

const options = {
  replace: (domNode) => {
    if (domNode.type === 'tag') {
      const { name, attribs, children } = domNode

      const className = attribs && attribs.class ? attribs.class : ''

      const getIndentLevel = (classStr) => {
        const match = classStr.match(/ql-indent-(\d+)/)
        return match ? parseInt(match[1], 10) : 0
      }

      switch (name) {
        case 'h1':
          return (
            <h1 className="text-xl md:text-2xl font-bold mb-1 text-purple-600 Nokora">
              {domToReact(children, options)}
            </h1>
          )
        
        case 'h2':
          return (
            <h2 className="text-lg md:text-xl font-semibold mb-1 text-purple-600 Nokora">
              {domToReact(children, options)}
            </h2>
          )
        case 'h3':
          return (
            <h3 className="text-base md:text-lg font-semibold mb-1 text-purple-600 Nokora">
              {domToReact(children, options)}
            </h3>
          )
        case 'h4':
          return (
            <h4 className="text-sm md:text-base  font-semibold mb-1 text-purple-600 Nokora">
              {domToReact(children, options)}
            </h4>
          )
          case 'h5':
            return (
              <h5 className="text-sm md:text-base font-semibold mb-1 text-gray-500 border-b border-gray-200 pb-1 Nokora">
                {domToReact(children, options)}
              </h5>
            )
        case 'h6':
          return (
            <h6 className="text-sm md:text-base font-semibold mb-1 text-gray-500 Nokora">
              {domToReact(children, options)}
            </h6>
          )
        case 'p':
          return <p className="Nokora text-sm md:text-base lg:text-lg mb-1">{domToReact(children, options)}</p>
        case 'pre':
          const preContent = domToReact(children, options)
          return (
            <div className="relative">
              <pre className="bg-gray-800 text-gray-100 p-2 md:p-3 lg:p-4 rounded-lg overflow-x-auto font-mono whitespace-pre-wrap text-xs md:text-sm lg:text-base">
                {preContent}
              </pre>
              <CopyButton content={preContent} isInline={false} />
            </div>
          )
        case 'code':
          const codeContent = domToReact(children, options)
          return (
            <span className="inline-flex items-center bg-gray-700 text-gray-100 px-1 md:px-1.5 lg:px-2 py-0.5 rounded font-mono whitespace-pre-wrap text-xs md:text-sm lg:text-base">
              <code>{codeContent}</code>
              <CopyButton content={codeContent} isInline={true} />
            </span>
          )
        case 'ol':
          return (
              <ol className="list-decimal ml-4 list-inside Nokora text-sm md:text-base lg:text-lg">
              {domToReact(children, options)}
              </ol>
            )
        case 'ul':
          return <ul className="list-inside Nokora ml-4 text-sm md:text-base lg:text-lg">{domToReact(children, options)}</ul>
        case 'li':
          const indentLevel = getIndentLevel(className)
          return (
            <li
              className="Nokora mb-2 ml-4 text-sm md:text-base lg:text-lg list-disc"
              style={{ marginLeft: `${indentLevel * 12}px` }}
            >
              {domToReact(children, options)}
            </li>
          )
        case 'a':
          return (
            <a
              href={attribs.href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline Nokora text-sm md:text-base lg:text-lg"
            >
              {domToReact(children, options)}
            </a>
          )
        case 'img':
          return (
            <img
              src={attribs.src}
              alt={attribs.alt || ''}
              className="w-full md:w-3/4 lg:w-1/2 h-auto my-2 md:my-3 lg:my-4 rounded-lg shadow-md mx-auto"
            />
          )
        case 'iframe':
            return (
              <div className="relative w-full max-w-lg my-4 md:my-6 lg:my-8">
                <div className="aspect-video">
                  <iframe
                    src={attribs.src}
                    frameBorder="0"
                    allowFullScreen={true}
                    className="w-full h-full rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
                    title="Embedded video"
                    loading="lazy"
                  />
                </div>
              </div>
            )
        case 'br':
          return <br />
        default:
          return <span className="Nokora text-sm md:text-base lg:text-lg">{domToReact(children, options)}</span>
      }
    }
  },
}

export default options