// src/pages/ProjectEditorPage.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { debounce } from 'lodash'
import Tab from '../../Components/Challenge/Editor/Tab';
import SubmissionHeader from '../../Components/Challenge/Editor/SubmissionHeader';
import EditorHeader from '../../Components/Challenge/Editor/EditorHeader';
import CodeEditor from '../../Components/Challenge/Editor/CodeEditor';
import Header from '../../Components/Headers/CodeForCambodiaHeader';
import Footer from '../../Components/Footer';
import { Maximize2, Minimize2, ArrowLeft } from 'lucide-react';
import ConfirmationModal from '../../Components/ConfirmationModal';

const SPLIT_RATIOS = [25, 50, 75];
const AUTO_SAVE_INTERVAL = 15000; // 30 seconds

const ProjectEditorPage = () => {
    const { workspaceId, projectId } = useParams();
    const navigate = useNavigate();
    const [project, setProject] = useState(null);
    const [submission, setSubmission] = useState(null);
    const [challenges, setChallenges] = useState([]);
    const [selectedChallenge, setSelectedChallenge] = useState('');
    const [htmlCode, setHtmlCode] = useState('');
    const [cssCode, setCssCode] = useState('');
    const [jsCode, setJsCode] = useState('');
    const [activeCodeTab, setActiveCodeTab] = useState('html');
    const editorRef = useRef(null);
    const [isMobileView, setIsMobileView] = useState(false);
    const [splitPosition, setSplitPosition] = useState(50);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const containerRef = useRef(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const autoSaveTimerRef = useRef(null);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [showUnsubmitModal, setShowUnsubmitModal] = useState(false);

    // Simple save function - no debouncing or auto-save
    const saveProject = useCallback(async () => {
        if (!workspaceId || !projectId) return;
        
        try {
            await axios.put(
                `http://localhost:4000/codecademy/challenges/project/update/workspace/${workspaceId}/project/${projectId}`,
                {
                    htmlCode,
                    cssCode,
                    jsCode,
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
            alert('Project saved successfully!');
        } catch (error) {
            console.error('Error saving project:', error);
            alert('Failed to save project. Please try again.');
        }
    }, [workspaceId, projectId, htmlCode, cssCode, jsCode]);



    useEffect(() => {
        fetchProject();
        checkSubmission();
        fetchChallenges();
    }, [projectId]); // Removed saveProject from dependencies

    useEffect(() => {
        const checkMobileView = () => {
            setIsMobileView(window.innerWidth < 768)
        }
        checkMobileView()
        window.addEventListener('resize', checkMobileView)
        return () => window.removeEventListener('resize', checkMobileView)
    }, [])

    // Fetch the project details
    const fetchProject = async () => {
        try {
            const response = await axios.get(
                `http://localhost:4000/codecademy/challenges/project/workspace/${workspaceId}/project/${projectId}`,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
            const projectData = response.data.data;
            setProject(projectData);
            setHtmlCode(projectData.html_code || '');
            setCssCode(projectData.css_code || '');
            setJsCode(projectData.js_code || '');
        } catch (error) {
            console.error('Error:', error);
        }
    };

        // Check if the project has been submitted
        const checkSubmission = async () => {
            try {
                const response = await axios.get(
                    `http://localhost:4000/codecademy/challenges/submission/project/${projectId}`,
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                    }
                );
                setSubmission(response.data.data);
            } catch (error) {
                // Silently handle 404s as they're expected when no submission exists
                if (error.response && error.response.status === 404) {
                    setSubmission(null);
                    return;
                }
                console.error('Error checking submission:', error);
            }
        };


    // Fetch available challenges
    const fetchChallenges = async () => {
        try {
            const response = await axios.get('http://localhost:4000/codecademy/challenges/challenge', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setChallenges(response.data.data);
        } catch (error) {
            console.error('Error fetching challenges:', error);
        }
    };

    // Delete the project
    const deleteProject = async () => {
        if (
            !window.confirm(
                'Are you sure you want to delete this project? This action cannot be undone.'
            )
        )
            return;
        try {
            await axios.post(
                `http://localhost:4000/codecademy/challenges/project/delete/workspace/${workspaceId}/project/${projectId}`,
                {},
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
            alert('Project deleted successfully!');
            navigate(`/projects/${workspaceId}`);
        } catch (error) {
            console.error('Error deleting project:', error);
        }
    };

    // Delete the submission
    const deleteSubmission = async () => {
        try {
            await axios.post(
                `http://localhost:4000/codecademy/challenges/submission/unsubmit/${submission.id}`,
                {},
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
            setShowUnsubmitModal(false);
            setSubmission(null);
        } catch (error) {
            console.error('Error deleting submission:', error);
        }
    };

    // Submit the project to a challenge
    const submitProject = async () => {
        try {
            await axios.post(
                `http://localhost:4000/codecademy/challenges/submission/submit`,
                { projectId, description: '' },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
            setShowSubmitModal(false);
            await checkSubmission(); // Refresh submission status
        } catch (error) {
            console.error('Error submitting project:', error);
            alert('Failed to submit project. Please try again.');
        }
    };

    const handleEditorMount = (editor, monaco) => {
        editorRef.current = editor
        setTimeout(() => {
            editor.layout()
        }, 0)
    }

    const handleDragStart = useCallback((e) => {
        setIsDragging(true);
        document.body.style.userSelect = 'none';
        const containerRect = containerRef.current.getBoundingClientRect();
        setStartX(e.clientX - containerRect.left);
      }, []);
    
      const handleDrag = useCallback(
        (e) => {
          if (!isDragging || !containerRef.current) return;
    
          const containerRect = containerRef.current.getBoundingClientRect();
          const containerWidth = containerRect.width;
          const currentX = e.clientX - containerRect.left;
          const deltaX = currentX - startX;
    
          requestAnimationFrame(() => {
            let newPosition = splitPosition + (deltaX / containerWidth) * 100;
            newPosition = Math.max(20, Math.min(80, newPosition)); // Clamp between 20% and 80%
            setSplitPosition(newPosition);
            setStartX(currentX);
          });
        },
        [isDragging, splitPosition, startX]
      );
    
      const handleDragStop = useCallback(() => {
        setIsDragging(false);
        document.body.style.userSelect = '';
        if (editorRef.current) {
          setTimeout(() => {
            editorRef.current.layout();
          }, 50);
        }
      }, []);
  

  useEffect(() => {
    const resizeObserver = new ResizeObserver(
      debounce(() => {
        if (editorRef.current) {
          editorRef.current.layout();
        }
      }, 10)
    );
  
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
  
    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]); // Add containerRef here
  

    const handleSplitChange = (ratio) => {
        setSplitPosition(ratio);
        // Ensure editor reflows
        if (editorRef.current) {
            setTimeout(() => editorRef.current.layout(), 0);
        }
    };

    return (
        <div className="h-screen flex flex-col bg-white">
            {!isFullscreen && <Header />}

            {!isFullscreen && (
                <EditorHeader 
                title={project?.title}
                onSave={saveProject}  // This will only trigger when the save button is clicked
                onDelete={deleteProject}
                workspaceId={workspaceId}
            />
            )}
            {!isFullscreen && (
                <SubmissionHeader 
                    submission={submission}
                    selectedChallenge={selectedChallenge}
                    setSelectedChallenge={setSelectedChallenge}
                    challenges={challenges}
                    onSubmit={() => setShowSubmitModal(true)}
                    onDelete={() => setShowUnsubmitModal(true)}
                />
            )}

            <div className="flex-1 flex flex-col">
                <div className="border-b border-gray-200">
                    <div className="flex justify-between items-center">
                        <div className="flex">
                            {['html', 'css', 'js'].map((tab) => (
                                <Tab
                                    key={tab}
                                    active={activeCodeTab === tab}
                                    onClick={() => setActiveCodeTab(tab)}
                                >
                                    {tab.toUpperCase()}
                                </Tab>
                            ))}
                        </div>
                        
                        <div className="flex gap-2 px-4 items-center">
                            {!isMobileView && (
                                <>
                                    {SPLIT_RATIOS.map(ratio => (
                                        <button
                                            key={ratio}
                                            onClick={() => handleSplitChange(ratio)}
                                            className={`
                                                px-2 py-1 text-sm rounded
                                                ${Math.round(splitPosition) === ratio 
                                                    ? 'bg-blue-500 text-white' 
                                                    : 'bg-gray-200 hover:bg-gray-300'
                                                }
                                                transition-colors duration-150
                                            `}
                                        >
                                            {ratio}%
                                        </button>
                                    ))}
                                </>
                            )}
                            <button
                                onClick={() => setIsFullscreen(!isFullscreen)}
                                className="px-2 py-1 text-sm hover:bg-gray-100 rounded transition-colors duration-150 flex items-center gap-1"
                            >
                                {isFullscreen ? (
                                    <>
                                        <Minimize2 size={14} />
                                    </>
                                ) : (
                                    <>
                                        <Maximize2 size={14} />
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="flex-1">
                    <CodeEditor
                        splitPosition={splitPosition}
                        isDragging={isDragging}
                        handleDragStart={handleDragStart}
                        handleDrag={handleDrag}
                        handleDragStop={handleDragStop}
                        activeCodeTab={activeCodeTab}
                        code={
                            activeCodeTab === 'html'
                            ? htmlCode
                            : activeCodeTab === 'css'
                            ? cssCode
                            : jsCode
                        }
                        onChange={(value) =>
                            activeCodeTab === 'html'
                            ? setHtmlCode(value)
                            : activeCodeTab === 'css'
                            ? setCssCode(value)
                            : setJsCode(value)
                        }
                        editorRef={editorRef}
                        handleEditorMount={handleEditorMount}
                        isMobileView={isMobileView}
                        preview={
                            <iframe
                            srcDoc={`
                                <!DOCTYPE html>
                                <html>
                                <head>
                                    <meta charset="utf-8">
                                    <style>${cssCode || ''}</style>
                                </head>
                                <body>
                                    ${htmlCode || ''}
                                    <script>
                                        window.onerror = function(msg, url, lineNo, columnNo, error) {
                                            console.log('Preview error:', msg);
                                            return false;
                                        };
                                        ${jsCode || ''}
                                    </script>
                                </body>
                                </html>
                            `}
                            className="w-full h-full border-none"
                            title="preview"
                            sandbox="allow-scripts"
                        />
                        }
                        containerRef={containerRef}
                    />
                </div>
            </div>
            {!isFullscreen && <Footer/>}

            <ConfirmationModal
                isOpen={showSubmitModal}
                onClose={() => setShowSubmitModal(false)}
                onConfirm={submitProject}
                title="Submit Project"
                confirmText="Submit"
                confirmButtonClass="bg-green-600 hover:bg-green-700"
            >
                <p className='Nokora '>ពេល submit project នឹងក្លាយជាសាធារណៈ។ ចង់ update គ្រាន់ update code វានឹង update auto</p>
            </ConfirmationModal>

            <ConfirmationModal
                isOpen={showUnsubmitModal}
                onClose={() => setShowUnsubmitModal(false)}
                onConfirm={deleteSubmission}
                title="Unsubmit Project"
                confirmText="Unsubmit"
                confirmButtonClass="bg-red-600 hover:bg-red-700"
            >
                <p className='Nokora '>បើអ្នក unsubmit នឹងបាត់ vote។ ច្បាស់អត់?</p>
            </ConfirmationModal>
        </div>
    );
};

export default ProjectEditorPage;
