import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import LeftPanel from '../Hero/LeftPanel';
import RightPanel from '../Hero/RightPanel';

const codeLines = [
  "import { success } from 'life';",
  "const you = new Developer();",
  "you.learn('coding');",
  "you.build('amazing-projects');",
  "success(you);",
];

export default function Hero({ onRegisterSuccess }) {
  const [totalUsers, setTotalUsers] = useState(null);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const response = await axios.get('https://usitebackend-36ad67a8d6c6.herokuapp.com/codecademy/leaderboard/total-users');
        setTotalUsers(response.data[0].total_users);
      } catch (error) {
        console.error('Error fetching total users:', error);
      }
    };

    fetchTotalUsers();
  }, []);

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="font-mono text-white relative overflow-hidden border-b-2 border-gray-100"
    >
      <div className="absolute inset-0 opacity-10">
        {codeLines.map((line, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2, duration: 0.5 }}
            className="text-sm sm:text-base md:text-lg text-black lg:text-xl xl:text-2xl"
          >
            {line}
          </motion.div>
        ))}
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-24 relative z-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 items-center gap-8">
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <LeftPanel totalUsers={totalUsers} />
          </motion.div>
          <motion.div
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <RightPanel onRegisterSuccess={onRegisterSuccess} />
          </motion.div>
        </div>
      </div>

    </motion.div>
  );
}